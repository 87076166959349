import { MainNav } from './main-nav/MainNav'
import { NavItem } from '../../../shared/components/cards/nav-item/NavItem'
import { ProfileDrop } from './profile-drop/ProfileDrop'
import { ProfileDropItem } from './profile-drop/profile-drop-item/ProfileDropItem'
import { useWindowSize } from '../../../shared/hooks/UseWindowSize'
import { IconLogout } from '../../../shared/components/icons/IconLogout'
import { IconProfile } from '../../../shared/components/icons/IconProfile'
import { NavDrop } from './nav-drop/NavDrop'
import { NavLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../../shared/hooks/UseAuth'
import { selectTariffDetails, selectUser } from '../../../store/reducers/AuthSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/Redux'
import { NavDropItem } from '../../../shared/components/cards/nav-drop-item/NavDropItem'
import { IconDocument } from '../../../shared/components/icons/IconDocument'
import { IconPaidResults } from '../../../shared/components/icons/IconPaidResults'
import { IconSeoPosition } from '../../../shared/components/icons/IconSeoPosition'
import { IconSeoSearch } from '../../../shared/components/icons/IconSeoSearch'
import { NavLinkItem } from '../../../shared/components/cards/nav-link-item/NavLinkItem'
import { NavCollapseItem } from '../../../shared/components/cards/nav-collapse-item/NavCollapseItem'
import { IconBarChart } from '../../../shared/components/icons/IconBarChart'
import { IconZap } from '../../../shared/components/icons/IconZap'
import { IconSpy } from '../../../shared/components/icons/IconSpy'
import { IconZapSearch } from '../../../shared/components/icons/IconZapSearch'
import { useTranslation } from 'react-i18next'
import { IconPPCCompliance } from '../../../shared/components/icons/IconPPCCompliance'
import { IconLanguageCompliance } from '../../../shared/components/icons/IconLanguageCompliance'
import { IconCrown } from '../../../shared/components/icons/IconCrown'
import React from 'react'
import { setModal } from '../../../store/reducers/AppSlice'
import { ChangePlan } from '../../../pages/main/projects/components/ChangePlan'
import { CardDrop } from '../../../shared/components/cards/card-drop/CardDrop'
import { IconQuestion } from '../../../shared/components/icons/IconQuestion'
import { BpAnchor } from '../../../shared/components/text/anchor/BpAnchor'
import { articles } from '../../../shared/consts/articles.const'
import { FeedbackModal } from './feedback-modal/FeedbackModal'
import { BpButton } from '../../../shared/components/buttons/bp-button/BpButton'

export const Header = () => {
  const { t } = useTranslation()
  const { xs, sm, xl } = useWindowSize()
  const iconColor = '#0E63D8'
  const user = useAppSelector(selectUser)
  const tariffDetails = useAppSelector(selectTariffDetails)
  const { logout } = useAuth()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  return (
    <div className='flex items-center relative gap-[16px]'>
      <NavLink to='/'>
        <img alt='logo' src={'/logo.svg'} className='cursor-pointer w-[111px]' />
      </NavLink>
      {xl && (
        <div className='flex-1'>
          <MainNav>
            <NavLink to='/' end>
              {({ isActive }) => (
                <NavItem size={'sm'} active={isActive}>
                  {t('navigation.overview')}
                </NavItem>
              )}
            </NavLink>
            <NavDropItem
              label={t('navigation.SERPMonitoring')}
              active={['/ad-reports', '/organic-results', '/live-search'].includes(pathname)}
            >
              <NavLinkItem to='/ad-reports' icon={<IconPaidResults />}>
                {t('navigation.paidResults')}
              </NavLinkItem>
              <NavLinkItem to='/organic-results' icon={<IconDocument />}>
                {t('navigation.organicResults')}
              </NavLinkItem>
              <NavLinkItem to='/live-search' icon={<IconZapSearch />} label='Beta'>
                {t('navigation.liveSearch')}
              </NavLinkItem>
            </NavDropItem>
            <NavDropItem
              label={t('navigation.nonCompliantAffiliates')}
              active={['/ppc-compliance', '/language-compliance-page'].includes(pathname)}
            >
              <NavLinkItem to='/ppc-compliance' icon={<IconPPCCompliance />}>
                PPC Compliance
              </NavLinkItem>
              <NavLinkItem to='/language-compliance-page' icon={<IconLanguageCompliance />}>
                Language Compliance
              </NavLinkItem>
            </NavDropItem>
            <NavDropItem
              label={t('navigation.researchTools')}
              active={['/position-tracker', '/serp-presence', '/advertising-research'].includes(
                pathname,
              )}
            >
              <NavLinkItem to='/position-tracker' icon={<IconSeoPosition />}>
                {t('navigation.SEOPositionTracker')}
              </NavLinkItem>
              <NavLinkItem to='/serp-presence' icon={<IconBarChart />}>
                {t('navigation.onSiteBrandMentions')}
              </NavLinkItem>
              <NavLinkItem to='/advertising-research' icon={<IconSeoSearch />}>
                {t('navigation.advertisingResearch')}
              </NavLinkItem>
            </NavDropItem>
            <NavDropItem
              label={t('navigation.uncloaking')}
              active={['/live-uncloaking', '/blackhat-monitoring'].includes(pathname)}
              hidden={!user.allow_decloak_live && !user.allow_decloak_auto}
            >
              <NavLinkItem
                to='/live-uncloaking'
                icon={<IconZap />}
                hidden={!user.allow_decloak_live}
              >
                {t('navigation.liveUncloaking')}
              </NavLinkItem>
              <NavLinkItem
                to='/blackhat-monitoring'
                icon={<IconSpy />}
                hidden={!user.allow_decloak_auto}
              >
                {t('navigation.blackhatMonitoring')}
              </NavLinkItem>
            </NavDropItem>
          </MainNav>
        </div>
      )}
      <div className='ml-auto flex justify-end gap-[16px] w-[calc(100%-127px)] md:w-auto'>
        {!tariffDetails?.tariff?.is_subscription_expired && (
          <div
            className={'relative border border-aqua-300 rounded-[18px] p-[2px] flex items-center'}
          >
            <button onClick={() => dispatch(setModal({ children: <ChangePlan /> }))}>
              <div className='rounded-full bg-white w-[32px] h-[32px] flex items-center justify-center'>
                <IconCrown className='w-[22px]' color={'#A059FF'} />
              </div>
            </button>
          </div>
        )}
        <CardDrop
          className={'!top-[50px]'}
          trigger={
            <div
              className={'relative border border-aqua-300 rounded-[18px] p-[2px] flex items-center'}
            >
              <div className='rounded-full w-[32px] h-[32px] flex items-center justify-center hover:bg-[#6684A74D]'>
                <IconQuestion className='w-[16px]' color={'#FFFFFF'} />
              </div>
            </div>
          }
        >
          <div className={'min-w-[230px] py-[16px]'}>
            <div className={'px-[16px] pb-[6px] flex flex-col'}>
              {articles.map((article, index) => (
                <BpAnchor
                  key={index}
                  target='_blank'
                  href={article.href}
                  className={
                    'text-[15px] !text-focus-500 hover:!text-default hover:bg-default-200 p-[8px] rounded-[8px]'
                  }
                >
                  {article.text}
                </BpAnchor>
              ))}
            </div>
            <div className={'bg-[#0E63D8]/[0.05] px-[24px] py-[16px] flex flex-col gap-[12px]'}>
              <p className={'text-center text-[17px] font-medium'}>Ask a specialist</p>
              <BpButton
                size={'sm'}
                onClick={() =>
                  window.open('https://calendly.com/roman_r_bluepear/discovery-call', '_blank')
                }
              >
                Book 15-min call
              </BpButton>
              <div className={'flex gap-[6px] justify-center'}>
                <BpAnchor
                  className={'text-[15px]'}
                  target='_blank'
                  href={'mailto:roman.r@bluepear.co'}
                >
                  Email
                </BpAnchor>
                <BpAnchor className={'text-[15px]'} target='_blank' href={'https://t.me/Van_Fill'}>
                  Telegram
                </BpAnchor>
              </div>
            </div>
            <div className={'pt-[16px] flex px-[16px]'}>
              <button
                className={
                  'text-[15px] text-[#0E63D8] w-fit m-auto hover:text-[#40a9ff] hover:opacity-80 transition-opacity duration-150'
                }
                onClick={() => dispatch(setModal({ children: <FeedbackModal /> }))}
              >
                Give feedback
              </button>
            </div>
          </div>
        </CardDrop>
        <ProfileDrop
          username={user ? user.username : ''}
          short={xs || sm}
          className='max-w-[calc(100%-52px)] md:max-w-full'
        >
          <NavLink to='/profile-settings'>
            <ProfileDropItem icon={<IconProfile color={iconColor}></IconProfile>}>
              {t('pages.profile.profile')}
            </ProfileDropItem>
          </NavLink>
          <NavLink to='/projects'>
            <ProfileDropItem icon={<IconCrown color={iconColor}></IconCrown>}>
              Projects & Plans
            </ProfileDropItem>
          </NavLink>
          {user.is_master && (
            <NavLink to='/team'>
              <ProfileDropItem icon={<IconProfile color={iconColor}></IconProfile>}>
                Team
              </ProfileDropItem>
            </NavLink>
          )}
          <ProfileDropItem
            icon={<IconLogout color={iconColor}></IconLogout>}
            onClick={() => logout()}
          >
            {t('pages.profile.logout')}
          </ProfileDropItem>
        </ProfileDrop>
        {!xl && (
          <div className='z-10'>
            <NavDrop>
              <NavLink to='/' end>
                {({ isActive }) => (
                  <NavItem theme='dark' size='sm' decoration='dot' active={isActive}>
                    {t('navigation.overview')}
                  </NavItem>
                )}
              </NavLink>
              <NavCollapseItem
                label={t('navigation.SERPMonitoring')}
                theme='dark'
                size='sm'
                decoration='dot'
                active={['/ad-reports', '/organic-results', '/live-search'].includes(pathname)}
              >
                <NavLinkItem to='/ad-reports' icon={<IconPaidResults />}>
                  {t('navigation.paidResults')}
                </NavLinkItem>
                <NavLinkItem to='/organic-results' icon={<IconDocument />}>
                  {t('navigation.organicResults')}
                </NavLinkItem>
                <NavLinkItem to='/live-search' icon={<IconZapSearch />} label='Beta'>
                  {t('navigation.liveSearch')}
                </NavLinkItem>
              </NavCollapseItem>
              <NavCollapseItem
                label={t('navigation.nonCompliantAffiliates')}
                theme='dark'
                size='sm'
                decoration='dot'
                active={['/ppc-compliance', '/language-compliance-page'].includes(pathname)}
              >
                <NavLinkItem to='/ppc-compliance' icon={<IconPPCCompliance />}>
                  PPC Compliance
                </NavLinkItem>
                <NavLinkItem to='/language-compliance-page' icon={<IconLanguageCompliance />}>
                  Language Compliance
                </NavLinkItem>
              </NavCollapseItem>
              <NavCollapseItem
                label={t('navigation.researchTools')}
                theme='dark'
                size='sm'
                decoration='dot'
                active={['/position-tracker', '/serp-presence', '/advertising-research'].includes(
                  pathname,
                )}
              >
                <NavLinkItem to='/position-tracker' icon={<IconSeoPosition />}>
                  {t('navigation.SEOPositionTracker')}
                </NavLinkItem>
                <NavLinkItem to='/serp-presence' icon={<IconBarChart />}>
                  {t('navigation.onSiteBrandMentions')}
                </NavLinkItem>
                <NavLinkItem to='/advertising-research' icon={<IconSeoSearch />}>
                  {t('navigation.advertisingResearch')}
                </NavLinkItem>
              </NavCollapseItem>
              <NavCollapseItem
                label={t('navigation.uncloaking')}
                theme='dark'
                size='sm'
                decoration='dot'
                active={['/live-uncloaking', '/blackhat-monitoring'].includes(pathname)}
              >
                <NavLinkItem to='/live-uncloaking' icon={<IconZap />}>
                  {t('navigation.liveUncloaking')}
                </NavLinkItem>
                <NavLinkItem to='/blackhat-monitoring' icon={<IconSpy />}>
                  {t('navigation.blackhatMonitoring')}
                </NavLinkItem>
              </NavCollapseItem>
            </NavDrop>
          </div>
        )}
      </div>
    </div>
  )
}
