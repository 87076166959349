import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { hideModal, showAlert } from '../../../../store/reducers/AppSlice'
import React from 'react'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { BpTextArea } from '../../../../shared/components/forms/textarea/BpTextArea'
import { Controller, useForm } from 'react-hook-form'
import { useSendFeedbackMutation } from '../../../../api/user/User.api'

export const FeedbackModal = () => {
  const dispatch = useAppDispatch()

  const [updateInfo] = useSendFeedbackMutation()

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
  })

  const submit = (values: any) => {
    updateInfo(values).then((response: any) => {
      if (response.data?.success) {
        dispatch(
          showAlert({
            message: 'Success',
            type: 'success',
            timeout: 2000,
          }),
        )
        dispatch(hideModal())
      }
    })
  }

  return (
    <form className={'flex flex-col'} onSubmit={handleSubmit(submit)}>
      <h3
        className={
          'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        Give feedback
      </h3>
      <div className={'flex flex-col gap-[16px] pt-[18px] pb-[16px] px-[24px]'}>
        <Controller
          name='message'
          control={control}
          render={({ field }) => (
            <BpTextArea
              {...field}
              height={'sm'}
              placeholder={'We’d love to hear your feedback on our service'}
              {...register('message', {
                required: 'Required',
              })}
            />
          )}
        />
      </div>
      <div
        className={
          'w-full flex gap-[12px] px-[24px] py-[24px] border-t-[3px] border-t-[#00326D]/[.02]'
        }
      >
        <BpButton type='submit' disabled={!isValid} className={'uppercase'}>
          Send
        </BpButton>
        <BpButton type='button' theme='transparent' onClick={() => dispatch(hideModal())}>
          Cancel
        </BpButton>
      </div>
    </form>
  )
}
