import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconQuestion = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z'
        stroke={color}
        strokeMiterlimit='10'
        className='group-hover:stroke-default'
      />
      <path
        d='M7.99992 9.82591V9.33896C7.99992 8.69983 8.3347 8.09113 8.91296 7.75635C9.79557 7.23896 10.0999 6.1433 9.58252 5.2607C9.06513 4.37809 7.96948 4.07374 7.08687 4.59113C6.53905 4.92591 6.17383 5.50417 6.17383 6.17374'
        stroke={color}
        strokeMiterlimit='10'
        className='group-hover:stroke-default'
      />
      <path
        d='M8.0003 11.9564C8.33647 11.9564 8.60899 11.6839 8.60899 11.3477C8.60899 11.0115 8.33647 10.739 8.0003 10.739C7.66412 10.739 7.3916 11.0115 7.3916 11.3477C7.3916 11.6839 7.66412 11.9564 8.0003 11.9564Z'
        fill={color}
        className='group-hover:fill-default'
      />
    </svg>
  )
}
