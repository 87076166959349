import { IWithExpandedAllProps, withExpanded } from '../../../../../shared/hoc/WithExpanded'
import { ColumnsType } from 'antd/lib/table'
import { IAdvertisingModel } from '../../../../../api/research/models/Advertising.model'
import React, { Fragment, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { IDetailedInfoFilter } from '../../interfaces/DetailedInfoFilter.interface'
import { selectDetailedInfoFilters } from '../../store/DetailedInfoSlice'
import { IAdvertisingParams } from '../../../../../api/research/interfaces/AdvertisingParams.interface'
import { useGetAdvertisingQuery } from '../../../../../api/research/Research.api'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { DetailedInfoPagesExpandedRow } from './DetailedInfoPagesExpandedRow'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { PositionCell } from '../../../../../shared/components/cells/PositionCell'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import { IconArrowDown } from '../../../../../shared/components/icons/IconArrowDown'
import { IconArrowUp } from '../../../../../shared/components/icons/IconArrowUp'
import { useTranslation } from 'react-i18next'

const Table = ({ onToggleExpand, isExpandAll, expandedRowKeys }: IWithExpandedAllProps) => {
  const { t } = useTranslation()
  const detailedInfoFilter = useAppSelector<IDetailedInfoFilter>(selectDetailedInfoFilters)
  const {
    project,
    site,
    country,
    engine_id,
    dateRange: date,
    keyword,
    has_brand,
    page_url,
    show_hidden,
  } = detailedInfoFilter

  const [queryParams, setQueryParams] = useState<IAdvertisingParams>()
  const { data, isLoading, isFetching } = useGetAdvertisingQuery(
    queryParams as IAdvertisingParams,
    { skip: !project || queryParams == undefined },
  )

  useEffect(() => {
    if (!project) return

    setQueryParams({
      page: 1,
      'per-page': 10,
      project_id: +project,
      site,
      country_id: country ? +country : undefined,
      engine_id: +engine_id,
      date,
      keyword,
      has_brand,
      landing: page_url,
      show_hidden,
    })
  }, [detailedInfoFilter])

  const columns: ColumnsType<IAdvertisingModel> = [
    {
      title: t('table.columns.page'),
      dataIndex: 'title',
      key: 'title',
      render: (_: string, { title, has_brand, url }: IAdvertisingModel) => {
        return (
          <Fragment>
            <div className='flex whitespace-nowrap items-center'>
              <span className={'block font-medium max-w-xl overflow-hidden text-ellipsis'}>
                {title}
              </span>
              {has_brand && (
                <span
                  className={`inline-block
                  font-semibold
                  uppercase
                  text-[10px]
                  leading-5
                  px-2
                  rounded-md
                  bg-focus-50
                  ml-2
                  text-aqua-300
                  cursor-default`}
                >
                  {t('pages.domainOverview.hasBrand')}
                </span>
              )}
            </div>
            <BpAnchor
              className={'block mt-1 w-96 overflow-hidden text-ellipsis'}
              target='_blank'
              href={url}
            >
              {url.split('?')[0]}
            </BpAnchor>
          </Fragment>
        )
      },
      width: '320px',
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.keyword(s)')}
          <BpTooltip title={t('pages.domainOverview.keywordsTooltip')} />
        </span>
      ),
      dataIndex: 'keywords_count',
      key: 'keywords_count',
      render: (
        _: string,
        { title, keywords_count, last_found, prev_keywords_count }: IAdvertisingModel,
      ) => {
        return (
          <div className='flex items-center'>
            <span className='w-8'>{keywords_count}</span>

            {keywords_count > prev_keywords_count ? (
              <span className='flex items-center text-success-400 pr-4'>
                <IconArrowUp className='w-4' color='#4DB11E' />
                {keywords_count - prev_keywords_count}
              </span>
            ) : keywords_count < prev_keywords_count ? (
              <span className='flex items-center text-danger pr-4'>
                <IconArrowDown className='w-4' color='#DC2F02' />
                {prev_keywords_count - keywords_count}
              </span>
            ) : null}

            {expandedRowKeys?.includes(`${title}${last_found}`) ? (
              <span
                className={`text-focus
                  text-[13px]
                  leading-[13px]
                  ml-auto
                  border-dotted
                  border-b
                  cursor-pointer`}
                onClick={() => {
                  onToggleExpand && onToggleExpand(false, `${title}${last_found}`)
                }}
              >
                {t('buttons.close')}
              </span>
            ) : (
              <span
                className={`text-focus
                  text-[13px]
                  leading-[13px]
                  ml-auto
                  border-dotted
                  border-b
                  cursor-pointer`}
                onClick={() => {
                  onToggleExpand && onToggleExpand(true, `${title}${last_found}`)
                }}
              >
                {t('buttons.show')}
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.avgPosition')}
          <BpTooltip title={t('pages.domainOverview.avgPositionTooltip')} />
        </span>
      ),
      dataIndex: 'position',
      key: 'position',
      render: (_: string, { position, previous_position }: IAdvertisingModel) => {
        const current = +position.toFixed(1)
        const previous = previous_position !== null && +previous_position.toFixed(1)

        if (!current && previous) {
          return (
            <div className='flex items-center gap-2'>
              <span className='w-8'>&gt;10</span>
              <span className='flex items-center text-danger'>
                <IconArrowDown className='w-4' color='#DC2F02' />
                {11 - previous}
              </span>
            </div>
          )
        }

        return <PositionCell current={current} previous={previous} />
      },
      sorter: true,
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.topKeyword')}
          <BpTooltip title={t('pages.domainOverview.topKeywordTooltip')} />
        </span>
      ),
      dataIndex: 'top_keyword',
      key: 'top_keyword',
    },
    {
      title: t('table.columns.landingPage'),
      dataIndex: 'link_screen_url',
      key: 'link_screen_url',
      render: (_: string, { link_screen_url }: IAdvertisingModel) => (
        <BpAnchor href={link_screen_url} target='_blank'>
          {t('buttons.viewScreenshot')}
        </BpAnchor>
      ),
    },
    {
      title: t('table.columns.lastUpdated'),
      dataIndex: 'last_found',
      key: 'last_found',
      width: '150px',
      sorter: true,
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<IAdvertisingModel> | SorterResult<IAdvertisingModel>[],
  ) => {
    if (queryParams) {
      setQueryParams({
        ...queryParams,
        page: current,
        'per-page': pageSize,
        sort: useSorter(sorter),
      })
    }
  }

  return (
    <BpTable
      dataSource={data?.data?.items}
      rowKey={(record) => `${record.title}${record.last_found}`}
      columns={columns}
      loading={isLoading || isFetching}
      onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
      data={data as TTableResponse<IAdvertisingModel>}
      expandable={{
        expandedRowRender: (record: IAdvertisingModel) => (
          <DetailedInfoPagesExpandedRow record={record} />
        ),
        expandedRowKeys: isExpandAll
          ? data?.data?.items.map((item) => `${item.title}${item.last_found}`)
          : expandedRowKeys,
        showExpandColumn: false,
      }}
    ></BpTable>
  )
}

export const DetailedInfoPagesTable = withExpanded(Table)
