import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../Store'
import { IUserModel } from '../../api/site/models/User.model'
import { ITariffModel } from '../../api/user/models/Tariff.model'
import { IProjectDetailsModel } from '../../api/user/models/ProjectDetails.model'

type AuthState = {
  authenticated: boolean | null
  user: IUserModel
  oauthUser: any | null
  tariffDetails: {
    tariff?: ITariffModel
    location_details: { [key: string]: number }
    project_details?: IProjectDetailsModel[]
    tariffUpdated?: Date
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authenticated: null,
    tariffDetails: {
      tariffUpdated: undefined,
    },
  } as AuthState,
  reducers: {
    login: (state: AuthState, { payload }: PayloadAction<IUserModel>) => {
      state.authenticated = payload.status === 'active'
      state.oauthUser = payload
      state.user = payload
    },
    logOut: (state: AuthState) => {
      state.authenticated = false
      state.oauthUser = undefined
    },
    setSignup: (state: AuthState, { payload }: PayloadAction<any>) => {
      state.oauthUser = payload
    },
    setTariffDetails: (state: AuthState, { payload }: PayloadAction<any>) => {
      state.tariffDetails = { ...state.tariffDetails, ...payload }
    },
    setTariffUpdated: (state: AuthState) => {
      state.tariffDetails = { ...state.tariffDetails, tariffUpdated: new Date() }
    },
  },
})

export const { login, logOut, setSignup, setTariffDetails, setTariffUpdated } = authSlice.actions

export const selectAuthenticated = (state: RootState) => state.root.auth.authenticated
export const selectUser = (state: RootState) => state.root.auth.user
export const selectOauthUser = (state: RootState) => state.root.auth.oauthUser
export const selectTariffDetails = (state: RootState) => state.root.auth.tariffDetails

export default authSlice.reducer
