import { ReactNode } from 'react'
import { Square } from '../../utility/square/Square'
import { IconButton } from '../../buttons/icon-button/IconButton'
import { IconCrossBold } from '../../icons/IconCrossBold'

export interface IBpModalProps {
  children: ReactNode
  className?: string
  size?: 'md' | 'lg' | 'xl'
  onClose?: () => void
}

export const BpModal = ({ children, className, size = 'md', onClose }: IBpModalProps) => {
  return (
    <div className='fixed indent-0 flex justify-center py-[8px] items-center bg-focus-500/50 w-full h-full z-30 overflow-hidden'>
      <Square
        className={`flex flex-col max-h-full relative w-full min-h-[200px] ${
          { md: 'max-w-[400px]', lg: 'max-w-[700px]', xl: 'max-w-[830px]' }[size]
        } ${className}`}
      >
        <IconButton className='absolute top-[16px] right-[16px]' onClick={onClose}>
          <IconCrossBold color='#6684A7' />
        </IconButton>
        {children}
      </Square>
    </div>
  )
}
