import React, { HTMLAttributes, useEffect, useState } from 'react'
import { TriangleButton } from '../../../../shared/components/buttons/triangle/TriangleButton'
import { Square } from '../../../../shared/components/utility/square/Square'
import { IWithDropdownProps, withDropdown } from '../../../../shared/hoc/WithDropdown'
import { IconProfile } from '../../../../shared/components/icons/IconProfile'

interface IProfileDropProps extends HTMLAttributes<HTMLElement>, IWithDropdownProps {
  username: string
  short?: boolean
}

const Drop = ({ children, open, username, short = false, onToggle }: IProfileDropProps) => {
  const [isHidden, setIsHidden] = useState(!open)

  useEffect(() => {
    open ? setIsHidden(false) : setTimeout(() => setIsHidden(true), 150)
  })

  return (
    <div className={`cursor-pointer select-none relative ${!short && 'min-w-[175px]'}`}>
      <div
        className={`relative border rounded-[18px] p-[2px] flex items-center z-10 ${
          open ? 'border-transparent' : 'border-aqua-300'
        } ${!short && 'pr-[14px]'}`}
        onClick={onToggle}
      >
        <div className='rounded-full bg-default w-[32px] h-[32px] flex items-center justify-center'>
          <div className='text-white text-[14px] font-medium uppercase font-roboto'>
            <IconProfile className='w-[22px]' />
          </div>
        </div>
        <div
          className={`text-[14px] flex-1 mx-[12px] truncate ${
            open ? `text-focus-500 ${short && '-order-1'}` : `text-white ${short && 'hidden'}`
          }`}
        >
          <span className={'block max-w-[85px] overflow-x-hidden truncate'}>{username}</span>
        </div>
        <div className={`${short && 'hidden'} flex`}>
          <TriangleButton position={open ? 'top' : 'down'} color={open ? '#A059FF' : 'white'} />
        </div>
      </div>
      <Square
        className={`flex flex-col absolute rounded-[18px] left-0 right-0 top-0 w-full min-h-full pl-[11px] pr-[11px] pb-[11px] pt-[45px] transition duration-150 ${
          open ? 'opacity-100' : 'opacity-0'
        } ${isHidden && 'invisible'}`}
      >
        <ul className='flex flex-col gap-[8px]'>{children}</ul>
      </Square>
    </div>
  )
}

export const ProfileDrop = withDropdown(Drop)
