import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { hideModal } from '../../../../store/reducers/AppSlice'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { IconArrowUp } from '../../../../shared/components/icons/IconArrowUp'

export const GuideModal = () => {
  const dispatch = useAppDispatch()

  return (
    <div className={'py-[40px] overflow-y-auto max-h-full'}>
      <h2 className={'text-[36px] text-center'}>Quick guide</h2>
      <div className={'px-[60px]'}>
        <BpTabs className='mt-[19px]' size={'xs'} align={'center'}>
          <BpTab title={'SERP Monitoring'}>
            <div className={'py-[24px] flex flex-col gap-[60px]'}>
              <div className={'flex flex-col gap-[24px]'}>
                <h2 className={'text-[36px]'}>Title</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  Tracking affiliate policy compliance violations in brands’ advertising campaigns
                  just got even more convenient! Bluepear has introduced a new dashboard that makes
                  it simpler to manage the issue of brand bidding and ad hijacking, with a focus on
                  affiliate agreement compliance. Brands can now more quickly identify and address
                  violators who are not adhering to the terms of the affiliate agreement.
                </p>
              </div>
              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>Caption</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  If brands want to get comprehensive information on non-compliant traffic from
                  their affiliate partners and running unauthorized brand campaigns, they can simply
                  navigate to the &quot;Non-Compliant Affiliates&quot; tab in the main menu, without
                  the need for additional filtering.
                </p>
              </div>
              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>Caption</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  The system now assigns statuses to help its users stay on top of affiliate policy
                  compliance:
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <strong>New:</strong> Affiliate was detected for the first time
                  <br />
                  <strong>In Progress:</strong> Affiliate received a violations warning
                  <br />
                  <strong>Ignore:</strong> For affiliates who have no restrictions on using branded
                  traffic
                  <br />
                  <strong>Resolved:</strong> For affiliates who have been dealt with affiliate
                  policy violations.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  These statuses enable users to more effectively track and manage affiliate policy
                  compliance issues with their partners.
                </p>
              </div>
            </div>
          </BpTab>
          <BpTab title={'Non-Compliant Affilitates'}></BpTab>
          <BpTab title={'Invite Users'}></BpTab>
          <BpTab title={'Create a project'}></BpTab>
        </BpTabs>
        <div className={'bg-[#F7F9FB] py-[32px] rounded-[16px] flex justify-center gap-[16px]'}>
          <BpButton
            onClick={() => {
              document.getElementById('guide-modal')?.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
            iconRight={<IconArrowUp />}
            className={'uppercase'}
            size={'md'}
          >
            Back to top
          </BpButton>
          <BpButton
            onClick={() => dispatch(hideModal())}
            className={'uppercase'}
            theme={'outline'}
            size={'md'}
          >
            Close
          </BpButton>
        </div>
      </div>
    </div>
  )
}
