import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconTrash = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 1.5C7 1.22386 7.22386 1 7.5 1H8.5C8.77614 1 9 1.22386 9 1.5L12.5 1.5C12.7761 1.5 13 1.72386 13 2C13 2.27614 12.7761 2.5 12.5 2.5L3.5 2.5C3.22386 2.5 3 2.27614 3 2C3 1.72386 3.22386 1.5 3.5 1.5L7 1.5ZM3 3.5C2.85952 3.5 2.72552 3.5591 2.63079 3.66283C2.53606 3.76657 2.48934 3.90537 2.50206 4.04527L3.50206 15.0453C3.52547 15.3028 3.7414 15.5 4 15.5L12 15.5C12.2586 15.5 12.4745 15.3028 12.498 15.0453L13.4979 4.04527C13.5107 3.90536 13.4639 3.76656 13.3692 3.66283C13.2745 3.5591 13.1405 3.5 13 3.5H3ZM4.45661 14.5L3.54752 4.5L12.4525 4.5L11.5434 14.5L4.45661 14.5ZM7 7.5C7 7.22386 6.77614 7 6.5 7C6.22386 7 6 7.22386 6 7.5L6 12.5C6 12.7761 6.22386 13 6.5 13C6.77615 13 7 12.7761 7 12.5L7 7.5ZM9.5 7C9.77614 7 10 7.22386 10 7.5L10 12.5C10 12.7761 9.77614 13 9.5 13C9.22386 13 9 12.7761 9 12.5L9 7.5C9 7.22386 9.22386 7 9.5 7Z'
        fill={color}
      />
    </svg>
  )
}
