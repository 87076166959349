import { CommonMainPage } from '../../../utility/CommonMainPage'
import { BpTabs } from '../../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../../shared/components/utility/tabs/tab/BpTab'
import { useGetInfoQuery } from '../../../../../api/project/Project.api'
import { useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { IconButton } from '../../../../../shared/components/buttons/icon-button/IconButton'
import { ProjectMainSettings } from '../ProjectMainSettings'
import { useAppDispatch } from '../../../../../store/hooks/Redux'
import { setModal } from '../../../../../store/reducers/AppSlice'
import { ProjectLocations } from '../ProjectLocations'
import { ProjectEditName } from '../ProjectEditName'
import { ProjectTerms } from '../ProjectTerms'
import { useTranslation } from 'react-i18next'
import { IconArrowBack } from '../../../../../shared/components/icons/IconArrowBack'
import { IconPencil } from '../../../../../shared/components/icons/IconPencil'
import { TariffButton } from '../../../../../shared/components/buttons/tariff-button/TariffButton'

export const EditProjectsPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data } = useGetInfoQuery(
    { id: id || '' },
    {
      selectFromResult: (result) => {
        const item = result.data?.data.items[0]

        return {
          ...result,
          data: item,
        }
      },
      skip: !id,
    },
  )

  return (
    <CommonMainPage>
      <div className={'flex mb-[12px] min-h-[36px] gap-[16px] items-center'}>
        <IconButton size='lg' onClick={() => navigate(-1)}>
          <IconArrowBack color='#00326D' />
        </IconButton>
        <h2>{data?.name}</h2>
        <IconButton
          className={data?.name ? 'visible' : 'hidden'}
          size={'lg'}
          onClick={() =>
            dispatch(setModal({ children: <ProjectEditName id={id} name={data?.name} /> }))
          }
        >
          <IconPencil color='#00326D' />
        </IconButton>
        <TariffButton />
      </div>
      <BpTabs
        size={'md'}
        variant={'row'}
        className={'px-[16px] xl:px-[32px] -mx-[16px] xl:-mx-[32px]'}
      >
        <BpTab title={t('pages.project.general')}>
          <ProjectMainSettings />
        </BpTab>
        <BpTab title={t('pages.project.locations')}>
          <ProjectLocations />
        </BpTab>
        <BpTab title={t('pages.project.searchTerms')}>
          <ProjectTerms />
        </BpTab>
      </BpTabs>
    </CommonMainPage>
  )
}
