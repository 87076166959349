import { BpButton } from '../../buttons/bp-button/BpButton'
import { IconArrowLeft } from '../../icons/IconArrowLeft'
import { IconArrowRight } from '../../icons/IconArrowRight'
import { ChangeEvent, useEffect, useState } from 'react'
import { TablePaginationConfig } from 'antd'
import { BpSelect } from '../../forms/select/BpSelect'
import { BpInput } from '../../forms/input/BpInput'
import { useDebounce } from 'use-debounce'
import { useTranslation } from 'react-i18next'

export interface IBpPaginationProps extends TablePaginationConfig {
  total?: number
  totalRows?: number
  className?: string
  disabled?: boolean
  sizePagination?: 'sm' | 'md'
}

export const BpPagination = ({
  pageSize = 10,
  total = 1,
  current = 1,
  totalRows = 1,
  onChange,
  className,
  disabled = false,
  sizePagination: size = 'md',
}: IBpPaginationProps) => {
  const { t } = useTranslation()
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSize)
  const [currentPage, setCurrentPage] = useState<number>(current)
  const [currentTotal, setCurrentTotal] = useState(total)
  const [debouncedValue] = useDebounce(currentPage, 1000)

  useEffect(() => {
    total <= 0 ? setCurrentTotal(1) : setCurrentTotal(total)
  }, [total])

  useEffect(() => {
    if (currentPage > currentTotal) setCurrentPage(currentTotal)
  }, [currentTotal])

  useEffect(() => {
    if (onChange) onChange(currentPage, currentPageSize)
    if (!debouncedValue) setCurrentPage(1)
  }, [debouncedValue, currentPageSize])

  const handlePrevious = () => {
    const result = currentPage - 1
    setCurrentPage(result <= 0 ? 1 : result)
  }

  const handleNext = () => {
    const result = currentPage + 1
    setCurrentPage(result > currentTotal ? currentTotal : result)
  }

  const handleChangePage = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value.replace(/\D/, ''))

    if (value <= currentTotal) {
      setCurrentPage(value)
    }
  }
  return (
    <div
      className={`flex flex-col md:items-center justify-between gap-[16px] lg:gap-[76px] lg:flex-row ${className}`}
    >
      <div className='flex flex-col md:flex-row md:items-center justify-between flex-1 gap-[16px] md:w-full lg:w-auto'>
        {size === 'md' && (
          <div className='flex items-center gap-[12px]'>
            <BpSelect
              className='w-[110px]'
              value={currentPageSize}
              defaultValue={pageSize}
              options={[
                { value: '10', text: '10' },
                { value: '20', text: '20' },
                { value: '50', text: '50' },
                { value: '100', text: '100' },
                { value: '500', text: '500' },
              ]}
              hideIcon
              onChange={(e) => setCurrentPageSize(+e.target.value)}
              disabled={disabled}
              height={110}
            ></BpSelect>
            <span className='text-focus-500 text-[15px]'>{t('table.pagination.itemsPerPage')}</span>
          </div>
        )}
        <div className='text-focus-500 text-[15px]'>
          {t('table.pagination.ofItems', {
            page: `${currentPage * currentPageSize - currentPageSize + 1}–${
              currentPage * currentPageSize > totalRows ? totalRows : currentPage * currentPageSize
            }`,
            total: totalRows,
          })}
        </div>
        {size === 'md' && (
          <div className='flex items-center gap-[12px]'>
            <div className='w-[50px]'>
              <BpInput
                value={currentPage}
                defaultValue={current}
                onChange={handleChangePage}
                max={currentTotal}
                disabled={disabled}
              />
            </div>
            <span className='text-focus-500 text-[15px]'>
              {t('table.pagination.ofPages', { total: currentTotal })}
            </span>
          </div>
        )}
      </div>
      <div className='flex items-center gap-[8px] justify-between lg:justify-center md:w-full lg:w-auto'>
        <BpButton
          iconLeft={<IconArrowLeft />}
          theme='outline'
          onClick={handlePrevious}
          disabled={disabled || currentPage <= 1}
        >
          {t('table.pagination.previous')}
        </BpButton>
        <BpButton
          iconRight={<IconArrowRight />}
          theme='outline'
          onClick={handleNext}
          disabled={disabled || currentPage >= currentTotal}
        >
          {t('table.pagination.next')}
        </BpButton>
      </div>
    </div>
  )
}
