import React, { useEffect } from 'react'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { useGetProjectsQuery, useGetRolesQuery } from '../../../../api/filter/Filter.api'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { hideModal } from '../../../../store/reducers/AppSlice'
import { useEditUserMutation, useGetUserQuery } from '../../../../api/team/Team.api'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import { useTranslation } from 'react-i18next'

export const EditModal = ({ id }: { id: number }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [editUser] = useEditUserMutation()
  const { data, isLoading, isFetching } = useGetUserQuery({ id })

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery({ all: true })

  const { data: roles, isFetching: rolesFetching, isLoading: rolesLoading } = useGetRolesQuery()

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onChange',
  })

  const form = watch()

  const submit = (values: any) => {
    editUser({ id, ...values }).then(() => dispatch(hideModal()))
  }

  useEffect(() => {
    if (data?.data) {
      reset({
        name: data.data.name,
        email: data.data.email,
        sub_role: data.data.sub_role,
        projects: data.data.project_ids.map((value: number) => String(value)),
        autoAssign: data.data.auto_assign_new_projects,
      })
    }
  }, [data, roles])

  if (isLoading || isFetching) {
    return (
      <div className='relative min-h-[200px]'>
        <Preloader />
      </div>
    )
  }

  return (
    <form className={'flex flex-col'} onSubmit={handleSubmit(submit)}>
      <h3
        className={
          'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        Edit member
      </h3>
      <div className={'flex flex-col gap-[16px] pt-[18px] pb-[16px] px-[24px]'}>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              label={t('fields.assignName')}
              placeholder={t('fields.assignName')}
              required
              error={errors.name?.message as string}
              {...register('name', {
                required: t('fields.validate.nameRequired'),
              })}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              label={t('fields.email')}
              placeholder={t('fields.email')}
              disabled
              required
              error={errors.email?.message as string}
              {...register('email', {
                required: t('fields.validate.emailRequired'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('fields.validate.enterValidEmail'),
                },
              })}
            />
          )}
        />
        <Controller
          name='sub_role'
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => (
            <BpSelect
              {...fieldProps}
              height={150}
              options={roles}
              loading={rolesLoading || rolesFetching}
              disabled={!roles?.length}
              showSearch
              label={t('fields.role')}
              placeholder={t('fields.role')}
              required
              error={errors.sub_role?.message as string}
              {...register('sub_role', {
                required: t('fields.validate.required'),
                onChange: (e) => {
                  setValue('projects', [])
                  onChange(e)
                },
              })}
            />
          )}
        />
        {form.sub_role === 'user' && (
          <Controller
            name='projects'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSelect
                {...fieldProps}
                height={150}
                options={projects}
                loading={projectsLoading || projectsFetching}
                disabled={!projects?.length}
                multiple
                showSearch
                label={t('fields.projects')}
                placeholder={t('fields.projects')}
                required
                error={errors.projects?.message as string}
                {...register('projects', {
                  required: t('fields.validate.atLeastOneProject'),
                })}
              />
            )}
          />
        )}
        <div className={'flex items-center gap-[9px] px-[2px]'}>
          <Controller
            name={'autoAssign'}
            control={control}
            render={({ field: { value, ...fieldProps } }) => (
              <BpCheckbox
                {...fieldProps}
                checked={value}
                defaultChecked={false}
                label={t('fields.automaticallyAssign')}
              />
            )}
          />
          <BpTooltip
            title={
              'The invited user will be automatically assigned to every new project created in the workspace. This saves time and ensures they have immediate access to new projects without additional actions from your side.'
            }
          />
        </div>
      </div>
      <div
        className={
          'w-full flex gap-[12px] px-[24px] py-[24px] border-t-[3px] border-t-[#00326D]/[.02]'
        }
      >
        <BpButton type='submit' disabled={!isValid} className={'uppercase'}>
          {t('buttons.save')}
        </BpButton>
        <BpButton type='button' theme='transparent' onClick={() => dispatch(hideModal())}>
          {t('buttons.cancel')}
        </BpButton>
      </div>
    </form>
  )
}
