import { useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { URL_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { FormHTMLAttributes } from 'react'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import {
  useGetAssistanceRequestQuery,
  useGetCompanyRoleQuery,
} from '../../../../api/filter/Filter.api'

export interface ICompanySettingsFormData {
  companyName: string
  companyWebsite: string
  companyRole: string
  assistanceRequest: string[]
}

export interface ICompanySettingsFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: ICompanySettingsFormData) => void
  isFetching?: boolean
}

export const CompanySettingsForm = ({
  submitHandler,
  isFetching = false,
  ...formProps
}: ICompanySettingsFormProps) => {
  const { data: companyRole } = useGetCompanyRoleQuery()
  const { data: assistanceRequest } = useGetAssistanceRequestQuery()

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ICompanySettingsFormData>({
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      companyWebsite: '',
      companyRole: '',
      assistanceRequest: [],
    },
  })

  return (
    <>
      <h2 className='mb-[24px]'>Create your account</h2>
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: ICompanySettingsFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label='Company Name'
          required
          error={errors.companyName?.message}
          {...register('companyName', {
            required: 'Company Name is required',
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label='Company website'
          required
          error={errors.companyWebsite?.message}
          {...register('companyWebsite', {
            required: 'Company Website is required',
            pattern: {
              value: URL_REGEX,
              message: 'Invalid website address',
            },
          })}
        ></BpInput>
        <BpSelect
          label='Company role'
          options={companyRole}
          {...register('companyRole', {
            required: 'Required',
          })}
          required
        />
        <BpSelect
          label='What can we help you with'
          options={assistanceRequest}
          height={150}
          multiple
          {...register('assistanceRequest', {
            required: 'Required',
          })}
          required
        />
        <div className={'pt-[24px]'}>
          <BpButton className={'uppercase w-full'} type='submit' disabled={!isValid} size='lg'>
            Start with 14 day trial
          </BpButton>
        </div>
      </form>
    </>
  )
}
