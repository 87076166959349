import { BpButton } from '../../buttons/bp-button/BpButton'
import { useTranslation } from 'react-i18next'

export interface IFormControlsParams {
  disabled?: boolean
  onCancel?: () => void
  onSubmit?: () => void
}

export const FormControls = ({ disabled = false, onCancel, onSubmit }: IFormControlsParams) => {
  const { t } = useTranslation()

  return (
    <>
      <BpButton type='submit' disabled={disabled} onClick={onSubmit}>
        {t('buttons.saveChanges')}
      </BpButton>
      <BpButton type='button' theme='outline' onClick={onCancel}>
        {t('buttons.cancel')}
      </BpButton>
    </>
  )
}
