import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store/Store'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'

type TeamState = {
  pagination: IPagination
}

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    pagination: {
      current: 1,
      pageSize: 50,
      total: 1,
    },
  } as TeamState,
  reducers: {
    setTeamPagination: (state: TeamState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload
    },
  },
})

export const { setTeamPagination } = teamSlice.actions

export const selectTeamPagination = (state: RootState) => state.root.team.pagination

export default teamSlice.reducer
