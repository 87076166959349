import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ILoginParams } from './interfaces/LoginParams.interface'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IUserModel } from './models/User.model'
import { login, logOut } from '../../store/reducers/AuthSlice'
import { IRequestPasswordResetParams } from './interfaces/RequestPasswordResetParams.interface'
import { IContactParams } from './interfaces/ContactParams.interface'
import { IContactModel } from './models/Contact.model'
import { ISetUpPasswordFormData } from '../../pages/auth/set-up-password/form/SetUpPasswordForm'

export const siteApi = createApi({
  reducerPath: 'site/api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/site/',
  }),
  endpoints: (build) => ({
    login: build.query<ICommonResponse<IUserModel>, ILoginParams>({
      query: (params: ILoginParams) => ({
        url: 'login',
        method: 'POST',
        credentials: 'include',
        body: (() => {
          const urlencoded = new URLSearchParams()
          urlencoded.append('email', params.email)
          urlencoded.append('password', params.password)
          return urlencoded
        })(),
      }),
      transformResponse(baseQueryReturnValue: ICommonResponse<any>): any {
        if (!baseQueryReturnValue.success) {
          return {
            ...baseQueryReturnValue,
            errors: {
              login: ['Incorrect email or password.'],
            },
          }
        }

        return baseQueryReturnValue
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        if (data.data) dispatch(login(data.data))
      },
    }),
    logout: build.query<void, void>({
      query: () => ({
        url: 'logout',
        method: 'POST',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(logOut())
      },
    }),
    currentUser: build.query<ICommonResponse<IUserModel>, void>({
      query: () => 'currentuser',
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          data.data ? dispatch(login(data.data)) : dispatch(logOut())
        } catch (e) {
          dispatch(logOut())
        }
      },
    }),
    requestPasswordReset: build.query({
      query: (params: IRequestPasswordResetParams) => ({
        url: 'request-password-reset',
        method: 'POST',
        body: (() => {
          const urlencoded = new URLSearchParams()
          urlencoded.append('email', params.email)
          return urlencoded
        })(),
      }),
    }),
    resetPassword: build.query({
      query: (params: ISetUpPasswordFormData & { token: string }) => ({
        url: 'reset-password',
        params: { token: params.token },
        method: 'POST',
        body: (() => {
          const urlencoded = new URLSearchParams()
          urlencoded.append('password', params.password)
          console.log(urlencoded)
          return urlencoded
        })(),
      }),
    }),
    contact: build.query<ICommonResponse<IContactModel>, IContactParams>({
      query: ({ email, name, message, company, reCaptcha, ...params }: IContactParams) => ({
        url: 'contact',
        method: 'POST',
        body: (() => {
          const urlencoded = new URLSearchParams()
          urlencoded.append('email', `${email}`)
          urlencoded.append('name', `${name}`)
          urlencoded.append('message', `${message}`)
          urlencoded.append('company', `${company}`)
          urlencoded.append(
            'utm',
            JSON.stringify({
              source: params.utm_source,
              medium: params.utm_medium,
              campaign: params.utm_campaign,
              term: params.utm_term,
              content: params.utm_content,
            }),
          )
          return urlencoded
        })(),
      }),
    }),
    signup: build.query<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'signup',
        method: 'POST',
        body: (() => {
          const urlencoded = new URLSearchParams()
          urlencoded.append('scenario', params.scenario)
          urlencoded.append('fullName', params.fullName)
          urlencoded.append('email', params.email)

          if (params.password) {
            urlencoded.append('password', params.password)
            urlencoded.append('notifyNews', JSON.stringify(params.notifyNews))
          } else {
            urlencoded.append('notifyNews', JSON.stringify(true))
          }

          if (params.scenario === 'second') {
            urlencoded.append('companyName', params.companyName)
            urlencoded.append('companyWebsite', params.companyWebsite)
            urlencoded.append('companyRole', params.companyRole)
            params.assistanceRequest?.map((value: string) => {
              urlencoded.append('assistanceRequest[]', value)
            })
          }

          return urlencoded
        })(),
      }),
    }),
    googleOauth: build.query<any, any | void>({
      query: (params: any) => ({ url: 'google-oauth', params }),
    }),
    sendVerification: build.query<any, void>({
      query: (params: any) => ({ url: 'send-verification-email', params }),
    }),
  }),
})

export const {
  useLazyLoginQuery,
  useLazyLogoutQuery,
  useCurrentUserQuery,
  useLazyRequestPasswordResetQuery,
  useLazyResetPasswordQuery,
  useLazyContactQuery,
  useLazySignupQuery,
  useLazyGoogleOauthQuery,
  useLazySendVerificationQuery,
} = siteApi
