import { Field, IFieldProps, TFieldTheme } from '../utility/field/Field'
import React, { ForwardedRef, forwardRef, TextareaHTMLAttributes, useEffect, useState } from 'react'

export interface IBpTextAreaProps
  extends Omit<IFieldProps, 'children'>,
    TextareaHTMLAttributes<HTMLTextAreaElement> {
  disabled?: boolean
  error?: string
  success?: string
  height?: 'md' | 'lg'
}

export const BpTextArea = forwardRef(
  (props: IBpTextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const { label, fieldSize, error, info, success, height = 'md', ...areaProps } = props
    const { disabled, required } = areaProps

    const [theme, setTheme] = useState<TFieldTheme>('default')
    const [message, setMessage] = useState<string>()
    const [isFocus, setIsFocus] = useState<boolean>(false)

    useEffect(() => {
      if (disabled) {
        setTheme('disabled')
      } else if (error) {
        setTheme('error')
        setMessage(error)
      } else if (success) {
        setTheme('success')
        setMessage(success)
      } else {
        setMessage('')
        isFocus ? setTheme('focus') : setTheme('default')
      }
    })

    return (
      <Field
        theme={theme}
        message={message}
        label={label}
        fieldSize={fieldSize}
        required={required}
        info={info}
      >
        <textarea
          {...areaProps}
          ref={ref}
          className={`
            flex 
            h-[113px]
            ${{ md: 'h-full', lg: 'h-[114px]' }[height]}
            p-[12px] 
            rounded-[8px]
            outline-none
            border-none
            text-[15px]
            text-focus-500
            placeholder:text-aqua-300
            w-full
            disabled:bg-transparent
          `}
          onFocus={() => setIsFocus(true)}
          onBlur={(e) => {
            setIsFocus(false)
            areaProps.onBlur && areaProps.onBlur(e)
          }}
        />
      </Field>
    )
  },
)
