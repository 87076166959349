import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { TTableResponse } from '../../shared/types/TableResponse.type'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'

export const teamApi = createApi({
  reducerPath: 'team/api',
  tagTypes: ['Team'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/team-management/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getList: build.query<TTableResponse<any>, any>({
      query: (params: any) => ({ url: 'list', params }),
      providesTags: ['Team'],
    }),
    changeStatus: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'user-change-status',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('id', params.id)
          formData.append('status', params.status)

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Team'],
    }),
    getUser: build.query<ICommonResponse<any>, any>({
      query: (params: any) => ({ url: 'edit-user', params }),
      providesTags: ['Team'],
    }),
    addUser: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'add-user',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('name', params.name)
          formData.append('email', params.email)
          formData.append('sub_role', params.sub_role)
          formData.append('project_ids', (params.projects || []).join(','))
          formData.append('auto_assign_new_projects', JSON.stringify(params.autoAssign))
          formData.append('send_invitation', JSON.stringify(true))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Team'],
    }),
    editUser: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'edit-user',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('id', JSON.stringify(params.id))
          formData.append('name', params.name)
          formData.append('sub_role', params.sub_role)
          formData.append('project_ids', (params.projects || []).join(','))
          formData.append('auto_assign_new_projects', JSON.stringify(params.autoAssign))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Team'],
    }),
    sendInvite: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'send-invite',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('user_id', JSON.stringify(params.id))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Team'],
    }),
  }),
})

export const {
  useGetListQuery,
  useChangeStatusMutation,
  useGetUserQuery,
  useAddUserMutation,
  useEditUserMutation,
  useSendInviteMutation,
} = teamApi
