import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconPencil = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9392 4.98929C14.0334 5.0834 14.0861 5.21115 14.0857 5.34425C14.0853 5.47734 14.0319 5.60479 13.9373 5.69837L11.1102 8.49385L4.4548 15.1808C4.36096 15.2751 4.23343 15.3281 4.10041 15.3281L1.27198 15.3281C1.13938 15.3281 1.0122 15.2754 0.918431 15.1817C0.824663 15.0879 0.771984 14.9607 0.771984 14.8281L0.771985 11.9997C0.771985 11.8671 0.824663 11.7399 0.918431 11.6461L7.57529 4.98929L10.4037 2.16086C10.599 1.9656 10.9156 1.9656 11.1108 2.16086L13.9392 4.98929ZM10.4057 7.78419L12.8766 5.34086L10.7573 3.22152L8.28239 5.6964L3.24681 10.732L5.36429 12.8495L10.4029 7.787L10.4029 7.78699L10.4057 7.78419ZM4.65886 13.5582L2.5397 11.4391L1.77198 12.2068L1.77198 14.3281L3.89261 14.3281L4.65886 13.5582Z'
        fill={color}
      />
    </svg>
  )
}
