export const articles = [
  {
    text: 'Tackling Affiliate Policy Violations',
    href: 'https://bluepear.net/blog/tackling-affiliate-policy-violations',
  },
  {
    text: 'Coupon Code Affiliate Monitoring',
    href: 'https://bluepear.net/blog/affiliate-code-risks',
  },
  {
    text: 'How to Automate Competitor Analysis',
    href: 'https://bluepear.net/blog/automate-competitor-analysis-in-search-results',
  },
  {
    text: 'How to Keep Compliance in Check',
    href: 'https://bluepear.net/blog/digital-brand-monitoring-affiliate-program-violations',
  },
  {
    text: 'All articles',
    href: 'https://bluepear.net/blog',
  },
]
