import { useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import { BpLink } from '../../../../shared/components/text/link/BpLink'
import { FormHTMLAttributes } from 'react'
import { GoogleButton } from '../../utility/GoogleButton'

export interface ISignUpFormData {
  scenario?: string
  fullName?: string
  email?: string
  password?: string
  notifyNews?: boolean
}

export interface ISignUpFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: ISignUpFormData) => void
  isFetching?: boolean
}

export const SignUpForm = ({
  submitHandler,
  isFetching = false,
  ...formProps
}: ISignUpFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ISignUpFormData>({
    mode: 'onChange',
    defaultValues: {
      fullName: '',
      email: '',
      password: '',
      notifyNews: true,
    },
  })

  return (
    <>
      <h2 className='mb-[24px]'>
        Create account and start your{' '}
        <span className={'text-default font-medium'}>free 14 day trial</span>
      </h2>
      <GoogleButton />
      <div className={'h-[1px] bg-focus/[.15] my-[16px] flex items-center justify-center'}>
        <div className={'bg-white px-[3px] text-aqua-300'}>or</div>
      </div>
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: ISignUpFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label='Full Name'
          required
          error={errors.fullName?.message}
          {...register('fullName', {
            required: 'Full Name is required',
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label='Work E-mail'
          required
          error={errors.email?.message}
          {...register('email', {
            required: 'Work Email is required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Please enter a valid email',
            },
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label={'Password'}
          type='password'
          required
          error={errors.password?.message}
          {...register('password', {
            required: 'Password is required',
            pattern: {
              value: PASSWORD_REGEX,
              message:
                'Password must contain at least one number, one upper-case letter, one lower-case letter and one special character.',
            },
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters',
            },
          })}
        ></BpInput>
        <div className={'pt-[24px]'}>
          <BpCheckbox
            label={'Receive news and reports from Bluepear'}
            {...register('notifyNews')}
          />
        </div>
        <BpButton className={'uppercase w-full'} type='submit' disabled={!isValid} size='lg'>
          Continue
        </BpButton>
        <div>
          <p>
            By continuing, you agree to the{' '}
            <a className={'underline'} href={'https://bluepear.net/privacy-policy'}>
              Privacy Policy
            </a>
          </p>
          <p>
            Already have an account? <BpLink to={'/auth'}>Log in</BpLink>
          </p>
        </div>
      </form>
    </>
  )
}
