import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { siteApi } from '../api/site/Site.api'
import app from './reducers/AppSlice'
import auth from './reducers/AuthSlice'
import research from '../pages/main/research-tools/store/ResearchSlice'
import competitors from '../pages/main/research-tools/store/CompetitorsSlice'
import keywords from '../pages/main/research-tools/store/KeywordsSlice'
import adOverview from '../pages/main/ad-overview/store/AdOverviewSlice'
import advertiserReport from '../pages/main/advertiser-report/store/AdvertiserReportSlice'
import organicResults from '../pages/main/organic-results/store/OrganicResultsSlice'
import positionTracker from '../pages/main/position-tracker/store/PositionTrackerSlice'
import detailedInfo from '../pages/main/detailed-info/store/DetailedInfoSlice'
import serpPresence from '../pages/main/serp-presence/store/SerpPresenceSlice'
import overview from '../pages/main/overview/store/OverviewSlice'
import liveSearch from '../pages/main/live-search/store/LiveSearchSlice'
import liveUncloaking from '../pages/main/live-uncloaking/store/LiveUncloakingSlice'
import blackhatMonitoring from '../pages/main/blackhat-monitoring/store/BlackhatMonitoringSlice'
import ppcCompliance from '../pages/main/ppc-compliance/store/PpcComplianceSlice'
import projects from '../pages/main/projects/store/ProjectsSlice'
import languageCompliance from '../pages/main/language-compliance/store/LanguageComplianceSlice'
import team from '../pages/main/team/store/TeamSlice'
import { profileApi } from '../api/profile/Profile.api'
import { researchApi } from '../api/research/Research.api'
import { filterApi } from '../api/filter/Filter.api'
import { adOverviewApi } from '../api/ad-overview/AdOverview.api'
import { advertiserReportsApi } from '../api/advertiser-report/AdvertiserReport.api'
import { decloakApi } from '../api/decloak/Decloak.api'
import { overviewApi } from '../api/overview/Overview.api'
import { featuresApi } from '../api/features/Features.api'
import { complianceApi } from '../api/compliance/Compliance.api'
import { languageComplianceApi } from '../api/language-compliance/LanguageCompliance.api'
import { affiliateApi } from '../api/affiliate/Affiliate.api'
import { rtkQueryErrorLogger } from './ErrorLogger'
import { exportReportsApi } from '../api/export-reports/ExportReports.api'
import { projectApi } from '../api/project/Project.api'
import { tariffApi } from '../api/tariff/Tariff.api'
import { userApi } from '../api/user/User.api'
import { adminApi } from '../api/admin/Admin.api'
import { teamApi } from '../api/team/Team.api'

const rootReducer = combineReducers({
  auth,
  app,
  research,
  competitors,
  keywords,
  adOverview,
  advertiserReport,
  overview,
  liveSearch,
  liveUncloaking,
  blackhatMonitoring,
  organicResults,
  detailedInfo,
  positionTracker,
  serpPresence,
  ppcCompliance,
  projects,
  languageCompliance,
  team,
})

export const store = configureStore({
  devTools: true,
  reducer: {
    root: rootReducer,
    [siteApi.reducerPath]: siteApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [researchApi.reducerPath]: researchApi.reducer,
    [filterApi.reducerPath]: filterApi.reducer,
    [adOverviewApi.reducerPath]: adOverviewApi.reducer,
    [advertiserReportsApi.reducerPath]: advertiserReportsApi.reducer,
    [decloakApi.reducerPath]: decloakApi.reducer,
    [overviewApi.reducerPath]: overviewApi.reducer,
    [featuresApi.reducerPath]: featuresApi.reducer,
    [exportReportsApi.reducerPath]: exportReportsApi.reducer,
    [complianceApi.reducerPath]: complianceApi.reducer,
    [languageComplianceApi.reducerPath]: languageComplianceApi.reducer,
    [affiliateApi.reducerPath]: affiliateApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [tariffApi.reducerPath]: tariffApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      siteApi.middleware,
      profileApi.middleware,
      researchApi.middleware,
      filterApi.middleware,
      adOverviewApi.middleware,
      advertiserReportsApi.middleware,
      decloakApi.middleware,
      overviewApi.middleware,
      featuresApi.middleware,
      exportReportsApi.middleware,
      complianceApi.middleware,
      languageComplianceApi.middleware,
      affiliateApi.middleware,
      projectApi.middleware,
      tariffApi.middleware,
      userApi.middleware,
      adminApi.middleware,
      teamApi.middleware,
      rtkQueryErrorLogger,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
