import { useParams } from 'react-router-dom'
import { useGetTermsQuery, useUpdateTermsMutation } from '../../../../api/project/Project.api'
import { BpTextArea } from '../../../../shared/components/forms/textarea/BpTextArea'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectProjectTerms,
  selectProjectTermsChanged,
  setProjectTerms,
  updateProjectTerm,
} from '../store/ProjectsSlice'
import { ITerm } from '../interfaces/Term.interface'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import { useTranslation } from 'react-i18next'

export const ProjectTerms = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const termsList = useAppSelector(selectProjectTerms)
  const termsChanged = useAppSelector(selectProjectTermsChanged)
  const dispatch = useAppDispatch()

  const { data, isSuccess, isLoading, isFetching } = useGetTermsQuery(
    { project_id: id || '' },
    { skip: !id },
  )

  const [updateTerms] = useUpdateTermsMutation()

  useEffect(() => {
    if (isSuccess && data?.data.items) {
      dispatch(setProjectTerms(data?.data.items))
    }
  }, [isFetching])

  const submit = () => {
    updateTerms({ id, data: termsList })
  }

  const cancel = () => {
    dispatch(setProjectTerms(data?.data.items))
  }

  if (isLoading || isFetching) {
    return <Preloader />
  }

  return (
    <div className={'grid grid-rows-[auto_1fr_auto] h-full items-start'}>
      <div className={'flex min-h-[40px] items-center justify-between'}>
        <p className={'max-w-[750px] text-focus-500 text-[13px]'}>
          {t('pages.project.termsDescription')}
        </p>
      </div>
      <div className={'grid grid-cols-3 gap-x-[32px] gap-y-[16px] mt-[32px]'}>
        {termsList?.map((term: ITerm, index: number) => (
          <div key={index} className={'w-full'}>
            <BpTextArea
              label={`${term.country_name}, ${term.language_name}`}
              value={term.terms.join('\n')}
              placeholder={t('pages.project.placeholders.terms')}
              onChange={(e) => {
                dispatch(updateProjectTerm({ ...term, terms: e.target.value.split('\n') }))
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={
          'flex gap-[16px] px-[16px] xl:px-[32px] -mx-[16px] xl:-mx-[32px] border-t-[3px] border-focus-600/[.03] pt-[24px] mt-[24px]'
        }
      >
        <BpButton disabled={!termsChanged} onClick={submit} className={'uppercase'}>
          {t('buttons.saveChanges')}
        </BpButton>
        <BpButton disabled={!termsChanged} onClick={cancel} theme={'outline'}>
          {t('buttons.cancel')}
        </BpButton>
      </div>
    </div>
  )
}
