import React, {
  Children,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { IBpTabProps } from './tab/BpTab'
import { NavItem } from '../../cards/nav-item/NavItem'
import { BpButton } from '../../buttons/bp-button/BpButton'
import { IconArrowBack } from '../../icons/IconArrowBack'

type BpTab = ReactElement<PropsWithChildren<IBpTabProps>>

export interface BpTabsProps {
  children: BpTab | BpTab[]
  active?: string
  onToggle?: (activeTab: string) => void
  className?: string
  back?: () => void
  selector?: boolean
  size?: 'md' | 'lg'
  variant?: 'default' | 'row'
}

export const BpTabs = (props: BpTabsProps) => {
  const { children, className = '', size = 'lg', variant = 'default', active, onToggle } = props
  const [panel, setPanel] = useState<ReactNode | undefined>()
  const [activeTab, setActiveTab] = useState<number>(() => {
    let result = 0
    if (active) {
      Children.forEach(children, (child, index) => {
        if (child.props.title == active) {
          result = index
        }
      })
    }
    return result
  })

  useEffect(() => {
    Children.forEach(children, (child, index) => {
      if (index === activeTab) setPanel(child.props.panel)
    })
    if (onToggle) {
      const tab = Children.toArray(children)[activeTab]
      onToggle((tab as BpTab).props.title)
    }
  }, [activeTab])

  return (
    <>
      <div
        className={`flex md:items-center justify-start flex-col md:flex-row gap-[12px] ${
          variant === 'row' && 'shadow-row-shadow'
        } ${className}`}
      >
        {props.back && (
          <BpButton
            className='mr-1'
            contentClassName='md:hidden'
            size='sm'
            theme='outline'
            iconLeft={<IconArrowBack />}
            onClick={props.back}
          >
            Go back
          </BpButton>
        )}
        <ul className='flex gap-[31px] mr-auto overflow-y-auto max-w-full scrollbar-hide'>
          {Children.map(children, (child, index) => {
            return (
              <li>
                <NavItem
                  size={size}
                  theme='dark'
                  active={index == activeTab}
                  className={'flex flex-col items-center gap-[6px]'}
                  onMouseDown={() => setActiveTab(index)}
                  decoration={variant === 'row' ? 'rounded' : 'line'}
                >
                  <div className={'flex items-center gap-[6px]'}>
                    <div className={'font-medium'}>{child?.props.title}</div>
                    {child?.props.counter && (
                      <div
                        className={`items-center
                          h-[22px]
                          flex
                          ${
                            index == activeTab
                              ? 'bg-[#A059FF] text-white'
                              : 'bg-[#E8EDF2] text-[#6684A7]'
                          } font-medium text-[12px] px-[6px] rounded-[4px]`}
                      >
                        {child?.props.counter}
                      </div>
                    )}
                  </div>
                </NavItem>
              </li>
            )
          })}
        </ul>
        {panel && panel}
      </div>
      {!props.selector && (
        <section className='py-[24px] flex-1'>{Children.toArray(children)[activeTab]}</section>
      )}
    </>
  )
}
