import { CommonMainPage } from '../../../utility/CommonMainPage'
import { ProjectsFilters } from '../ProjectsFilters'
import { ProjectsTable } from '../ProjectsTable'

export const ProjectsPage = () => {
  return (
    <CommonMainPage>
      <ProjectsFilters />
      <ProjectsTable />
    </CommonMainPage>
  )
}
