import { useAppSelector } from '../../../../store/hooks/Redux'
import { ILanguageComplianceFilter } from '../interface/LanguageComplianceFilter.interface'
import {
  selectLanguageComplianceFilter,
  selectLanguageCompliancePagination,
  selectSelectedLanguageComplianceLastCheck,
  setLanguageCompliancePagination,
  setSelectedLanguageComplianceLastCheck,
} from '../store/LanguageComplianceSlice'
import React, { useEffect, useState } from 'react'
import {
  useGetLastCheckQuery,
  useUpdateStateMutation,
} from '../../../../api/language-compliance/LanguageCompliance.api'
import { ColumnsType } from 'antd/lib/table'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { IconEye } from '../../../../shared/components/icons/IconEye'
import { IconEyeOff } from '../../../../shared/components/icons/IconEyeOff'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'antd'
import { setModal, showAlert } from '../../../../store/reducers/AppSlice'
import { LanguageComplianceReport } from './LanguageComplianceReport'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { useSorter } from '../../../../shared/hooks/UseSorter'

export const LanguageComplianceLastCheck = () => {
  const dispatch = useDispatch()
  const selectedKeys = useAppSelector(selectSelectedLanguageComplianceLastCheck)
  const filters = useAppSelector<ILanguageComplianceFilter>(selectLanguageComplianceFilter)
  const { current, pageSize, total } = useAppSelector<IPagination>(
    selectLanguageCompliancePagination,
  )
  const { project, country, url, affiliate, dateRange, restrictedWords, hidden } = filters

  const [queryParams, setQueryParams] = useState<any>({
    page: current,
    'per-page': pageSize,
  })
  const { data, isLoading, isFetching, refetch } = useGetLastCheckQuery(queryParams, {
    skip: !queryParams.project_id || !queryParams.country_id,
    refetchOnMountOrArgChange: true,
  })
  const [updateState] = useUpdateStateMutation()

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      date: dateRange,
      project_id: Number(project),
      country_id: Number(country),
      url: url,
      affiliate: affiliate,
      word_id: restrictedWords ? Number(restrictedWords) : undefined,
      show_hidden: hidden,
    })
  }, [filters])

  const columns: ColumnsType<any> = [
    {
      title: 'Page',
      dataIndex: 'url',
      key: 'url',
      render: (_: string, { url, is_auto }) => {
        return (
          <div className={'flex items-center gap-[8px]'}>
            <div className='truncate max-w-[300px]'>
              <Tooltip title={url}>
                <BpAnchor href={url} target={'_blank'}>
                  {url.replace(/^(http:\/\/|https:\/\/)?(www\.)?/, '')}
                </BpAnchor>
              </Tooltip>
            </div>
            {is_auto && (
              <span
                className={
                  'text-[9px] rounded-[2px] flex uppercase items-center h-[16px] px-[4px] text-[#4DB11E] border-[1px] border-[#4DB11E]'
                }
              >
                Auto
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: 'Infringements',
      dataIndex: 'infringements_count',
      key: 'infringements_count',
      render: (_: string, { infringements_count, hash, url }) => {
        return (
          <div className={'flex items-start gap-[6px]'}>
            {infringements_count}
            <span
              className={`text-focus
                  text-[13px]
                  leading-[13px]
                  border-dotted
                  border-b
                  cursor-pointer`}
              onClick={() => {
                dispatch(
                  setModal({
                    size: 'lg',
                    children: <LanguageComplianceReport id={hash} state={'last_check'} url={url} />,
                  }),
                )
              }}
            >
              Report
            </span>
          </div>
        )
      },
      sorter: true,
    },
    {
      title: 'Affiliate',
      dataIndex: 'affiliate',
      key: 'affiliate',
      render: (affiliate: string) => (
        <Tooltip title={affiliate}>
          <div className='truncate max-w-[250px]'>
            {affiliate || <div className='text-[#6684A7]'>Unspecified</div>}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Last Check',
      dataIndex: 'last_check',
      key: 'last_check',
      width: 175,
      sorter: true,
    },
    {
      key: 'status',
      render: (_: string, { url }) => {
        return (
          <BpButton
            theme={'outline'}
            size={'sm'}
            onClick={() => {
              updateState({
                project_id: Number(project),
                country_id: Number(country),
                urls: [url],
                state: 'in_progress',
              }).then(() => {
                dispatch(
                  showAlert({
                    message: 'Report was moved successfully',
                    type: 'success',
                    timeout: 2000,
                  }),
                )
                refetch().then(() => {
                  dispatch(
                    setSelectedLanguageComplianceLastCheck(
                      selectedKeys.filter((key) => key !== url),
                    ),
                  )
                })
              })
            }}
          >
            To in progress
          </BpButton>
        )
      },
    },
    {
      key: 'action',
      render: (_: string, { is_hidden, url }) => {
        return (
          <IconButton
            size='lg'
            onClick={() => {
              if (!project) return
              updateState({
                project_id: Number(project),
                country_id: Number(country),
                urls: [url],
                is_hidden: Number(!is_hidden),
              }).then(() => {
                refetch().then(() => {
                  dispatch(
                    setSelectedLanguageComplianceLastCheck(
                      selectedKeys.filter((key) => key !== url),
                    ),
                  )
                })
              })
            }}
          >
            {is_hidden ? <IconEye color='#A059FF' /> : <IconEyeOff color='#A059FF' />}
          </IconButton>
        )
      },
      className: 'action',
    },
  ]

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    hideSelectAll: true,
    onSelect: (record, selected) => {
      if (selected) {
        !selectedKeys?.includes(record.url) &&
          dispatch(
            setSelectedLanguageComplianceLastCheck(
              selectedKeys ? [...selectedKeys, record.url] : [record.url],
            ),
          )
      } else {
        dispatch(
          setSelectedLanguageComplianceLastCheck(selectedKeys?.filter((row) => row !== record.url)),
        )
      }
    },
  }

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<any> | SorterResult<any>[],
  ) => {
    dispatch(
      setLanguageCompliancePagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <div className='min-h-[400px]'>
      <BpTable
        rowSelection={rowSelection}
        dataSource={data?.data?.items}
        rowKey={(record) => record.url}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<any>}
        rowClassName={(e) => (e.is_hidden ? 'disabled' : '')}
        bottomSection={
          !!selectedKeys?.length && (
            <div className='py-[18px] flex justify-between items-center'>
              <p className='text-[15px] font-medium text-[#00326D]'>
                {selectedKeys.length} selected
              </p>
              <BpButton
                size='sm'
                onClick={() => {
                  updateState({
                    project_id: Number(project),
                    country_id: Number(country),
                    urls: selectedKeys,
                    state: 'in_progress',
                  }).then(() => {
                    refetch().then(() => {
                      dispatch(setSelectedLanguageComplianceLastCheck([]))
                    })
                  })
                }}
              >
                Move to In progress
              </BpButton>
            </div>
          )
        }
      />
    </div>
  )
}
