import { ISignUpFormData, SignUpForm } from './form/SignUpForm'
import { CommonAuthPage } from '../utility/CommonAuthPage'
import { SubmitHandler } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { CompanySettingsForm, ICompanySettingsFormData } from './form/CompanySettingsForm'
import { useLazySignupQuery } from '../../../api/site/Site.api'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks/Redux'
import { selectOauthUser, selectUser } from '../../../store/reducers/AuthSlice'

export const SignUpPage = () => {
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const oauthUser = useAppSelector(selectOauthUser)
  const [scenario, setScenario] = useState<'first' | 'second'>('first')
  const [signUpFormValues, setSignUpFormValues] = useState<ISignUpFormData>()

  const [signup] = useLazySignupQuery()

  const handleSubmitSignup: SubmitHandler<ISignUpFormData> = (formData: ISignUpFormData) => {
    if (user) {
      setSignUpFormValues(formData)
      setScenario('second')
    } else {
      signup({ scenario: formData.scenario ? formData.scenario : scenario, ...formData }).then(
        (response: any) => {
          if (response.data?.success) {
            setSignUpFormValues(formData)
            setScenario('second')
          }
        },
      )
    }
  }

  const handleSubmitCompanySettings: SubmitHandler<ICompanySettingsFormData> = (
    formData: ICompanySettingsFormData,
  ) => {
    signup({ ...signUpFormValues, ...formData, scenario: 'second' }).then((response: any) => {
      if (response.data?.success) {
        navigate('/projects')
      }
    })
  }

  useEffect(() => {
    if (oauthUser) {
      if (oauthUser.id && oauthUser.status === 'active') {
        navigate('/projects')
      } else if (oauthUser.id && oauthUser.status === 'uncompleted_registration') {
        setScenario('second')
      } else if (oauthUser.fullName && oauthUser.email) {
        handleSubmitSignup({
          fullName: oauthUser.fullName,
          email: oauthUser.email,
          scenario: 'first_oauth',
        })
      }
    }
  }, [oauthUser])

  return (
    <>
      <CommonAuthPage
        form={
          {
            first: <SignUpForm submitHandler={handleSubmitSignup} />,
            second: <CompanySettingsForm submitHandler={handleSubmitCompanySettings} />,
          }[scenario]
        }
      >
        {
          {
            first: (
              <div className={'flex flex-col max-w-[430px] ml-auto'}>
                <div>
                  <h1 className={'text-[24px]'}>Affiliate Compliance</h1>
                  <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                    Protect your PPC ads against brand bidding, ad hijacking, and unauthorized promo
                    codes.
                  </p>
                </div>
                <div>
                  <h1 className={'text-[24px]'}>Competitor Analysis</h1>
                  <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                    Comprehensive insights into competitor campaigns and potential trademark
                    violations.
                  </p>
                </div>
              </div>
            ),
            second: (
              <div className={'flex flex-col max-w-[430px] ml-auto'}>
                <div>
                  <h1 className={'text-[24px]'}>150+</h1>
                  <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                    Global brands trust us with their protection
                  </p>
                </div>
                <div>
                  <h1 className={'text-[24px]'}>12K+</h1>
                  <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                    Affiliates detected on a monthly basis
                  </p>
                </div>
                <div>
                  <h1 className={'text-[24px]'}>$1mil+</h1>
                  <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                    Dollars saved monthly
                  </p>
                </div>
              </div>
            ),
          }[scenario]
        }
      </CommonAuthPage>
    </>
  )
}
