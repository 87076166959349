import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { IAffiliatesAdsParams } from '../../../../api/ad-overview/interfaces/AffiliatesAdsParams.interface'
import {
  useExportAffiliatesAdsMutation,
  useGetAffiliatesAdsQuery,
} from '../../../../api/ad-overview/AdOverview.api'
import { IWithExpandedAllProps, withExpanded } from '../../../../shared/hoc/WithExpanded'
import { ColumnsType } from 'antd/lib/table'
import { ISingleAdModel } from '../../../../api/ad-overview/models/SingleAd.model'
import { BpArticle } from '../../../../shared/components/cards/article/BpArticle'
import { ToggleList } from '../../../../shared/components/cards/toggle-list/ToggleList'
import { DetailsCard } from '../../../../shared/components/cards/details/DetailsCard'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../shared/hooks/UseSorter'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import { LinksList } from './LinksList'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { IconArrowBack } from '../../../../shared/components/icons/IconArrowBack'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconMinimize } from '../../../../shared/components/icons/IconMinimize'
import { IconMaximize } from '../../../../shared/components/icons/IconMaximize'
import { ExportDrop } from '../../../../shared/components/cards/export-drop/ExportDrop'
import { useAppSelector } from '../../../../store/hooks/Redux'
import { selectAdOverviewFilters } from '../store/AdOverviewSlice'
import { IAdOverviewFilter } from '../interfaces/AdOverviewFilter.interface'
import { useTranslation } from 'react-i18next'

const Table = ({
  onToggleExpand,
  isExpandAll,
  expandedRowKeys,
  onToggleExpandAll,
}: IWithExpandedAllProps) => {
  const { t } = useTranslation()
  const { publisher_id, affiliate } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [countRows, setCountRows] = useState<number>(0)

  const { project, country, engine, city, browser, search, dateRange, policy, adDomain } =
    useAppSelector<IAdOverviewFilter>(selectAdOverviewFilters)

  const [queryParams, setQueryParams] = useState({
    page: 1,
    'per-page': 10,
    project_id: +project || searchParams.get('project'),
    publisher_id: publisher_id ? +publisher_id : undefined,
    country_id: country || Number(searchParams.get('country')),
    engine_id: engine || Number(searchParams.get('engine')),
    city_id: city || Number(searchParams.get('city')),
    search: search || searchParams.get('search'),
    browser_id: browser || Number(searchParams.get('browser')),
    date: searchParams.get('dateRange') || dateRange,
    policy: policy || searchParams.get('policy'),
    site: adDomain || searchParams.get('adDomain'),
  } as IAffiliatesAdsParams)

  const { data, isLoading, isFetching } = useGetAffiliatesAdsQuery(
    queryParams as IAffiliatesAdsParams,
    {
      skip: !publisher_id || queryParams == undefined,
    },
  )

  const [exportFile, { isLoading: exportAffiliatesAdsLoading }] = useExportAffiliatesAdsMutation()

  useEffect(() => {
    if (!data?.data) return
    setCountRows(data.data.total)
  }, [data])

  const columns: ColumnsType<ISingleAdModel> = [
    {
      title: t('table.columns.ad'),
      key: 'text',
      render: (_: string, { title, text, finish_domain }: ISingleAdModel) => {
        return <BpArticle title={title} text={text} link={finish_domain} />
      },
      width: '400px',
    },
    {
      title: t('table.columns.keyword(s)'),
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: t('table.columns.redirectLinks'),
      dataIndex: 'redirects',
      key: 'redirects',
      width: '150px',
      render: (redirects: string[], record: ISingleAdModel) => {
        const allRedirects = [
          ...redirects,
          ...(record.additionalRedirects ? record.additionalRedirects.flat() : []),
        ]
        return (
          <ToggleList
            list={allRedirects}
            title={'links'}
            onToggle={(isShow) => {
              if (onToggleExpand) onToggleExpand(isShow, `${record.title}${record.found}`)
            }}
          />
        )
      },
    },
    {
      title: t('table.columns.position'),
      dataIndex: 'position_global',
      key: 'position_global',
      width: '100px',
      sorter: true,
    },
    {
      title: t('table.columns.details'),
      key: 'details',
      width: '320px',
      render: (_: ISingleAdModel, { city, country, device, engine, ...model }: ISingleAdModel) => {
        return (
          <DetailsCard
            {...model}
            cities={city}
            countries={country}
            devices={device}
            engines={engine}
          />
        )
      },
    },
    {
      title: t('table.columns.found'),
      dataIndex: 'found',
      key: 'found',
      width: '150px',
      sorter: true,
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<ISingleAdModel> | SorterResult<ISingleAdModel>[],
  ) => {
    setQueryParams({ ...queryParams, page: current, 'per-page': pageSize, sort: useSorter(sorter) })
  }

  return (
    <section>
      <div className='mb-[16px] flex flex-col md:flex-row gap-[16px] justify-between md:items-center'>
        <span className='flex gap-[16px]'>
          <IconButton size='lg' onClick={() => navigate(-1)}>
            <IconArrowBack color='#00326D' />
          </IconButton>
          <h3 className='flex-1 font-medium'>
            {countRows} {t('pages.adOverview.adsFor')} “{affiliate && affiliate}”
          </h3>
        </span>
        <span className='flex gap-[9px]'>
          <BpButton
            size='sm'
            theme='outline'
            type='button'
            onClick={() => {
              if (onToggleExpandAll) onToggleExpandAll(!isExpandAll)
            }}
            iconLeft={isExpandAll ? <IconMinimize /> : <IconMaximize />}
          ></BpButton>
          <ExportDrop
            onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
            loading={exportAffiliatesAdsLoading}
            columns={[
              { keys: ['title', 'text', 'finish_domain'], label: t('table.columns.ad') },
              { keys: ['keyword'], label: t('table.columns.keyword(s)') },
              { keys: ['redirects'], label: t('table.columns.redirectLinks') },
              {
                keys: [
                  'id',
                  'country',
                  'city',
                  'engine',
                  'device',
                  'search_screen_url',
                  'link_screen_url',
                ],
                label: t('table.columns.details'),
              },
              { keys: ['found'], label: t('table.columns.found') },
            ]}
          />
        </span>
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => `${record.title}${record.found}`}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<ISingleAdModel>}
        expandable={{
          expandedRowRender: (record) => (
            <LinksList
              redirects={record.redirects}
              additionalRedirects={record.additionalRedirects}
              className='max-w-[980px]'
            />
          ),
          expandedRowKeys: isExpandAll
            ? data?.data?.items.map((item) => `${item.title}${item.found}`)
            : expandedRowKeys,
          showExpandColumn: false,
          expandedRowClassName: () => 'links-list',
        }}
      />
    </section>
  )
}

export const AffiliatesAdsReport = withExpanded(Table)
