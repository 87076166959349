import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'
import { hideModal } from '../../../../store/reducers/AppSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  useGetAvailableCountriesQuery,
  useLazyGetLocationsDefaultSettingsQuery,
} from '../../../../api/project/Project.api'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import { selectProjectLocations, updateProjectLocations } from '../store/ProjectsSlice'
import { useTranslation } from 'react-i18next'
import { IAddCountriesForm } from '../interfaces/AddCountriesForm.interface'

export const ProjectAddCountry = ({ id }: { id?: string }) => {
  const { t } = useTranslation()
  const locationsList = useAppSelector(selectProjectLocations)
  const dispatch = useAppDispatch()

  const { control, handleSubmit } = useForm<IAddCountriesForm>({
    mode: 'onChange',
    defaultValues: {},
  })

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetAvailableCountriesQuery(
    { project_id: id || '' },
    {
      skip: !id,
      selectFromResult: (result) => {
        const currentLocations = locationsList?.map((location) => String(location.country_id)) || []

        return {
          ...result,
          data: result.data?.filter((country) => !currentLocations.includes(country.value)),
        }
      },
    },
  )

  const [getLocationsDefaultSettings] = useLazyGetLocationsDefaultSettingsQuery()

  if (countriesLoading || countriesFetching) {
    return <Preloader />
  }

  const submit = (value: IAddCountriesForm) => {
    getLocationsDefaultSettings({ 'country_ids[]': value.countries }).then(({ data }) => {
      dispatch(updateProjectLocations(data?.data.items))
      dispatch(hideModal())
    })
  }

  return (
    <form className={'flex flex-col'} onSubmit={handleSubmit(submit)}>
      <h3
        className={
          'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        {t('pages.project.addCountry')}
      </h3>
      <div className={'flex flex-col gap-[16px] pt-[18px] pb-[16px] px-[24px]'}>
        <Controller
          name='countries'
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => (
            <BpSelect
              {...fieldProps}
              height={150}
              options={countries}
              multiple
              showSearch
              disabled={!countries?.length}
              loading={countriesLoading || countriesFetching}
              label={t('fields.countries')}
              placeholder={t('fields.selectCountries')}
              onChange={(e) => {
                const value = [].slice.call(e.target.selectedOptions).map((o: IOption) => o.value)

                onChange(value)
              }}
              required
            />
          )}
        />
      </div>
      <div
        className={
          'w-full flex gap-[12px] px-[24px] py-[24px] border-t-[3px] border-t-[#00326D]/[.02]'
        }
      >
        <BpButton type='submit' className={'uppercase'}>
          {t('buttons.saveChanges')}
        </BpButton>
        <BpButton type='button' theme='transparent' onClick={() => dispatch(hideModal())}>
          {t('buttons.cancel')}
        </BpButton>
      </div>
    </form>
  )
}
