import React, { useEffect } from 'react'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { useGetProjectsQuery } from '../../../../api/filter/Filter.api'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { hideModal } from '../../../../store/reducers/AppSlice'
import { useEditUserMutation, useGetUserQuery } from '../../../../api/team/Team.api'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'

export const EditModal = ({ id }: { id: number }) => {
  const dispatch = useAppDispatch()

  const [editUser] = useEditUserMutation()
  const { data, isLoading, isFetching } = useGetUserQuery({ id })

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery({ all: true })

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onChange',
  })

  const submit = (values: any) => {
    editUser({ id, ...values }).then(() => dispatch(hideModal()))
  }

  useEffect(() => {
    if (data?.data) {
      reset({
        name: data.data.name,
        email: data.data.email,
        projects: data.data.project_ids.map((value: number) => String(value)),
        autoAssign: data.data.auto_assign_new_projects,
      })
    }
  }, [data])

  if (isLoading || isFetching) {
    return (
      <div className='relative min-h-[200px]'>
        <Preloader />
      </div>
    )
  }

  return (
    <form className={'flex flex-col'} onSubmit={handleSubmit(submit)}>
      <h3
        className={
          'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        Edit member
      </h3>
      <div className={'flex flex-col gap-[16px] pt-[18px] pb-[16px] px-[24px]'}>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              label={'Asign name'}
              placeholder={'Asign name'}
              required
              error={errors.name?.message as string}
              {...register('name', {
                required: 'Required',
              })}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              label={'Email'}
              placeholder={'Email'}
              disabled
              required
              error={errors.email?.message as string}
              {...register('email', {
                required: 'Required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email',
                },
              })}
            />
          )}
        />
        <Controller
          name='projects'
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => (
            <BpSelect
              {...fieldProps}
              height={150}
              options={projects}
              loading={projectsLoading || projectsFetching}
              disabled={!projects?.length}
              multiple
              showSearch
              label={'Projects'}
              placeholder={'Projects'}
              required
              error={errors.projects?.message as string}
              {...register('projects', {
                required: 'At least one project must be selected',
              })}
            />
          )}
        />
        <div className={'flex items-center gap-[9px] px-[2px]'}>
          <Controller
            name={'autoAssign'}
            control={control}
            render={({ field: { value, ...fieldProps } }) => (
              <BpCheckbox
                {...fieldProps}
                checked={value}
                defaultChecked={false}
                label={'Automatically assign to all new projects'}
              />
            )}
          />
          <BpTooltip
            title={
              'The invited user will be automatically assigned to every new project created in the workspace. This saves time and ensures they have immediate access to new projects without additional actions from your side.'
            }
          />
        </div>
      </div>
      <div
        className={
          'w-full flex gap-[12px] px-[24px] py-[24px] border-t-[3px] border-t-[#00326D]/[.02]'
        }
      >
        <BpButton type='submit' disabled={!isValid} className={'uppercase'}>
          Change
        </BpButton>
        <BpButton type='button' theme='transparent' onClick={() => dispatch(hideModal())}>
          Cancel
        </BpButton>
      </div>
    </form>
  )
}
