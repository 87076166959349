import React, { useState } from 'react'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconCrown } from '../../../../shared/components/icons/IconCrown'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { hideModal } from '../../../../store/reducers/AppSlice'

export const ChangePlan = () => {
  const [state, setState] = useState<'show-info' | 'change-plan' | 'request-sent'>('show-info')
  const dispatch = useAppDispatch()

  return (
    <div>
      {
        {
          'show-info': (
            <div className={'flex flex-col'}>
              <div
                className={
                  'flex flex-col min-h-[107px] justify-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
                }
              >
                <h3 className={'text-[20px] font-medium pb-[4px]'}>Trial</h3>
                <div
                  className={
                    'flex text-default text-[16px] leading-[24px] py-[4px] px-[12px] bg-grey-300 w-fit rounded-[8px] gap-[10px]'
                  }
                >
                  <div className={'w-[13px] pt-[9px]'}>
                    <IconCrown color={'#A059FF'} />
                  </div>
                  <p className={'flex flex-col'}>
                    Used 20/20 keywords
                    <span className={'text-[12px] leading-[16px]'}>Next payment 12.11.2024</span>
                  </p>
                  <div className={'pt-[5px]'}>
                    <BpTooltip
                      color={'#A059FF'}
                      title={
                        'Keyword usage is calculated by counting unique keywords for each location in a project and then summing them across all projects.'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
          'change-plan': (
            <div className={'flex flex-col'}>
              <h3
                className={
                  'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
                }
              >
                Plans & Pricing
              </h3>
            </div>
          ),
          'request-sent': (
            <div className={'flex flex-col'}>
              <h3
                className={
                  'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
                }
              >
                Request sent
              </h3>
              <p className={'pt-[18px] pb-[16px] px-[24px] text-focus-500 text-[15px]'}>
                Your account manager will contact you shortly to upgrade your plan.
              </p>
            </div>
          ),
        }[state]
      }
      <div className={'flex pb-[21px] pt-[24px] px-[24px] border-t-[3px] border-focus-600/[.03]'}>
        {
          {
            'show-info': (
              <div>
                <p className={'text-[15px] text-aqua-300 mb-[4px]'}>Get more keywords</p>
                <BpButton
                  onClick={() => setState('change-plan')}
                  type='button'
                  className={'uppercase'}
                >
                  Change Plan
                </BpButton>
              </div>
            ),
            'change-plan': (
              <BpButton
                onClick={() => setState('request-sent')}
                type='button'
                className={'uppercase'}
              >
                Select plan
              </BpButton>
            ),
            'request-sent': (
              <BpButton type='button' className={'uppercase'} onClick={() => dispatch(hideModal())}>
                I got it
              </BpButton>
            ),
          }[state]
        }
      </div>
    </div>
  )
}
