import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconPlus } from '../../../../shared/components/icons/IconPlus'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import { ProjectModel } from '../../../../api/project/models/Project.model'
import React, { useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../shared/hooks/UseSorter'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { selectTeamPagination, setTeamPagination } from '../store/TeamSlice'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import {
  useChangeStatusMutation,
  useGetListQuery,
  useSendInviteMutation,
} from '../../../../api/team/Team.api'
import { cropRow } from '../../../../shared/helpers/crop-row'
import { Tooltip } from 'antd'
import { DropOptions } from '../../../../shared/components/forms/drop-options/DropOptions'
import { setModal, showAlert } from '../../../../store/reducers/AppSlice'
import { InviteModal } from './InviteModal'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { IconSettings } from '../../../../shared/components/icons/IconSettings'
import { EditModal } from './EditModal'
import { setTariffUpdated } from '../../../../store/reducers/AuthSlice'

export const TeamTable = () => {
  const { current, pageSize, total } = useAppSelector<IPagination>(selectTeamPagination)
  const [queryParams, setQueryParams] = useState<any>({
    page: current,
    'per-page': pageSize,
  })
  const dispatch = useAppDispatch()

  const { data, isLoading, isFetching } = useGetListQuery(queryParams)
  const [updateStatus] = useChangeStatusMutation()
  const [sendInvite] = useSendInviteMutation()

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: string, { id, email, status }) => {
        const options = [
          {
            text: 'Active',
            value: 'active',
            hidden: ['active'].includes(status),
          },
          {
            text: 'Archived',
            value: 'archived',
            hidden: ['archived'].includes(status),
          },
        ]

        return (
          <div className={'flex gap-[8px] items-center'}>
            <DropOptions
              options={options}
              disabled={status === 'pending'}
              onChange={(value) => {
                updateStatus({ id, status: value })
              }}
            >
              <span
                className={`text-[10px] font-semibold uppercase py-[2px] px-[8px] rounded-[6px] cursor-pointer
              ${
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {
                  active: 'text-success-400 bg-success-300',
                  pending: 'text-[#DA5533] bg-danger/[.07] cursor-default',
                  archived: 'text-aqua-300 bg-aqua-300/[.07]',
                }[status]
              }`}
              >
                {status}
              </span>
            </DropOptions>
            {status === 'pending' && (
              <button
                className={`text-focus
                text-[13px]
                leading-[13px]
                border-dotted
                border-b
                cursor-pointer
              `}
                type='button'
                onClick={() =>
                  sendInvite({ id }).then((response: any) => {
                    if (response.data?.success) {
                      dispatch(
                        showAlert({
                          message: `Invitation resent to ${email}`,
                          type: 'success',
                          timeout: 2000,
                        }),
                      )
                    }
                  })
                }
              >
                Resend ivitation
              </button>
            )}
          </div>
        )
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (_: string, { role }) => <span className={'capitalize'}>{role}</span>,
    },
    {
      title: 'Projects',
      key: 'projects',
      render: (_: string, { projects }) => {
        const { visible, hidden } = cropRow(projects, 25)

        return (
          <div className={'flex gap-[8px] items-center w-fit'}>
            {visible}{' '}
            {!!hidden.length && (
              <Tooltip title={hidden.join(', ')}>
                <span
                  className={'text-focus text-[13px] leading-[13px] ml-auto border-dotted border-b'}
                >
                  +{hidden.length}
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: 'Last visit',
      dataIndex: 'last_visit',
      key: 'last_visit',
    },
    {
      key: 'action',
      render: (_: string, { id }) => {
        return (
          <IconButton
            size='lg'
            onClick={() => dispatch(setModal({ children: <EditModal id={id} /> }))}
          >
            <IconSettings color={'#00326D'} />
          </IconButton>
        )
      },
    },
  ]

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<any> | SorterResult<any>[],
  ) => {
    dispatch(
      setTeamPagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <section>
      <div className='pb-[20px]'>
        <BpButton
          contentClassName={'font-normal'}
          iconLeft={<IconPlus />}
          size={'sm'}
          onClick={() => dispatch(setModal({ children: <InviteModal /> }))}
        >
          Invite team member
        </BpButton>
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<ProjectModel>}
        pagination={{
          current,
          pageSize,
          total,
        }}
      />
    </section>
  )
}
