import React, { useEffect } from 'react'
import { Header } from './header/Header'
import { Footer } from './footer/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import { BpSelect } from '../../shared/components/forms/select/BpSelect'
import { useAppDispatch, useAppSelector } from '../../store/hooks/Redux'
import { selectTariffDetails, selectUser, setTariffDetails } from '../../store/reducers/AuthSlice'
import { useGetCurrentTariffDetailsQuery } from '../../api/user/User.api'
import { BpButton } from '../../shared/components/buttons/bp-button/BpButton'
import { setModal, showAlert } from '../../store/reducers/AppSlice'
import { ChangePlan } from '../../pages/main/projects/components/ChangePlan'
import { useGetVirtualUsersQuery, useLazyGetVirtualLoginQuery } from '../../api/admin/Admin.api'
import { useLazySendVerificationQuery } from '../../api/site/Site.api'

export const MainLayout = () => {
  const user = useAppSelector(selectUser)
  const tariffDetails = useAppSelector(selectTariffDetails)
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const { data: currentTariff, refetch: refetchTariff } = useGetCurrentTariffDetailsQuery({})
  const { data: virtualUsers } = useGetVirtualUsersQuery(
    {},
    { skip: user.role !== 'admin' && !user.is_virtual_mode },
  )
  const [virtualLogin] = useLazyGetVirtualLoginQuery()
  const [sendVerification] = useLazySendVerificationQuery()

  useEffect(() => {
    if (currentTariff?.data) {
      dispatch(setTariffDetails(currentTariff?.data))
    }
  }, [currentTariff])

  useEffect(() => {
    if (tariffDetails?.tariffUpdated) {
      setTimeout(() => refetchTariff(), 2500)
    }
  }, [tariffDetails.tariffUpdated])

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT === 'bluepear') {
      const gtmScript = document.createElement('script')
      gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NKXFCZD');
    `
      document.head.appendChild(gtmScript)

      return () => {
        document.head.removeChild(gtmScript)
      }
    }
  }, [])

  return (
    <main className='relative bg-grey'>
      <div className={'relative w-full z-20 bg-default'}>
        {(user.role === 'admin' || user.is_virtual_mode) && (
          <div className={'container mx-auto px-[16px] xl:max-w-[1440px]'}>
            <div className={'flex items-center gap-[8px] py-[6px]'}>
              <p className={'text-white'}>Impersonated as</p>
              <BpSelect
                className={'w-[200px]'}
                placeholder={'Select user'}
                options={virtualUsers}
                fieldSize={'sm'}
                showSearch
                defaultValue={String(user.id)}
                loading={!virtualUsers}
                onChange={(e) => {
                  const userId = Number(e.target.value)

                  userId !== user.id &&
                    virtualLogin({ user_id: userId }).then(() => {
                      window.location.href = pathname
                    })
                }}
              />
            </div>
          </div>
        )}
        {tariffDetails?.tariff?.is_subscription_expired && (
          <div className={'container mx-auto px-[16px] xl:max-w-[1440px]'}>
            <div className={'flex items-center gap-[8px] py-[6px]'}>
              <p className={'text-white'}>Your {tariffDetails?.tariff?.name} has expired</p>
              <BpButton
                onClick={() =>
                  dispatch(setModal({ children: <ChangePlan step={'change-plan'} /> }))
                }
                contentClassName={'!text-focus-500'}
                size={'sm'}
                theme={'light'}
              >
                Upgrade now
              </BpButton>
            </div>
          </div>
        )}
        {!user.is_email_verified && (
          <div className={'container mx-auto px-[16px] xl:max-w-[1440px]'}>
            <div className={'flex items-center gap-[8px] py-[6px]'}>
              <p className={'text-white'}>
                Confirm your email to stay informed about billing, payments, and important system
                updates.
              </p>
              <BpButton
                onClick={() => {
                  sendVerification().then((response) => {
                    if (response.data.success) {
                      dispatch(
                        showAlert({
                          message: response.data.data?.message,
                          type: 'success',
                          timeout: 2000,
                        }),
                      )
                    }
                  })
                }}
                contentClassName={'!text-focus-500'}
                size={'sm'}
                theme={'light'}
              >
                Resend Confirmation Email
              </BpButton>
            </div>
          </div>
        )}
      </div>
      <div className='bg-focus-500 absolute left-0 top-0 h-[540px] md:h-[364px] lg:h-[260px] xl:h-[208px] w-full animate-appear-hand'></div>
      <div className='relative flex flex-col p-[16px] pb-0 xl:pt-0 justify-between min-h-screen relative overflow-hidden container xl:max-w-[1440px] mx-auto z-10'>
        <header className='relative z-20'>
          <Header />
        </header>
        <section className='flex flex-1 mt-[22px] xl:mt-0'>
          <Outlet />
        </section>
        <Footer />
      </div>
      <div className='bg-focus-500 absolute left-0 bottom-0 h-[360px] md:h-[256px] lg:h-[201px] w-full'></div>
    </main>
  )
}
