import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import { IconCheckBold } from '../../icons/IconCheckBold'

export interface IBpCheckbox extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element
}

export const BpCheckbox = forwardRef((props: IBpCheckbox, ref: ForwardedRef<HTMLInputElement>) => {
  const { label = '', hidden, type = 'checkbox', ...inputProps } = props

  return (
    <label
      className={`table group ${!inputProps.disabled && 'cursor-pointer'} ${
        hidden ? 'invisible' : 'visible'
      }`}
    >
      <div className='flex gap-[8px] items-center'>
        <input {...inputProps} ref={ref} type={type} className='peer' hidden></input>
        <div
          className={`
              flex
              items-center
              justify-center
              w-[16px]
              h-[16px]
              bg-white
              shadow-outline
              shadow-focus
              group-hover:shadow-default
              peer-checked:bg-default
              peer-checked:shadow-none
              peer-disabled:bg-gray-700
              peer-disabled:shadow-focus-500/[0.35]
              peer-disabled:peer-checked:bg-aqua-300/[0.40]
              ${type == 'checkbox' ? 'rounded-[4px]' : 'rounded-full'}
            `}
        >
          {type == 'checkbox' ? (
            <span className='w-[9px]'>
              <IconCheckBold></IconCheckBold>
            </span>
          ) : (
            <span className='rounded-full w-[6px] h-[6px] bg-white'></span>
          )}
        </div>
        {label && (
          <span className='text-focus-500 peer-disabled:text-aqua-300 text-[14px] select-none leading-[16px]'>
            {label}
          </span>
        )}
      </div>
    </label>
  )
})
