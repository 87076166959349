import { useParams } from 'react-router-dom'
import {
  useDownloadReportMutation,
  useGetMainSettingsQuery,
  useLazySendAlertEmailQuery,
  useLazySendAlertTelegramQuery,
  useLazySendWeeklyReportEmailQuery,
  useLazySendWeeklyReportTelegramQuery,
  useUpdateBrandMutation,
  useUpdateDevicesMutation,
  useUpdateNotificationsMutation,
} from '../../../../api/project/Project.api'
import { useGetDeviceTypesQuery } from '../../../../api/filter/Filter.api'
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import { Square } from '../../../../shared/components/utility/square/Square'
import { BpCard } from '../../../../shared/components/cards/card/BpCard'
import { FormControls } from '../../../../shared/components/forms/form-controls/FormControls'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { BpTextArea } from '../../../../shared/components/forms/textarea/BpTextArea'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { useTranslation } from 'react-i18next'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { IUpdateNotificationsForm } from '../interfaces/UpdateNotificationsForm.interface'
import { IUpdateDevicesForm } from '../interfaces/UpdateDevicesForm.interface'
import { IUpdateBrandForm } from '../interfaces/UpdateBrandForm.interface'

export const ProjectMainSettings = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const { data: mainSettings, isFetching } = useGetMainSettingsQuery(
    { id: id || '' },
    {
      selectFromResult: (result) => {
        const item = result.data?.data.items[0]

        return {
          ...result,
          data: {
            ...item,
            brand_variants: item?.brand_variants?.join('\n'),
            domains: item?.domains?.join('\n'),
          },
        }
      },
      skip: !id,
    },
  )

  const [updateBrand] = useUpdateBrandMutation()
  const [updateDevices] = useUpdateDevicesMutation()
  const [updateNotification] = useUpdateNotificationsMutation()
  const [downloadReport, { isLoading: downloadReportLoading }] = useDownloadReportMutation()
  const [sendWeeklyReportTelegram] = useLazySendWeeklyReportTelegramQuery()
  const [sendWeeklyReportEmail] = useLazySendWeeklyReportEmailQuery()
  const [sendAlertTelegram] = useLazySendAlertTelegramQuery()
  const [sendAlertEmail] = useLazySendAlertEmailQuery()

  const { data: devices, isFetching: devicesIsFetching } = useGetDeviceTypesQuery()

  const {
    control: controlBrand,
    handleSubmit: handleSubmitBrand,
    register: registerBrand,
    formState: { isDirty: isDirtyBrand, isValid: isValidBrand, errors: errorsBrand },
    setValue: setValueBrand,
    setError: setErrorBrand,
    watch: watchBrand,
    reset: resetBrand,
  } = useForm<IUpdateBrandForm>({
    mode: 'onChange',
    defaultValues: { brand_name: '', brand_variants: '', domains: '' },
  })

  const {
    control: controlDevices,
    handleSubmit: handleSubmitDevices,
    formState: { isDirty: isDirtyDevices, isValid: isValidDevices },
    watch: watchDevices,
    reset: resetDevices,
  } = useForm<IUpdateDevicesForm>({
    mode: 'onChange',
    defaultValues: { devices: [] },
  })

  const {
    control: controlNotifications,
    register: registerNotifications,
    handleSubmit: handleSubmitNotifications,
    setValue: setValueNotifications,
    formState: {
      isDirty: isDirtyNotifications,
      isValid: isValidNotifications,
      errors: errorsNotifications,
    },
    reset: resetNotifications,
    watch: watchNotifications,
  } = useForm<IUpdateNotificationsForm>({
    mode: 'onChange',
    defaultValues: {
      notify_new_publishers: false,
      notify_report: false,
      telegram_chat_id: '',
      report_emails: '',
    },
  })

  const formBrand = watchBrand()
  const formDevices = watchDevices()
  const formNotifications = watchNotifications()

  useEffect(() => {
    resetBrand({
      brand_name: mainSettings.brand_name,
      brand_variants: mainSettings.brand_variants,
      domains: mainSettings.domains,
    })
    resetNotifications({
      notify_new_publishers: mainSettings.notify_new_publishers,
      notify_report: mainSettings.notify_report,
      telegram_chat_id: mainSettings.telegram_chat_id,
      report_emails: mainSettings.report_emails,
    })
  }, [isFetching])

  useEffect(() => {
    resetDevices({
      devices: devices?.map(
        (device) =>
          mainSettings.devices?.some((d: { id: number }) => d.id === Number(device.value)) || false,
      ),
    })
  }, [isFetching, devicesIsFetching])

  const domainsValidate = (list: string): string[] => {
    const domainRegex = /^(?!-)[A-Za-zА-Яа-яЁё0-9-]{1,63}(?<!-)\.[A-Za-zА-Яа-яЁё]{2,}$/

    return list
      .split('\n')
      .map((line: string) => line.trim())
      .filter((line: string) => line && !domainRegex.test(line))
  }

  const submitBrand = (values: IUpdateBrandForm) => {
    updateBrand({ ...values, id })
  }

  const submitDevices = (values: IUpdateDevicesForm) => {
    updateDevices({
      devices: devices
        ?.filter((_, index) => values?.devices[index])
        .map((device) => ({ id: Number(device.value) })),
      id,
    })
  }

  const submitNotification = (values: IUpdateNotificationsForm) => {
    updateNotification({ id: Number(id), ...values })
  }

  if (isFetching) {
    return <Preloader />
  }

  return (
    <section className='flex flex-col gap-[24px] xl:gap-[32px] lg:flex-wrap lg:flex-row lg:items-start'>
      <Square className={'flex-1 lg:flex-initial lg:w-[calc(50%-12px)] xl:w-auto xl:flex-1'}>
        <form onSubmit={handleSubmitBrand(submitBrand)}>
          <BpCard
            title={t('pages.project.brand')}
            description={t('pages.project.brandDescription')}
            footer={
              <FormControls
                disabled={
                  !isDirtyBrand ||
                  !isValidBrand ||
                  !formBrand.brand_name.length ||
                  !!domainsValidate(formBrand?.domains).length
                }
                canceled={isDirtyBrand}
                onCancel={() => resetBrand()}
              />
            }
            size={'sm'}
          >
            <div className='flex flex-col gap-[16px]'>
              <Controller
                name='brand_name'
                control={controlBrand}
                render={({ field }) => (
                  <BpInput
                    {...field}
                    label={t('fields.brandName')}
                    placeholder={t('pages.project.placeholders.brandName')}
                    onClear={() => setValueBrand('brand_name', '')}
                    required
                    error={errorsBrand.brand_name?.message as string}
                    {...registerBrand('brand_name', {
                      required: t('fields.validate.required'),
                      maxLength: {
                        value: 100,
                        message: 'Please keep name under 100 characters',
                      },
                    })}
                  />
                )}
              />
              <Controller
                name='brand_variants'
                control={controlBrand}
                render={({ field }) => (
                  <BpTextArea
                    {...field}
                    label={t('fields.brandsMisspellingsVariations')}
                    placeholder={t('pages.project.placeholders.brandsMisspellingsVariations')}
                    required
                    error={errorsBrand.brand_variants?.message as string}
                    {...registerBrand('brand_variants', {
                      required: t('fields.validate.required'),
                      pattern: /.*\S+.*/,
                      onChange: (e) => {
                        setValueBrand('brand_variants', e.target.value.replace(',', '\n'))
                      },
                      onBlur: (e) => {
                        setValueBrand(
                          'brand_variants',
                          e.target.value
                            .split('\n')
                            .map((value: string) => value.trim())
                            .join('\n'),
                        )
                      },
                    })}
                  />
                )}
              />
              <Controller
                name='domains'
                control={controlBrand}
                render={({ field }) => (
                  <BpTextArea
                    {...field}
                    label={t('fields.domains')}
                    placeholder={t('pages.project.placeholders.domains')}
                    info={t('pages.projects.info.domains')}
                    required
                    error={errorsBrand.domains?.message as string}
                    {...registerBrand('domains', {
                      required: t('fields.validate.required'),
                      pattern: /.*\S+.*/,
                      onChange: (e) => {
                        setValueBrand(
                          'domains',
                          e.target.value
                            .replace(',', '\n')
                            .replace(/[^a-zA-Zа-яА-Я0-9\s.,-:/]/g, ''),
                        )
                      },
                      onBlur: (e) => {
                        if (domainsValidate(e.target.value).length > 0) {
                          setErrorBrand('domains', { message: t('fields.validate.domain') })
                        }
                      },
                    })}
                  />
                )}
              />
            </div>
          </BpCard>
        </form>
      </Square>
      <Square className={'flex-1 lg:flex-initial lg:w-[calc(50%-12px)] xl:w-auto xl:flex-1'}>
        <form onSubmit={handleSubmitDevices(submitDevices)}>
          <BpCard
            title={t('pages.project.devices')}
            description={t('pages.project.devicesDescription')}
            footer={
              <FormControls
                disabled={
                  !isDirtyDevices || !isValidDevices || !formDevices?.devices.includes(true)
                }
                canceled={isDirtyDevices}
                onCancel={() => resetDevices()}
              />
            }
            size={'sm'}
          >
            <div>
              <div className={'text-[14px] font-medium mb-[14px] leading-[24px] text-focus-500'}>
                {t('fields.deviceTypes')}
                <span className={'text-default'}> *</span>
              </div>
              <div className={'flex flex-col gap-[16px]'}>
                {devices?.map((device, index) => {
                  const fieldName = `devices.${index}` as const

                  return (
                    <Controller
                      key={index}
                      name={fieldName}
                      control={controlDevices}
                      render={({ field: { value, ...fieldProps } }) => (
                        <BpCheckbox
                          {...fieldProps}
                          checked={value}
                          defaultChecked={false}
                          label={device.text}
                        />
                      )}
                    />
                  )
                }) || (
                  <div className={'relative mx-auto w-[48px] h-[48px]'}>
                    <Preloader />
                  </div>
                )}
              </div>
            </div>
          </BpCard>
        </form>
      </Square>
      <Square className={'flex-1 lg:flex-initial lg:w-[calc(50%-12px)] xl:w-auto xl:flex-1'}>
        <form onSubmit={handleSubmitNotifications(submitNotification)}>
          <BpCard
            title={t('pages.project.notifications')}
            description={t('pages.project.notificationsDescription')}
            footer={
              <FormControls
                disabled={!isDirtyNotifications || !isValidNotifications}
                canceled={isDirtyNotifications}
                onCancel={() => resetNotifications()}
              />
            }
            size={'sm'}
          >
            <div className='flex flex-col gap-[24px]'>
              <div className={'flex flex-col gap-[12px] px-[2px]'}>
                <Controller
                  name={'notify_new_publishers'}
                  control={controlNotifications}
                  render={({ field: { value, ...fieldProps } }) => (
                    <BpCheckbox
                      {...fieldProps}
                      checked={value}
                      label={
                        <span className={'flex gap-[8px]'}>
                          {t('pages.project.notifyAboutNewAffiliates')}{' '}
                          <BpTooltip title={t('pages.project.notifyAboutNewAffiliatesTooltip')} />
                        </span>
                      }
                    />
                  )}
                />
                <Controller
                  name={'notify_report'}
                  control={controlNotifications}
                  render={({ field: { value, ...fieldProps } }) => (
                    <BpCheckbox
                      {...fieldProps}
                      checked={value}
                      label={
                        <span className={'flex gap-[8px]'}>
                          {t('pages.project.sendFullWeeklyReport')}{' '}
                          <BpTooltip title={t('pages.project.sendFullWeeklyReportTooltip')} />
                        </span>
                      }
                    />
                  )}
                />
                <BpButton
                  className={'w-fit'}
                  type={'button'}
                  theme={'outline'}
                  size={'sm'}
                  loading={downloadReportLoading}
                  onClick={() => downloadReport({ project_id: id })}
                >
                  {t('pages.project.download7DayReport')}
                </BpButton>
              </div>
              <div className={'flex flex-col gap-[6px]'}>
                <Controller
                  name='telegram_chat_id'
                  control={controlNotifications}
                  render={({ field }) => (
                    <BpInput
                      {...field}
                      label={t('fields.telegramChatId')}
                      placeholder={t('pages.project.placeholders.insertChatId')}
                      onClear={() => setValueNotifications('telegram_chat_id', '')}
                      error={errorsNotifications?.telegram_chat_id?.message as string}
                      {...registerNotifications('telegram_chat_id', {
                        pattern: {
                          value: /^[\d-]+$/,
                          message: t('fields.validate.tgChatId'),
                        },
                      })}
                    />
                  )}
                />
                <div className={'flex gap-[9px] px-[2px]'}>
                  <BpButton
                    className={'w-fit'}
                    type={'button'}
                    theme={'outline'}
                    size={'sm'}
                    onClick={() => sendWeeklyReportTelegram({ project_id: id })}
                    disabled={
                      !!errorsNotifications?.telegram_chat_id?.message ||
                      !formNotifications?.telegram_chat_id
                    }
                  >
                    {t('pages.project.send7DayReport')}
                  </BpButton>
                  <BpButton
                    className={'w-fit'}
                    type={'button'}
                    theme={'outline'}
                    size={'sm'}
                    onClick={() => sendAlertTelegram({ project_id: id })}
                    disabled={
                      !!errorsNotifications?.telegram_chat_id?.message ||
                      !formNotifications?.telegram_chat_id
                    }
                  >
                    {t('pages.project.notifyAboutNewAffiliate')}
                  </BpButton>
                </div>
              </div>
              <div className={'flex flex-col gap-[6px]'}>
                <Controller
                  name='report_emails'
                  control={controlNotifications}
                  render={({ field }) => (
                    <BpInput
                      {...field}
                      label={t('fields.recipientsEmailAddresses')}
                      placeholder={t('pages.project.placeholders.enterEmail')}
                      onClear={() => setValueNotifications('report_emails', '')}
                      error={errorsNotifications?.report_emails?.message as string}
                      {...registerNotifications('report_emails', {
                        pattern: {
                          value: EMAIL_REGEX,
                          message: t('fields.validate.email'),
                        },
                      })}
                    />
                  )}
                />
                <div className={'flex gap-[9px] px-[2px]'}>
                  <BpButton
                    className={'w-fit'}
                    type={'button'}
                    theme={'outline'}
                    size={'sm'}
                    onClick={() => sendWeeklyReportEmail({ project_id: id })}
                    disabled={
                      !!errorsNotifications?.report_emails?.message ||
                      !formNotifications?.report_emails
                    }
                  >
                    {t('pages.project.send7DayReport')}
                  </BpButton>
                  <BpButton
                    className={'w-fit'}
                    type={'button'}
                    theme={'outline'}
                    size={'sm'}
                    onClick={() => sendAlertEmail({ project_id: id })}
                    disabled={
                      !!errorsNotifications?.report_emails?.message ||
                      !formNotifications?.report_emails
                    }
                  >
                    {t('pages.project.notifyAboutNewAffiliate')}
                  </BpButton>
                </div>
              </div>
            </div>
          </BpCard>
        </form>
      </Square>
    </section>
  )
}
