import React, { HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import { IconFilter } from '../../icons/IconFilter'
import { BpButton } from '../../buttons/bp-button/BpButton'
import { Square } from '../../utility/square/Square'
import { IWithDropdownProps, withDropdown } from '../../../hoc/WithDropdown'
import { IOption } from '../search-input/BpSearchInput'

export interface IFilterDropParams extends IWithDropdownProps {
  children?: ReactNode | undefined
  disabled?: boolean
  options?: IOption[]
  onChange?: (value: string) => void
  offset?: number
}

const Drop = ({
  children,
  disabled = false,
  options,
  open,
  onToggle,
  onChange,
  offset = 25,
}: IFilterDropParams & HTMLAttributes<HTMLElement>) => {
  const [isHidden, setIsHidden] = useState(!open)

  useEffect(() => {
    open ? setIsHidden(false) : setTimeout(() => setIsHidden(true), 150)
  })

  return (
    <div className='relative table select-none w-full'>
      <div onClick={onToggle}>{children}</div>
      <div
        className={`transition-opacity
        duration-150
        absolute
        left-0
        w-[160px]
        max-w-[85vw]
        ${open && !disabled ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
        ${isHidden && 'invisible'} z-50`}
        style={{ top: `${offset}px` }}
      >
        <Square depth='200' className='flex flex-col py-[8px] px-[12px]'>
          {options
            ?.filter((option) => !option.hidden)
            .map((option, index) => (
              <button
                className={'text-left p-[12px] hover:bg-default-200 rounded-[8px]'}
                key={index}
                onClick={() => {
                  onChange && onChange(option.value)
                  onToggle && onToggle()
                }}
              >
                {option.text}
              </button>
            ))}
        </Square>
      </div>
    </div>
  )
}

export const DropOptions = withDropdown(Drop)
