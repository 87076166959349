export function cropRow(array: string[], maxLength = 25) {
  let totalLength = 0
  const visibleArray: string[] = []
  let hiddenArray: string[] = []

  for (let i = 0; i < array.length; i++) {
    const country = array[i]
    totalLength += country.length + (i > 0 ? 2 : 0)

    if (totalLength <= maxLength) {
      visibleArray.push(country)
    } else {
      hiddenArray = array.slice(i)
      break
    }
  }

  return { visible: visibleArray.join(', '), hidden: hiddenArray }
}
