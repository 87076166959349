import { Table } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { BpPagination, IBpPaginationProps } from '../pagination/BpPagination'
import { TableProps } from 'antd/lib/table/Table'
import { TTableResponse } from '../../../types/TableResponse.type'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { usePreviousValue } from '../../../hooks/UsePreviousValue'
import { useTranslation } from 'react-i18next'

export interface IBpTableProps<RecordType> extends TableProps<RecordType> {
  data?: TTableResponse<RecordType>
  loading?: boolean
  onChange?: (
    pagination: IBpPaginationProps,
    filters?: Record<string, FilterValue | null>,
    sorter?: SorterResult<RecordType> | SorterResult<RecordType>[],
    extra?: TableCurrentDataSource<RecordType>,
  ) => void
  hidePagination?: boolean
  sizePagination?: 'sm' | 'md'
  bottomSection?: ReactNode
}

export const BpTable = <T extends object>(props: IBpTableProps<T>) => {
  const { t } = useTranslation()
  const { data, onChange, pagination, scroll, bottomSection, sizePagination, ...tableProps } = props

  const [bpPagination, setBpPagination] = useState<IBpPaginationProps>({
    current: pagination ? pagination.current : 1,
    pageSize: pagination ? pagination.pageSize : 10,
    total: pagination ? pagination.total : 1,
    totalRows: 1,
  })
  const [bpSorter, setBpSorter] = useState<SorterResult<T> | SorterResult<T>[]>()
  const [bpFilters, setBpFilters] = useState<Record<string, FilterValue | null>>()
  const previousBpPagination = usePreviousValue(bpPagination)

  useEffect(() => {
    if (!data?.data) return
    const { pageCount, total } = data.data
    setBpPagination({ ...bpPagination, totalRows: total, total: pageCount })
  }, [data])

  useEffect(() => {
    if (onChange && bpPagination.current && bpPagination.current > 0)
      onChange(bpPagination, bpFilters, bpSorter)
  }, [bpPagination])

  useEffect(() => {
    const newPagination = {
      ...bpPagination,
      ...pagination,
    }

    if (
      previousBpPagination &&
      (previousBpPagination as IBpPaginationProps).current !== newPagination.current
    ) {
      setBpPagination(newPagination)
    }
  }, [pagination])

  return (
    <div>
      <Table
        {...tableProps}
        locale={{
          triggerAsc: t('table.clickToSortAscending'),
          triggerDesc: t('table.clickToSortDescending'),
          cancelSort: t('table.clickToCancelSorting'),
        }}
        onChange={(pagination, filters, sorter, extra) => {
          setBpSorter(sorter)
          setBpFilters(filters)
          if (onChange) onChange(bpPagination, filters, sorter, extra)
        }}
        pagination={false}
        scroll={scroll ?? { x: '1327px' }}
      />
      {!props.hidePagination && (
        <div className='relative z-40 bg-white mt-[-70px]'>
          {bottomSection && (
            <div className='border-t-[2px] border-focus/[0.03]'>{bottomSection}</div>
          )}
          <div className='pt-[24px] border-t-[2px] border-focus/[0.03]'>
            <BpPagination
              {...bpPagination}
              sizePagination={sizePagination}
              onChange={(page, pageSize) => {
                setBpPagination({
                  ...bpPagination,
                  current: page,
                  pageSize,
                })
              }}
              disabled={tableProps.loading}
            />
          </div>
        </div>
      )}
    </div>
  )
}
