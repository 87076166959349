import { CommonMainPage } from '../../utility/CommonMainPage'
import { TeamTable } from './TeamTable'

export const TeamPage = () => {
  return (
    <CommonMainPage>
      <TeamTable />
    </CommonMainPage>
  )
}
