import { OverviewFilters } from './filters/OverviewFilters'
import { Square } from '../../../../shared/components/utility/square/Square'
import { StatsPreviewList } from './stats-preivew-list/StatsPreviewList'
import { VisibilityReport } from './visibility/VisibilityReport'
import { VisibilityByCountryReport } from './visibility-by-country/VisibilityByCountryReport'
import { TopKeywordsGraph } from './top-keywords/TopKeywords'
import { TopCompetitorsGraph } from './top-competitors/TopCompetitors'
import { IconLink } from '../../../../shared/components/icons/IconLink'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks/Redux'
import { IOverviewFilter } from '../interfaces/OverviewFilter.interface'
import { selectOverviewFilters } from '../store/OverviewSlice'
import { IResearchFilter } from '../../research-tools/interfaces/ResearchFilter.interface'
import {
  selectResearchFilters,
  setResearchActiveTab,
  setResearchFilter,
  TResearchTabs,
} from '../../research-tools/store/ResearchSlice'
import { useTranslation } from 'react-i18next'

export const OverviewPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { country, project } = useAppSelector<IOverviewFilter>(selectOverviewFilters)
  const researchFilters = useAppSelector<IResearchFilter>(selectResearchFilters)

  const handleLinkTo = (tab: TResearchTabs) => {
    dispatch(
      setResearchFilter({
        ...researchFilters,
        project,
        country: country ?? [],
      }),
    )
    dispatch(setResearchActiveTab(tab))
    navigate('/advertising-research')
  }

  return (
    <div className='flex flex-col gap-[16px] w-full'>
      <Square className='flex flex-col p-[16px] xl:p-[32px] gap-[24px] w-full'>
        <OverviewFilters />
        <StatsPreviewList />
      </Square>
      <section className='flex flex-col xl:flex-row gap-[16px]'>
        <Square className='flex p-[16px] xl:p-[32px] xl:w-[calc(65%-8px)]'>
          <VisibilityReport />
        </Square>
        <Square className='flex p-[16px] xl:p-[32px] xl:w-[calc(35%-8px)]'>
          <VisibilityByCountryReport />
        </Square>
      </section>
      <Square className='flex flex-col items-center p-[16px] xl:p-[32px] gap-[24px]'>
        <TopKeywordsGraph />
        <BpButton
          onClick={() => handleLinkTo('Keywords')}
          size='lg'
          className='inline-flex'
          iconLeft={<IconLink />}
        >
          {t('buttons.viewFullReport')}
        </BpButton>
      </Square>
      <Square className='flex flex-col items-center p-[16px] xl:p-[32px] gap-[24px]'>
        <TopCompetitorsGraph />
        <BpButton onClick={() => handleLinkTo('Competitors')} size='lg' iconLeft={<IconLink />}>
          {t('buttons.viewFullReport')}
        </BpButton>
      </Square>
    </div>
  )
}
