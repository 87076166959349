import ReactDOM from 'react-dom/client'
import 'antd/dist/antd.min.css'
import './index.css'
import App from './App'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/Store'
import './i18n'
import 'swiper/css'
import { MainGuard } from './guards/MainGuard'
import { AuthGuard } from './guards/AuthGuard'
import { ProjectsPage } from './pages/main/projects/components/pages/ProjectsPage'
import { PageGuard } from './guards/PageGuard'
import { MainLayout } from './layouts/main-layout/MainLayout'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { OverviewPage } from './pages/main/overview/components/OverviewPage'
import { TeamPage } from './pages/main/team/components/TeamPage'
import { OnboardingPage } from './pages/main/onboarding/OnboardingPage'
import { NotFound } from './shared/components/cards/not-found/NotFound'
import { LiveSearchPage } from './pages/main/live-search/components/LiveSearchPage'
import { LanguageCompliancePage } from './pages/main/language-compliance/components/LanguageCompliancePage'
import { PpcCompliancePage } from './pages/main/ppc-compliance/components/PpcCompliancePage'
import { ProfileSettingsPage } from './pages/main/profile-settings/components/ProfileSettingsPage'
import { OrganicResultsPage } from './pages/main/organic-results/OrganicResultsPage'
import { DetailedInfoPage } from './pages/main/detailed-info/DetailedInfoPage'
import { PositionTrackerPage } from './pages/main/position-tracker/PositionTrackerPage'
import { SerpPresence } from './pages/main/serp-presence/SerpPresence'
import { BlackhatMonitoringPage } from './pages/main/blackhat-monitoring/components/BlackhatMonitoringPage'
import { LiveUncloakingPage } from './pages/main/live-uncloaking/components/LiveUncloakingPage'
import { AdvertiserReportPage } from './pages/main/advertiser-report/AdvertiserReportPage'
import { CommonPageLayout } from './layouts/common-page-layout/CommonPageLayout'
import { AdOverviewPage } from './pages/main/ad-overview/components/AdOverviewPage'
import { AffiliatesAdsReport } from './pages/main/ad-overview/components/AffiliatesAdsReport'
import { ResearchToolsPage } from './pages/main/research-tools/components/ResearchToolsPage'
import { ColumnReportTable } from './pages/main/research-tools/components/column-report/ColumnReportTable'
import { AdvertisersReportTable } from './pages/main/research-tools/components/advertisers-report/AdvertisersReportTable'
import { AuthLayout } from './layouts/auth-layout/AuthLayout'
import { LoginPage } from './pages/auth/login/LoginPage'
import { SignUpPage } from './pages/auth/signup/SignUpPage'
import { ResetPasswordPage } from './pages/auth/reset-password/ResetPasswordPage'
import { SetUpPasswordPage } from './pages/auth/set-up-password/SetUpPasswordPage'
import { ContactPage } from './pages/auth/contact/ContactPage'
import { EditProjectsPage } from './pages/main/projects/components/pages/EditProjectsPage'

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: 'auth',
        element: <AuthGuard />,
        children: [
          {
            element: <AuthLayout />,
            children: [
              { index: true, element: <LoginPage /> },
              {
                path: 'signup',
                element: <SignUpPage />,
              },
              {
                path: 'reset-password',
                element: <ResetPasswordPage />,
              },
              {
                path: 'set-up-password',
                element: <SetUpPasswordPage />,
              },
              {
                path: 'request-demo',
                element: <Navigate to={'/auth/signup'} />,
              },
              { path: '*', element: <NotFound /> },
            ],
          },
        ],
      },
      {
        path: '/',
        element: <MainGuard />,
        children: [
          {
            element: <MainLayout />,
            children: [
              { index: true, element: <Navigate to={'/projects'} /> },
              {
                path: 'projects',
                element: (
                  <PageGuard roles={['editor', 'admin']}>
                    <ProjectsPage />
                  </PageGuard>
                ),
              },
              {
                path: 'projects/:id/:tab?',
                element: (
                  <PageGuard roles={['editor', 'admin']}>
                    <EditProjectsPage />
                  </PageGuard>
                ),
              },
              {
                path: 'team',
                element: (
                  <PageGuard roles={['admin']}>
                    <TeamPage />
                  </PageGuard>
                ),
              },
              { path: 'ad-reports', element: <AdvertiserReportPage /> },
              {
                path: 'adv-overview',
                element: <CommonPageLayout />,
                children: [
                  { index: true, element: <AdOverviewPage /> },
                  { path: ':publisher_id/:affiliate', element: <AffiliatesAdsReport /> },
                ],
              },
              {
                path: 'advertising-research',
                element: <CommonPageLayout />,
                children: [
                  { index: true, element: <ResearchToolsPage /> },
                  { path: 'ads-domain/:id/:domain', element: <ColumnReportTable /> },
                  { path: 'ads-keyword/:id/:keyword', element: <ColumnReportTable /> },
                  { path: 'advertisers/:id/:keyword', element: <AdvertisersReportTable /> },
                ],
              },
              { path: 'profile-settings', element: <ProfileSettingsPage /> },
              { path: 'organic-results', element: <OrganicResultsPage /> },
              { path: 'detailed-info', element: <DetailedInfoPage /> },
              { path: 'position-tracker', element: <PositionTrackerPage /> },
              { path: 'serp-presence', element: <SerpPresence /> },
              { path: 'blackhat-monitoring', element: <BlackhatMonitoringPage /> },
              { path: 'live-uncloaking', element: <LiveUncloakingPage /> },
              { path: 'ppc-compliance', element: <PpcCompliancePage /> },
              { path: 'language-compliance-page', element: <LanguageCompliancePage /> },
              { path: 'live-search', element: <LiveSearchPage /> },
              { path: 'review', element: <OverviewPage /> },
              { path: '*', element: <NotFound /> },
            ],
          },
        ],
      },
      {
        path: 'onboarding',
        element: <MainGuard />,
        children: [{ index: true, element: <OnboardingPage /> }],
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId='1098248718474-pn7cnfqhnsij259125mph9po549noikf.apps.googleusercontent.com'>
      <RouterProvider router={router} />
    </GoogleOAuthProvider>
  </Provider>,
)
