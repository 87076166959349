import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconPage = ({ color = '#A059FF', className }: IIconProps) => {
  return (
    <svg
      className={`${className ? className : 'w-full'}`}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.99992 7.33301H5.99992M7.33325 9.99967H5.99992M11.3333 4.66634H5.99992M13.9999 4.53301V12.6663C13.9999 13.7709 13.1045 14.6663 11.9999 14.6663H6.53325C5.41315 14.6663 4.85309 14.6663 4.42527 14.4483C4.04895 14.2566 3.74299 13.9507 3.55124 13.5743C3.33325 13.1465 3.33325 12.5865 3.33325 11.4663V4.53301C3.33325 3.4129 3.33325 2.85285 3.55124 2.42503C3.74299 2.0487 4.04895 1.74274 4.42527 1.55099C4.85309 1.33301 5.41315 1.33301 6.53325 1.33301H10.7999C11.9201 1.33301 12.4801 1.33301 12.9079 1.55099C13.2843 1.74274 13.5902 2.0487 13.7819 2.42503C13.9999 2.85285 13.9999 3.4129 13.9999 4.53301Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
