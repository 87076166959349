import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { BaseQueryMeta, BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { IOption } from '../../shared/components/forms/search-input/BpSearchInput'

export const userApi = createApi({
  reducerPath: 'user/api',
  tagTypes: ['Project'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/user/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getCurrentTariffDetails: build.query<ICommonResponse<any>, any>({
      query: (params: any) => ({ url: 'current-tariff-details', params }),
      providesTags: ['Project'],
      transformResponse: (response: any) => {
        return {
          ...response,
          data: {
            ...response.data,
            project_details: Object.keys(response.data.project_details).map((row) => ({
              id: row,
              ...response.data.project_details[row],
            })),
          },
        }
      },
    }),
    sendRequestChangeTariff: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'request-change-tariff',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('tariff_id', JSON.stringify(params.plan))
          formData.append('payment_periodicity', params.period)

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    sendFeedback: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'send-feedback',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('message', params.message)

          return formData
        })(),
        cache: 'no-cache',
      }),
    }),
  }),
})

export const {
  useGetCurrentTariffDetailsQuery,
  useSendRequestChangeTariffMutation,
  useSendFeedbackMutation,
} = userApi
