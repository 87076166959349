import { IWithExpandedAllProps, withExpanded } from '../../../../../shared/hoc/WithExpanded'
import { ColumnsType } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { IDetailedInfoFilter } from '../../interfaces/DetailedInfoFilter.interface'
import { selectDetailedInfoFilters } from '../../store/DetailedInfoSlice'
import { useGetAdvertisingAffiliatesQuery } from '../../../../../api/research/Research.api'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { IAdvertisingAffiliatesModel } from '../../../../../api/research/models/AdvertisingAffiliates.model'
import { IAdvertisingAffiliatesParams } from '../../../../../api/research/interfaces/AdvertisingAffiliatesParams.interface'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { BpButton } from '../../../../../shared/components/buttons/bp-button/BpButton'
import { setModal } from '../../../../../store/reducers/AppSlice'
import { EditAffiliateModal } from '../../../ad-overview/components/affiliates-report/edit-affiliate-modal/EditAffiliateModal'
import { useEditAffiliateMutation } from '../../../../../api/ad-overview/AdOverview.api'
import { LinksList } from '../../../ad-overview/components/LinksList'
import { IconButton } from '../../../../../shared/components/buttons/icon-button/IconButton'
import { IconEye } from '../../../../../shared/components/icons/IconEye'
import { IconEyeOff } from '../../../../../shared/components/icons/IconEyeOff'
import { useTranslation } from 'react-i18next'

const Table = ({ onToggleExpand, isExpandAll, expandedRowKeys }: IWithExpandedAllProps) => {
  const { t } = useTranslation()
  const detailedInfoFilter = useAppSelector<IDetailedInfoFilter>(selectDetailedInfoFilters)
  const {
    project,
    site,
    country,
    engine_id,
    dateRange: date,
    keyword,
    has_brand,
    page_url,
    show_hidden,
  } = detailedInfoFilter

  const [queryParams, setQueryParams] = useState<IAdvertisingAffiliatesParams>()
  const { data, isLoading, isFetching, refetch } = useGetAdvertisingAffiliatesQuery(
    queryParams as IAdvertisingAffiliatesParams,
    { skip: !project || queryParams == undefined },
  )
  const [editAffiliate] = useEditAffiliateMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!project) return

    setQueryParams({
      page: 1,
      'per-page': 10,
      project_id: +project,
      site,
      country_id: country ? +country : undefined,
      engine_id: +engine_id,
      date,
      keyword,
      has_brand,
      landing: page_url,
      show_hidden,
    })
  }, [detailedInfoFilter])

  const columns: ColumnsType<IAdvertisingAffiliatesModel> = [
    {
      title: t('table.columns.affiliateId'),
      dataIndex: 'affiliate_id',
      key: 'affiliate_id',
      render: (_: string, { affiliate_id }: IAdvertisingAffiliatesModel) => {
        return (
          <div className='w-56 overflow-hidden text-ellipsis whitespace-nowrap'>{affiliate_id}</div>
        )
      },
    },
    {
      title: t('table.columns.redirectLinks'),
      dataIndex: 'redirects_count',
      key: 'redirects_count',
      render: (_: string, { affiliate_id, redirects_count }: IAdvertisingAffiliatesModel) => {
        return (
          <div className='flex items-center'>
            {redirects_count}

            {expandedRowKeys?.includes(affiliate_id) ? (
              <span
                className={`text-focus
                  text-[13px]
                  leading-[13px]
                  ml-4
                  border-dotted
                  border-b
                  cursor-pointer`}
                onClick={() => {
                  onToggleExpand && onToggleExpand(false, affiliate_id)
                }}
              >
                {t('buttons.close')}
              </span>
            ) : (
              <span
                className={`text-focus
                  text-[13px]
                  leading-[13px]
                  ml-4
                  border-dotted
                  border-b
                  cursor-pointer`}
                onClick={() => {
                  onToggleExpand && onToggleExpand(true, affiliate_id)
                }}
              >
                {t('buttons.show')}
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: t('table.columns.page'),
      dataIndex: 'page',
      key: 'page',
      render: (_: string, { page }: IAdvertisingAffiliatesModel) => {
        return (
          <div>
            <BpAnchor className='flex-1 leading-5' target='_blank' href={page}>
              {page.split('?')[0]}
            </BpAnchor>
          </div>
        )
      },
    },
    {
      title: t('table.columns.landingPage'),
      dataIndex: 'link_screen_url',
      key: 'link_screen_url',
      render: (_: string, { link_screen_url }: IAdvertisingAffiliatesModel) => (
        <BpAnchor href={link_screen_url} target='_blank'>
          {t('buttons.viewScreenshot')}
        </BpAnchor>
      ),
    },
    {
      title: t('table.columns.firstFound'),
      dataIndex: 'first_found',
      key: 'first_found',
      width: '150px',
      sorter: true,
    },
    {
      title: t('table.columns.lastFound'),
      dataIndex: 'last_found',
      key: 'last_found',
      width: '150px',
      sorter: true,
    },
    {
      key: 'edit',
      render: (_: IAdvertisingAffiliatesModel, { publisher_id }: IAdvertisingAffiliatesModel) => {
        return (
          <div className='flex w-full justify-end'>
            <BpButton
              theme='outline'
              size='sm'
              onClick={() =>
                dispatch(setModal({ children: <EditAffiliateModal publisherId={publisher_id} /> }))
              }
            >
              {t('buttons.editAffiliate')}
            </BpButton>
          </div>
        )
      },
      className: 'action',
      width: 120,
    },
    {
      key: 'hidden_action',
      render: (
        _: IAdvertisingAffiliatesModel,
        { publisher_id, is_hidden }: IAdvertisingAffiliatesModel,
      ) => {
        return (
          <IconButton
            size='lg'
            onClick={() => {
              if (!project) return
              editAffiliate({
                affiliate_id: publisher_id,
                is_hidden: !is_hidden,
              }).then(() => refetch())
            }}
          >
            {is_hidden ? <IconEye color='#A059FF' /> : <IconEyeOff color='#A059FF' />}
          </IconButton>
        )
      },
      className: 'action',
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?:
      | SorterResult<IAdvertisingAffiliatesModel>
      | SorterResult<IAdvertisingAffiliatesModel>[],
  ) => {
    if (queryParams) {
      setQueryParams({
        ...queryParams,
        page: current,
        'per-page': pageSize,
        sort: useSorter(sorter),
      })
    }
  }

  return (
    <BpTable
      dataSource={data?.data?.items}
      rowKey={(record) => record.affiliate_id}
      columns={columns}
      loading={isLoading || isFetching}
      onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
      data={data as TTableResponse<IAdvertisingAffiliatesModel>}
      rowClassName={(e) => (e.is_hidden ? 'disabled' : '')}
      expandable={{
        expandedRowRender: (record: IAdvertisingAffiliatesModel) => (
          <LinksList
            redirects={record.redirects}
            additionalRedirects={record.additionalRedirects}
            className='max-w-[980px] m-auto'
          />
        ),
        expandedRowKeys: isExpandAll
          ? data?.data?.items.map((item) => item.affiliate_id)
          : expandedRowKeys,
        showExpandColumn: false,
      }}
    ></BpTable>
  )
}

export const DetailedInfoAffiliatesTable = withExpanded(Table)
