import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store/Store'
import { IProjectFilters } from '../interfaces/ProjectFilters.interface'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { ILocation } from '../interfaces/Location.interface'
import { ITerm } from '../interfaces/Term.interface'

type ProjectsState = {
  filters: IProjectFilters
  locations?: ILocation[]
  terms?: ITerm[]
  locationsChanged?: boolean
  termsChanged?: boolean
  pagination: IPagination
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    filters: { country: undefined, device: undefined, searchEngine: undefined, keyword: undefined },
    locations: [],
    terms: [],
    locationsChanged: false,
    termsChanged: false,
    pagination: {
      current: 1,
      pageSize: 50,
      total: 1,
    },
  } as ProjectsState,
  reducers: {
    setProjectsFilter: (state: ProjectsState, { payload }: PayloadAction<IProjectFilters>) => {
      state.filters = { ...state.filters, ...payload }
    },
    setProjectsPagination: (state: ProjectsState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload
    },
    setProjectLocations: (state: ProjectsState, action: PayloadAction<ILocation[]>) => {
      state.locations = action.payload
      state.locationsChanged = false
    },
    updateProjectLocations: (state: ProjectsState, action: PayloadAction<ILocation[]>) => {
      const existingCountryIds = new Set(state.locations?.map((location) => location.country_id))
      const newLocations = action.payload.filter(
        (location: ILocation) => !existingCountryIds.has(location.country_id),
      )

      state.locations = [...(state.locations || []), ...newLocations]
      state.locationsChanged = true
    },
    updateProjectLocation: (state: ProjectsState, action: PayloadAction<ILocation>) => {
      const updatedLocation = action.payload
      const index = state.locations?.findIndex(
        (location) => location.country_id === updatedLocation.country_id,
      )

      if (index !== undefined && index >= 0 && state.locations) {
        state.locations[index] = updatedLocation
      }

      state.locationsChanged = !!(
        updatedLocation.languages?.length &&
        updatedLocation.engines?.length &&
        updatedLocation.cities?.length
      )
    },
    removeProjectLocation: (state: ProjectsState, action: PayloadAction<number>) => {
      state.locations = state.locations?.filter(
        (location) => location.country_id !== action.payload,
      )
      state.locationsChanged = true
    },
    setProjectTerms: (state: ProjectsState, action: PayloadAction<ITerm[]>) => {
      state.terms = action.payload
      state.termsChanged = false
    },
    updateProjectTerm: (state: ProjectsState, action: PayloadAction<ITerm>) => {
      const updatedTerm = action.payload
      const index = state.terms?.findIndex(
        (term) =>
          term.country_id === updatedTerm.country_id &&
          term.language_code === updatedTerm.language_code,
      )

      if (index !== undefined && index >= 0 && state.terms) {
        state.terms[index] = updatedTerm
      }

      state.termsChanged = true
    },
  },
})

export const {
  setProjectsFilter,
  setProjectsPagination,
  setProjectLocations,
  updateProjectLocations,
  updateProjectLocation,
  removeProjectLocation,
  setProjectTerms,
  updateProjectTerm,
} = projectsSlice.actions

export const selectProjectsFilters = (state: RootState) => state.root.projects.filters
export const selectProjectsPagination = (state: RootState) => state.root.projects.pagination
export const selectProjectLocations = (state: RootState) => state.root.projects.locations
export const selectProjectTerms = (state: RootState) => state.root.projects.terms
export const selectProjectLocationsChanged = (state: RootState) =>
  state.root.projects.locationsChanged
export const selectProjectTermsChanged = (state: RootState) => state.root.projects.termsChanged

export default projectsSlice.reducer
