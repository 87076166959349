import { ExportDrop } from '../../../../shared/components/cards/export-drop/ExportDrop'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks/Redux'
import { ILanguageComplianceFilter } from '../interface/LanguageComplianceFilter.interface'
import { selectLanguageComplianceFilter } from '../store/LanguageComplianceSlice'
import { useExportHistoryMutation } from '../../../../api/language-compliance/LanguageCompliance.api'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconUpload } from '../../../../shared/components/icons/IconUpload'

export const LanguageCompiliancePanel = () => {
  const filters = useAppSelector<ILanguageComplianceFilter>(selectLanguageComplianceFilter)
  const { project, country, url, affiliate, dateRange, restrictedWords, hidden } = filters
  const [queryParams, setQueryParams] = useState<any>()

  const [exportHistory, { isLoading: exportLoading }] = useExportHistoryMutation()

  useEffect(() => {
    setQueryParams({
      date: dateRange,
      project_id: Number(project),
      country_id: Number(country),
      url: url,
      affiliate: affiliate,
      word_id: restrictedWords ? Number(restrictedWords) : undefined,
      show_hidden: hidden,
    })
  }, [filters])

  return (
    <div className='flex gap-[16px] items-center'>
      <BpButton
        size='sm'
        theme='outline'
        iconLeft={<IconUpload />}
        loading={exportLoading}
        onClick={() => exportHistory({ ...queryParams })}
        type='button'
        className='w-[90px]'
      >
        Export
      </BpButton>
    </div>
  )
}
