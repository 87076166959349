import { GoogleLogin } from '@react-oauth/google'
import { setSignup } from '../../../store/reducers/AuthSlice'
import { useLazyGoogleOauthQuery } from '../../../api/site/Site.api'
import { useAppDispatch } from '../../../store/hooks/Redux'
import { useEffect } from 'react'

export const GoogleButton = () => {
  const [googleOauth] = useLazyGoogleOauthQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const googleSignInContainer = document.getElementById('googleSignIn') as HTMLElement

    if (googleSignInContainer && window.google?.accounts) {
      window.google.accounts.id.renderButton(googleSignInContainer, {
        width: googleSignInContainer.offsetWidth,
      })
    }
  }, [])

  return (
    <div id='googleSignIn' className={'w-full'}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          googleOauth({ credential: credentialResponse.credential }).then((response) => {
            dispatch(setSignup(response.data.data))
          })
        }}
        onError={() => {
          console.log('Login Failed')
        }}
      />
    </div>
  )
}
