import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'

export const tariffApi = createApi({
  reducerPath: 'tariff/api',
  tagTypes: ['Tariff'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/tariff/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getList: build.query<ICommonResponse<any>, any>({
      query: (params: any) => ({ url: 'list', params }),
      providesTags: ['Tariff'],
    }),
  }),
})

export const { useGetListQuery } = tariffApi
