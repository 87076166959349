import { BpSelect } from '../../../../../shared/components/forms/select/BpSelect'
import { IconFolder } from '../../../../../shared/components/icons/IconFolder'
import {
  useGetAffiliatesQuery,
  useGetBrowsersQuery,
  useGetCitiesQuery,
  useGetCountryQuery,
  useGetDomainsQuery,
  useGetEnginesQuery,
  useGetProjectsQuery,
} from '../../../../../api/filter/Filter.api'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { IAdOverviewFilter } from '../../interfaces/AdOverviewFilter.interface'
import {
  selectAdOverviewActiveTab,
  selectAdOverviewFilters,
  setAdOverviewFilter,
  TAdOverviewTabs,
} from '../../store/AdOverviewSlice'
import { IDomainParams } from '../../../../../api/filter/interfaces/DomainParams.interface'
import { BpSearchInput } from '../../../../../shared/components/forms/search-input/BpSearchInput'
import { IconCountry } from '../../../../../shared/components/icons/IconCountry'
import { FilterDrop } from '../../../../../shared/components/cards/filter-drop/FilterDrop'
import { BpDatePicker } from '../../../../../shared/components/forms/datepicker/BpDatePicker'
import { setCanCloseDropdown } from '../../../../../store/reducers/AppSlice'
import moment from 'moment'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_RANGE_FORMAT,
} from '../../../../../shared/consts/DefaultRangeFormat.const'
import { useEffect, useState } from 'react'
import { PolicyOptions } from '../../../../../shared/consts/PolicyOptions.const'
import {
  IWithSearchParamsProps,
  withSearchParams,
} from '../../../../../shared/hoc/WithSearchParams'
import { Switch } from 'antd'
import { ICityParams } from '../../../../../api/filter/interfaces/CityParams.interface'
import { IAffiliateParams } from '../../../../../api/filter/interfaces/AffiliatesParams.interface'
import { useTranslation } from 'react-i18next'

const Filter = ({ searchParams, setSearchParams }: IWithSearchParamsProps<IAdOverviewFilter>) => {
  const { t } = useTranslation()
  const activeTab = useAppSelector<TAdOverviewTabs>(selectAdOverviewActiveTab)
  const filters = useAppSelector<IAdOverviewFilter>(selectAdOverviewFilters)
  const { project, adDomain, dateRange, country, affiliate } = filters
  const dispatch = useAppDispatch()

  const {
    getValues,
    setValue,
    control,
    formState: { defaultValues },
  } = useForm<IAdOverviewFilter>({
    mode: 'onChange',
    defaultValues: { ...filters, ...searchParams },
  })

  useEffect(() => {
    applyFilter()
  }, [])

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const {
    data: engines,
    isFetching: enginesFetching,
    isLoading: enginesLoading,
  } = useGetEnginesQuery({ project_id: project ? +project : undefined }, { skip: !project })

  const {
    data: browsers,
    isFetching: browsersFetching,
    isLoading: browsersLoading,
  } = useGetBrowsersQuery()

  const {
    data: cities,
    isFetching: citiesFetching,
    isLoading: citiesLoading,
  } = useGetCitiesQuery(
    {
      project_id: project ? +project : undefined,
      country_id: country ? +country : undefined,
    } as ICityParams,
    { skip: !project },
  )

  const {
    data: adDomains,
    isFetching: domainsFetching,
    isLoading: domainsLoading,
  } = useGetDomainsQuery(
    {
      project_id: project ? +project : undefined,
      search: adDomain,
      date: dateRange,
      seo: false,
    } as IDomainParams,
    { skip: !project },
  )

  const {
    data: affiliates,
    isFetching: affiliatesFetching,
    isLoading: affiliatesLoading,
  } = useGetAffiliatesQuery(
    {
      date: dateRange,
      is_seo: false,
      project_id: +project,
      search: affiliate,
    } as IAffiliateParams,
    { skip: !project },
  )

  const applyFilter = () => {
    const values = getValues()
    dispatch(setAdOverviewFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <>
      <form className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.project
                ? defaultValues.project
                : project
                ? project
                : projects?.length && projects[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title={t('fields.project')}
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    if (e.target.value !== defaultValue) {
                      setValue('country', '')
                      setValue('adDomain', '')
                      setValue('search', '')
                      setValue('affiliate', '')
                    }
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='adDomain'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                options={adDomains}
                fieldSize='lg'
                placeholder={t('fields.advertiser')}
                loading={domainsLoading || domainsFetching}
                disabled={domainsLoading || domainsFetching}
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              />
            )}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSelect
                {...fieldProps}
                options={countries}
                Icon={IconCountry}
                fieldSize='lg'
                title={t('fields.country')}
                loading={countriesLoading || countriesFetching}
                disabled={countriesLoading || countriesFetching}
                defaultValue={defaultValues?.country}
                showSearch
                onChange={(e) => {
                  onChange(e)
                  if (e.target.value !== defaultValues?.country) {
                    setValue('city', '')
                  }
                  applyFilter()
                }}
              ></BpSelect>
            )}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] xl:w-auto xl:flex-1'>
          <Controller
            name='search'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                fieldSize='lg'
                placeholder={t('fields.forKeywordOrAd')}
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              ></BpSearchInput>
            )}
          />
        </div>
        <div className='flex flex-col w-full lg:w-[calc(50%-4px)] xl:w-auto'>
          <FilterDrop
            onSubmit={applyFilter}
            onClear={() => {
              setValue('engine', '')
              setValue('city', '')
              setValue('browser', '')
              setValue('policy', '')
              setValue('show_hidden', false)
              setValue('affiliate', '')
            }}
            values={getValues([
              'dateRange',
              'engine',
              'affiliate',
              'city',
              'browser',
              'policy',
              'show_hidden',
              'group',
            ])}
          >
            <Controller
              name='dateRange'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <BpDatePicker
                    onOpenChange={(e) => dispatch(setCanCloseDropdown(!e))}
                    label={t('fields.date')}
                    onChange={(e, t) => onChange(`${t[0]} - ${t[1]}`)}
                    defaultValue={[
                      moment(value, DEFAULT_DATE_FORMAT),
                      moment(value, DEFAULT_RANGE_FORMAT),
                    ]}
                  />
                )
              }}
            />
            <Controller
              name='engine'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={engines}
                  label={t('fields.searchEngine')}
                  placeholder={t('fields.other.selectFromList')}
                  loading={enginesLoading || enginesFetching}
                  disabled={enginesLoading || enginesFetching}
                />
              )}
            />
            <Controller
              name='affiliate'
              control={control}
              render={({ field }) => (
                <BpSearchInput
                  {...field}
                  label={t('fields.affiliate')}
                  placeholder={t('fields.other.search')}
                  options={affiliates}
                  loading={affiliatesLoading || affiliatesFetching}
                ></BpSearchInput>
              )}
            />
            <Controller
              name='city'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={cities}
                  label={t('fields.city')}
                  placeholder={t('fields.other.selectFromList')}
                  loading={citiesLoading || citiesFetching}
                  disabled={citiesLoading || citiesFetching}
                  defaultValue={field.value}
                  showSearch
                />
              )}
            />
            <Controller
              name='browser'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={browsers}
                  label={t('fields.browser')}
                  placeholder={t('fields.other.selectFromList')}
                  loading={browsersLoading || browsersFetching}
                  disabled={browsersLoading || browsersFetching}
                  defaultValue={field.value}
                />
              )}
            />
            <Controller
              name='policy'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  hasTranslate
                  options={[
                    {
                      text: 'Non-Compliant affiliates',
                      value: 'non_compliant_affiliates',
                      hidden: activeTab === 'Affiliates',
                    },
                    { text: 'Coupon sites', value: 'coupon_sites' },
                    {
                      text: 'Ad hijacking',
                      value: 'ad_hijacking',
                    },
                    ...PolicyOptions,
                  ]}
                  label={t('fields.policy')}
                  placeholder={t('fields.other.selectFromList')}
                ></BpSelect>
              )}
            />
            <Controller
              name='show_hidden'
              control={control}
              render={({ field }) => (
                <div className='flex justify-between gap-[15px]'>
                  <span className='text-focus-500 text-[15px] leading-[24px]'>
                    {t('fields.showHiddenAffiliates')}
                  </span>
                  <Switch {...field} defaultChecked={filters.show_hidden} />
                </div>
              )}
            />
            <Controller
              name='group'
              control={control}
              render={({ field }) => (
                <div className='flex justify-between gap-[15px]'>
                  <span className='text-focus-500 text-[15px] leading-[24px]'>
                    {t('fields.showUniqueAds')}
                  </span>
                  <Switch {...field} defaultChecked={filters.group} />
                </div>
              )}
            />
          </FilterDrop>
        </div>
      </form>
    </>
  )
}

export const AdOverviewFilters = withSearchParams<
  IWithSearchParamsProps<IAdOverviewFilter>,
  IAdOverviewFilter
>(Filter, selectAdOverviewFilters)
