import { ICommonResponse } from '../interfaces/CommonResponse.interface'
import { showAlert } from '../../store/reducers/AppSlice'
import { AppDispatch } from '../../store/Store'

export function handleCommonResponse(
  { success, errors }: ICommonResponse<unknown>,
  dispatch: AppDispatch,
  successMessage?: string,
): void {
  if (!success && errors) {
    const errorMessages = Array.isArray(errors)
      ? Object.values(errors).flat().join('\n')
      : Object.values(errors)[0]?.[0]

    dispatch(
      showAlert({
        message: errorMessages,
        timeout: 2000,
        type: 'error',
      }),
    )
  } else if (successMessage) {
    dispatch(
      showAlert({
        message: successMessage,
        type: 'success',
      }),
    )
  }
}
