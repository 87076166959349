import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export const AuthLayout = () => {
  const { t } = useTranslation()

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT === 'bluepear') {
      const gtagScript = document.createElement('script')
      gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 2000,
      });
      gtag("set", "ads_data_redaction", true);
      gtag("set", "url_passthrough", true);
    `
      document.head.appendChild(gtagScript)

      const cookieYesScript = document.createElement('script')
      cookieYesScript.src =
        'https://cdn-cookieyes.com/client_data/e3a46c8a3c7a861db08abbcf/script.js'
      document.head.appendChild(cookieYesScript)

      const gtmScript = document.createElement('script')
      gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NKXFCZD');
    `
      document.head.appendChild(gtmScript)

      return () => {
        document.head.removeChild(gtagScript)
        document.head.removeChild(cookieYesScript)
        document.head.removeChild(gtmScript)
      }
    }
  }, [])

  return (
    <div className='flex flex-col min-h-screen bg-grey md:bg-focus-500 px-[24px] md:px-[50px] py-[24px] overflow-hidden animate-appear-hand'>
      <div className='container mx-auto relative'>
        <div
          className='
          bg-white
          block
          w-[1181px]
          h-[1181px]
          md:-left-[605px]
          md:-top-[190px]
          absolute
          rotate-45
          rounded-[25%]
          invisible
          md:visible
        '
        ></div>
        <header className='relative flex justify-center md:justify-start mb-[31px] md:mb-[56px]'>
          <a href={t('project.landing')}>
            <img alt='logo' src={'/logo_dark.svg'} className='max-w-[166px]' />
          </a>
        </header>
        <section className='relative md:px-[50px]'>
          <Outlet />
        </section>
      </div>
    </div>
  )
}
