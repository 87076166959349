import { ReactNode } from 'react'

export interface IBpCard {
  title?: ReactNode
  description?: ReactNode
  children?: ReactNode
  footer?: ReactNode
  size?: 'sm' | 'md'
}

export const BpCard = ({ title, description, children, footer, size = 'md' }: IBpCard) => {
  return (
    <div className={'flex flex-col overflow-y-hidden'}>
      {title && (
        <div className='flex flex-col text-[20px] md:text-[24px] p-[24px] pb-[21px] border-b-2 border-b-focus-500/[0.02] flex gap-[15px]'>
          <h2 className={{ sm: 'text-[20px]', md: 'text-[24px]' }[size]}>{title}</h2>
          <p className={'text-[13px] text-focus-500'}>{description}</p>
        </div>
      )}
      <div className='p-[24px] flex flex-col gap-[24px] overflow-y-auto'>{children}</div>
      {footer && (
        <div className='p-[24px] border-t-2 border-t-focus-500/[0.02] flex gap-[16px]'>
          {footer}
        </div>
      )}
    </div>
  )
}
