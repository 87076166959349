import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { hideModal } from '../../../../store/reducers/AppSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { useUpdateInfoMutation } from '../../../../api/project/Project.api'
import { selectUser } from '../../../../store/reducers/AuthSlice'
import { useTranslation } from 'react-i18next'
import { IUpdateInfoForm } from '../interfaces/UpdateInfoForm.interface'

export const ProjectEditName = ({ id, name }: { id?: string; name: string }) => {
  const { t } = useTranslation()
  const { companyId } = useAppSelector(selectUser)
  const dispatch = useAppDispatch()

  const [updateInfo] = useUpdateInfoMutation()

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<IUpdateInfoForm>({
    mode: 'onChange',
    defaultValues: {
      name,
    },
  })

  const submit = (values: IUpdateInfoForm) => {
    updateInfo({ id: Number(id), client_id: companyId, ...values }).then(() =>
      dispatch(hideModal()),
    )
  }

  return (
    <form className={'flex flex-col'} onSubmit={handleSubmit(submit)}>
      <h3
        className={
          'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        {t('pages.project.editName')}
      </h3>
      <div className={'flex flex-col gap-[16px] pt-[18px] pb-[16px] px-[24px]'}>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              label={t('fields.projectName')}
              placeholder={t('pages.project.placeholders.projectName')}
              required
              error={errors.name?.message}
              {...register('name', {
                required: t('fields.validate.required'),
                maxLength: {
                  value: 100,
                  message: t('pages.project.validate.projectName'),
                },
              })}
            />
          )}
        />
      </div>
      <div
        className={
          'w-full flex gap-[12px] px-[24px] py-[24px] border-t-[3px] border-t-[#00326D]/[.02]'
        }
      >
        <BpButton type='submit' disabled={!isValid} className={'uppercase'}>
          {t('buttons.save')}
        </BpButton>
        <BpButton type='button' theme='transparent' onClick={() => dispatch(hideModal())}>
          {t('buttons.cancel')}
        </BpButton>
      </div>
    </form>
  )
}
