import { IWithSearchParamsProps, withSearchParams } from '../../../../shared/hoc/WithSearchParams'
import { IPpcComplianceFilter } from '../interfaces/PpcComplianceFilter.interface'
import {
  selectPpcComplianceCounter,
  selectPpcComplianceFilter,
  setPpcComplianceFilter,
  setPpcComplianceStatus,
} from '../store/PpcComplianceSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { Controller, useForm } from 'react-hook-form'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { IconFolder } from '../../../../shared/components/icons/IconFolder'
import { IconCountry } from '../../../../shared/components/icons/IconCountry'
import { BpSearchInput } from '../../../../shared/components/forms/search-input/BpSearchInput'
import { FilterDrop } from '../../../../shared/components/cards/filter-drop/FilterDrop'
import { BpDatePicker } from '../../../../shared/components/forms/datepicker/BpDatePicker'
import { setCanCloseDropdown } from '../../../../store/reducers/AppSlice'
import moment from 'moment'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_RANGE_FORMAT,
} from '../../../../shared/consts/DefaultRangeFormat.const'
import {
  useGetAffiliatesQuery,
  useGetAffiliateStatusQuery,
  useGetBrowsersQuery,
  useGetCitiesQuery,
  useGetCountryQuery,
  useGetDomainsQuery,
  useGetEnginesQuery,
  useGetProjectsQuery,
} from '../../../../api/filter/Filter.api'
import { IAffiliateParams } from '../../../../api/filter/interfaces/AffiliatesParams.interface'
import { IDomainParams } from '../../../../api/filter/interfaces/DomainParams.interface'
import { useEffect, useState } from 'react'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { PpcCompliancePanel } from './PpcCompliancePanel'
import { Switch } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const Filter = ({
  searchParams,
  setSearchParams,
}: IWithSearchParamsProps<IPpcComplianceFilter>) => {
  const { t } = useTranslation()
  const {
    project,
    country,
    affiliate,
    network,
    dateRange,
    site,
    engine,
    browser,
    city,
    hidden,
    status,
  } = useAppSelector<IPpcComplianceFilter>(selectPpcComplianceFilter)
  const counter = useAppSelector(selectPpcComplianceCounter)
  const [siteSearch, setSiteSearch] = useState<string>('')
  const [isDemo, setIsDemo] = useState(false)
  const dispatch = useAppDispatch()

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { defaultValues },
  } = useForm<IPpcComplianceFilter>({
    mode: 'onChange',
    defaultValues: {
      project,
      country,
      affiliate,
      network,
      dateRange,
      site,
      engine,
      browser,
      city,
      hidden,
      ...searchParams,
    },
  })

  useEffect(() => {
    applyFilter()
  }, [])

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const {
    data: affiliates,
    isFetching: affiliatesFetching,
    isLoading: affiliatesLoading,
  } = useGetAffiliatesQuery(
    {
      date: dateRange,
      is_seo: false,
      project_id: +project,
    } as IAffiliateParams,
    { skip: !project },
  )

  const {
    data: domains,
    isFetching: domainsFetching,
    isLoading: domainsLoading,
  } = useGetDomainsQuery(
    {
      project_id: project ? +project : undefined,
      search: getValues('site'),
      date: dateRange,
      seo: false,
    } as IDomainParams,
    { skip: !project },
  )

  const {
    data: engines,
    isFetching: enginesFetching,
    isLoading: enginesLoading,
  } = useGetEnginesQuery({ project_id: project ? +project : undefined }, { skip: !project })

  const {
    data: browsers,
    isFetching: browsersFetching,
    isLoading: browsersLoading,
  } = useGetBrowsersQuery()

  const {
    data: cities,
    isFetching: citiesFetching,
    isLoading: citiesLoading,
  } = useGetCitiesQuery(
    {
      project_id: project ? +project : undefined,
      country_id: country ? +country : undefined,
    },
    { skip: !project },
  )

  const { data: statuses } = useGetAffiliateStatusQuery()

  const applyFilter = (): void => {
    const values = getValues()
    dispatch(setPpcComplianceFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <section>
      <form
        onSubmit={handleSubmit(applyFilter)}
        className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'
      >
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue =
                defaultValues?.project ??
                project ??
                projects?.find((option) => !option.is_demo)?.value ??
                projects?.[0]?.value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title={t('fields.project')}
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)

                    const option = projects?.find((option) => option.value === e.target.value)
                    setIsDemo(option?.is_demo || false)

                    setValue('country', '')
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <BpSelect
                  {...fieldProps}
                  options={countries}
                  Icon={IconCountry}
                  fieldSize='lg'
                  title={t('fields.country')}
                  loading={countriesLoading || countriesFetching}
                  disabled={countriesLoading || countriesFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='affiliate'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                options={affiliates}
                fieldSize='lg'
                placeholder={t('fields.affiliate')}
                loading={affiliatesLoading || affiliatesFetching}
                disabled={affiliatesLoading || affiliatesFetching}
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              ></BpSearchInput>
            )}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='network'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                fieldSize='lg'
                placeholder={t('fields.network')}
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              ></BpSearchInput>
            )}
          />
        </div>
        <div className='flex flex-col w-full lg:w-[calc(50%-4px)] xl:w-auto'>
          <FilterDrop
            onSubmit={applyFilter}
            onClear={() => {
              setValue('site', '')
              setValue('engine', '')
              setValue('browser', '')
              setValue('city', '')
            }}
            values={getValues(['dateRange', 'site', 'engine', 'browser', 'city', 'hidden'])}
          >
            <Controller
              name='dateRange'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <BpDatePicker
                    onOpenChange={(e) => dispatch(setCanCloseDropdown(!e))}
                    label={t('fields.date')}
                    onChange={(e, t) => onChange(`${t[0]} - ${t[1]}`)}
                    defaultValue={[
                      moment(value, DEFAULT_DATE_FORMAT),
                      moment(value, DEFAULT_RANGE_FORMAT),
                    ]}
                  />
                )
              }}
            />
            <Controller
              name='site'
              control={control}
              render={({ field }) => (
                <BpSearchInput
                  {...field}
                  label={t('fields.advertiser')}
                  placeholder={t('fields.other.selectFromList')}
                  options={domains}
                  loading={domainsLoading || domainsFetching}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setSiteSearch(e.target.value)
                  }}
                ></BpSearchInput>
              )}
            />
            <Controller
              name='engine'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={engines}
                  label={t('fields.searchEngine')}
                  placeholder={t('fields.other.selectFromList')}
                  loading={enginesLoading || enginesFetching}
                  disabled={enginesLoading || enginesFetching}
                />
              )}
            />
            <Controller
              name='browser'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={browsers}
                  label={t('fields.browser')}
                  placeholder={t('fields.other.selectFromList')}
                  defaultValue={field.value}
                  loading={browsersLoading || browsersFetching}
                  disabled={browsersLoading || browsersFetching}
                />
              )}
            />
            <Controller
              name='city'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={cities}
                  label={t('fields.city')}
                  placeholder={t('fields.other.selectFromList')}
                  showSearch
                  loading={citiesLoading || citiesFetching}
                  disabled={citiesLoading || citiesFetching}
                />
              )}
            />
            <Controller
              name='hidden'
              control={control}
              render={({ field }) => (
                <div className='flex justify-between gap-[15px]'>
                  <span className='text-focus-500 text-[15px] leading-[24px]'>
                    {t('fields.showHiddenAffiliates')}
                  </span>
                  <Switch {...field} defaultChecked={defaultValues?.hidden} />
                </div>
              )}
            />
          </FilterDrop>
        </div>
      </form>
      {isDemo && (
        <div
          className={
            'w-full items-center flex gap-[8px] bg-[#FFF6A3] rounded-[12px] px-[16px] py-[8px] mt-[20px] -mb-[16px]'
          }
        >
          <svg
            className={'min-w-fit'}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M5.67678 1.76256C6.00497 1.43437 6.45009 1.25 6.91421 1.25H13.0858C13.5499 1.25 13.995 1.43437 14.3232 1.76256L18.2374 5.67678C18.5656 6.00497 18.75 6.45009 18.75 6.91421V13.0858C18.75 13.5499 18.5656 13.995 18.2374 14.3232L14.3232 18.2374C13.995 18.5656 13.5499 18.75 13.0858 18.75H6.91421C6.45009 18.75 6.00497 18.5656 5.67678 18.2374L1.76256 14.3232C1.43437 13.995 1.25 13.5499 1.25 13.0858V6.91421C1.25 6.45009 1.43437 6.00497 1.76256 5.67678L5.67678 1.76256ZM6.91421 2.75C6.84791 2.75 6.78432 2.77634 6.73744 2.82322L2.82322 6.73744C2.77634 6.78432 2.75 6.84791 2.75 6.91421V13.0858C2.75 13.1521 2.77634 13.2157 2.82322 13.2626L6.73744 17.1768C6.78432 17.2237 6.84791 17.25 6.91421 17.25H13.0858C13.1521 17.25 13.2157 17.2237 13.2626 17.1768L17.1768 13.2626C17.2237 13.2157 17.25 13.1521 17.25 13.0858V6.91421C17.25 6.84791 17.2237 6.78432 17.1768 6.73744L13.2626 2.82322C13.2157 2.77634 13.1521 2.75 13.0858 2.75H6.91421ZM10 8.25C10.4142 8.25 10.75 8.58579 10.75 9V13C10.75 13.4142 10.4142 13.75 10 13.75C9.58579 13.75 9.25 13.4142 9.25 13V9C9.25 8.58579 9.58579 8.25 10 8.25ZM10 7.5C10.4142 7.5 10.75 7.16421 10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75C9.25 7.16421 9.58579 7.5 10 7.5Z'
              fill='#00326D'
            />
          </svg>
          <p className={'text-[15px] text-focus-500'}>
            <Trans i18nKey='other.demoProjectSelected'>
              <NavLink className={'text-focus'} to='/projects' />
            </Trans>
          </p>
        </div>
      )}
      {statuses && (
        <BpTabs
          active={
            statuses.find((option) => {
              if (Number(option.value) === status) return option
            })?.text
          }
          className='mt-[19px]'
          onToggle={(e) =>
            dispatch(
              setPpcComplianceStatus(
                Number(
                  statuses.find((option) => {
                    if (option.text === e) return option
                  })?.value,
                ),
              ),
            )
          }
          selector
        >
          {statuses.map((status) => (
            <BpTab
              key={status.value}
              title={t('fields.options.' + status.text)}
              counter={
                status.text === 'Ignore' ? undefined : counter && counter[Number(status.value)]
              }
              panel={<PpcCompliancePanel />}
            />
          ))}
        </BpTabs>
      )}
    </section>
  )
}

export const PpcComplianceFilters = withSearchParams<
  IWithSearchParamsProps<IPpcComplianceFilter>,
  IPpcComplianceFilter
>(Filter, selectPpcComplianceFilter)
