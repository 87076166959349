import React from 'react'

export interface INavItemProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean
  theme?: 'default' | 'dark'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  decoration?: 'dot' | 'line' | 'rounded'
}

const classDecoration = {
  rounded: `after:rounded-sm
    after:h-[3px]
    after:bottom-0
    after:left-0
    after:right-0`,
  line: `after:rounded-t-sm
    after:h-[3px]
    after:bottom-0
    after:left-0
    after:right-0`,
  dot: `after:h-[6px]
    after:w-[6px]
    after:top-0
    after:bottom-0
    after:right-auto
    after:left-[-18px]
    after:m-auto
    after:rounded-t-full
    after:rounded-b-full`,
}

export const NavItem = (props: INavItemProps) => {
  const {
    children,
    active = false,
    theme = 'default',
    size = 'md',
    decoration = 'line',
    ...divAttributes
  } = props
  return (
    <div
      {...divAttributes}
      className={`py-[28px]
        cursor-pointer
        table
        group
        relative
        after:content-['']
        after:block
        after:absolute
        after:opacity-0
        after:transition-opacity
        after:duration-150
        hover:after:opacity-100
        flex-shrink
        ${classDecoration[decoration]}
        ${active && 'after:opacity-100'} 
        ${theme == 'dark' ? 'after:bg-default' : 'after:bg-fill'} 
        ${size == 'xs' && 'py-[3px] pb-[6px]'}
        ${size == 'md' && 'py-[12px]'}
        ${size == 'lg' && 'py-[13px]'}`}
    >
      <span
        className={`          
          transition-colors
          duration-150
          whitespace-nowrap
          ${theme == 'default' && `${active ? 'text-fill' : 'text-white'} group-hover:text-fill`}
          ${
            theme == 'dark' &&
            `${active ? 'text-default' : 'text-focus-500'} group-hover:text-default`
          }
          ${size == 'md' && 'text-[18px] leading-[24px]'}
          ${size == 'lg' && 'text-[24px] leading-[32px] font-medium'}`}
      >
        {children}
      </span>
    </div>
  )
}
