import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconSettings = ({ color = '#A059FF', className }: IIconProps) => {
  return (
    <svg
      className={`${className ? className : 'w-full'}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 1.5C4.5 1.22386 4.27614 1 4 1C3.72386 1 3.5 1.22386 3.5 1.5L3.5 8.05001C2.35888 8.28164 1.5 9.29052 1.5 10.5C1.5 11.7095 2.35888 12.7184 3.5 12.95L3.5 14.5C3.5 14.7761 3.72386 15 4 15C4.27614 15 4.5 14.7761 4.5 14.5L4.5 12.95C5.64112 12.7184 6.5 11.7095 6.5 10.5C6.5 9.29052 5.64112 8.28164 4.5 8.05001L4.5 1.5ZM5.5 10.5C5.5 11.3284 4.82843 12 4 12C3.17157 12 2.5 11.3284 2.5 10.5C2.5 9.67157 3.17157 9 4 9C4.82843 9 5.5 9.67157 5.5 10.5ZM12.5 1.5C12.5 1.22386 12.2761 1 12 1C11.7239 1 11.5 1.22386 11.5 1.5L11.5 4.05001C10.3589 4.28164 9.5 5.29052 9.5 6.5C9.5 7.70948 10.3589 8.71836 11.5 8.94999L11.5 14.5C11.5 14.7761 11.7239 15 12 15C12.2761 15 12.5 14.7761 12.5 14.5L12.5 8.94999C13.6411 8.71835 14.5 7.70948 14.5 6.5C14.5 5.29052 13.6411 4.28164 12.5 4.05001L12.5 1.5ZM12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5Z'
        fill={color}
      />
    </svg>
  )
}
