import React, { useState } from 'react'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'

export const LinkSetup = () => {
  const [step, setStep] = useState(1)

  return (
    <div className={'flex flex-col relative'}>
      <div>
        <p className={'px-[24px] pt-[17px] text-[13px] text-default font-medium'}>
          Step {step} of 4
        </p>
        <h3
          className={
            'px-[24px] text-[20px] pb-[8px] font-medium border-b-[3px] border-focus-600/[.03]'
          }
        >
          Введите URL
        </h3>
      </div>
      {
        {
          1: (
            <form className={'px-[24px] py-[20px]'}>
              <BpInput
                label={'URL Example'}
                info={
                  'Введите полный URL-адрес, на который будет вести трекинговая ссылка. Убедитесь, что адрес включает протокол (http/https) и все необходимые параметры, такие как UTM-метки, для корректного отслеживания.'
                }
                required
              />
              <p className={'text-aqua-300 text-[13px] whitespace-pre-line'}>
                Примеры корректных ссылок:
                <ul className={'pl-[14px] list-disc'}>
                  <li>https://example.com/?utm_source=google&utm_medium=cpc&utm_campaign=sale</li>
                  <li>https://example.com/?utm_source=cpc&utm_campaign=spring_sale</li>
                  <li>https://example.com/?utm_content=banner123</li>
                </ul>
              </p>
            </form>
          ),
          2: (
            <form className={'px-[24px] py-[20px] '}>
              <BpSelect
                label={'Domain zone'}
                info={
                  'Выберите доменные зоны для трекинговой ссылки. Это важно, если у вас есть локализованные версии сайта, например: .com, .ru, .de и другие.'
                }
                required
              />
            </form>
          ),
          3: (
            <form className={'flex flex-col gap-[16px] px-[24px] py-[20px]'}>
              <p className={'text-aqua-300 text-[13px] whitespace-pre-line'}>
                Ниже отображаются все найденные параметры в вашем URL. Отметьте те, которые хотите
                отслеживать, и назначьте для них соответствующую роль (например, “Партнёрская сеть”
                или “Аффилиат”). Параметры без выбранной роли будут проигнорированы.
              </p>
              <div className={'flex flex-col gap-[16px]'}>
                <div
                  className={
                    'flex justify-between items-center bg-[#F7F9FB] p-[16px] rounded-[16px]'
                  }
                >
                  <BpCheckbox label={'utm_medium: 213344'} />
                  <BpSelect className={'min-w-[180px]'} fieldSize={'sm'} />
                </div>
                <div
                  className={
                    'flex justify-between items-center bg-[#F7F9FB] p-[16px] rounded-[16px]'
                  }
                >
                  <BpCheckbox label={'utm_medium: 213344'} />
                  <BpSelect className={'min-w-[180px]'} fieldSize={'sm'} />
                </div>
              </div>
            </form>
          ),
          4: (
            <form className={'px-[24px] py-[20px]'}>
              <div className={'flex flex-col gap-[16px]'}>
                <div
                  className={
                    'flex justify-between items-center bg-[#F7F9FB] p-[16px] rounded-[16px]'
                  }
                >
                  <p className={'text-focus-500 text-[15px]'}>
                    <span className={'font-semibold'}>URL:</span>{' '}
                    https://example42074095790275.com/?utm_source=google&utm_medium=cpc&utm_campaign=sale
                  </p>
                  <BpButton size={'sm'} theme={'outline'}>
                    Edit
                  </BpButton>
                </div>
                <div
                  className={
                    'flex justify-between items-center bg-[#F7F9FB] p-[16px] rounded-[16px]'
                  }
                >
                  <p className={'text-focus-500 text-[15px]'}>
                    <span className={'font-semibold'}>Доменные зоны:</span> .org, .de, .ru
                  </p>
                  <BpButton size={'sm'} theme={'outline'}>
                    Edit
                  </BpButton>
                </div>
                <div
                  className={
                    'flex justify-between items-center bg-[#F7F9FB] p-[16px] rounded-[16px]'
                  }
                >
                  <p className={'text-focus-500 text-[15px]'}>
                    <span className={'font-semibold'}>Параметры:</span> Не выбрано
                  </p>
                  <BpButton size={'sm'} theme={'outline'}>
                    Edit
                  </BpButton>
                </div>
              </div>
            </form>
          ),
        }[step]
      }
      <div
        className={
          'flex justify-end pb-[21px] pt-[24px] px-[24px] border-t-[3px] border-focus-600/[.03]'
        }
      >
        {step > 1 && (
          <BpButton
            type={'button'}
            size={'xs'}
            theme={'transparent'}
            contentClassName={'text-aqua-300 font-normal'}
            onClick={() => setStep(step - 1)}
          >
            Back
          </BpButton>
        )}
        <BpButton className={'ml-auto uppercase'} type='button' onClick={() => setStep(step + 1)}>
          Next
        </BpButton>
      </div>
    </div>
  )
}
