import { Square } from '../../../shared/components/utility/square/Square'
import { HTMLAttributes, ReactElement } from 'react'
import { useWindowSize } from '../../../shared/hooks/UseWindowSize'

export interface ICommonAuthPageProps extends HTMLAttributes<HTMLDivElement> {
  form?: ReactElement
}

export const CommonAuthPage = ({ form, children }: ICommonAuthPageProps) => {
  const { xs, sm } = useWindowSize()

  return (
    <div className='flex justify-center md:justify-between'>
      {form && (
        <section className='w-[350px]'>
          {(xs || sm) && (
            <Square className='flex flex-col p-[24px]' depth='100'>
              {form}
            </Square>
          )}
          {!xs && !sm && form}
        </section>
      )}
      <section className='w-[472px] hidden xl:flex'>{children}</section>
    </div>
  )
}
