import { useParams } from 'react-router-dom'
import { useGetTermsQuery, useUpdateTermsMutation } from '../../../../api/project/Project.api'
import { BpTextArea } from '../../../../shared/components/forms/textarea/BpTextArea'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectProjectTerms,
  selectProjectTermsChanged,
  setProjectTerms,
  updateProjectTerm,
} from '../store/ProjectsSlice'
import { ITerm } from '../interfaces/Term.interface'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import { useTranslation } from 'react-i18next'
import { setModal } from '../../../../store/reducers/AppSlice'
import { ChangePlan } from './ChangePlan'
import { selectTariffDetails, setTariffUpdated } from '../../../../store/reducers/AuthSlice'
import { CardDrop } from '../../../../shared/components/cards/card-drop/CardDrop'

export const ProjectTerms = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const tariffDetails = useAppSelector(selectTariffDetails)
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false)
  const termsList = useAppSelector(selectProjectTerms)
  const termsChanged = useAppSelector(selectProjectTermsChanged)
  const dispatch = useAppDispatch()

  const { data, isSuccess, isLoading, isFetching } = useGetTermsQuery(
    { project_id: id || '' },
    { skip: !id },
  )
  const [updateTerms] = useUpdateTermsMutation()

  useEffect(() => {
    if (isSuccess && data?.data.items) {
      dispatch(setProjectTerms(data?.data.items))
    }
  }, [isFetching])

  const submit = () => {
    const data = termsList?.map((term) => ({
      ...term,
      terms: term.terms.filter((item) => item !== ''),
    }))

    updateTerms({ id, data }).then((response) => {
      dispatch(setTariffUpdated())

      if (
        !('error' in response) &&
        (response.data as any).errors.flat().includes('Tariff keywords limit exceeded')
      ) {
        setLimitExceeded(true)
      } else {
        setLimitExceeded(false)
      }
    })
  }

  const cancel = () => {
    dispatch(setProjectTerms(data?.data.items))
  }

  if (isLoading || isFetching) {
    return <Preloader />
  }

  return (
    <div className={'grid grid-rows-[auto_1fr_auto] h-full items-start'}>
      <div className={'flex min-h-[40px] items-center justify-between'}>
        <p className={'max-w-[750px] text-focus-500 text-[13px]'}>
          {t('pages.project.termsDescription')}
        </p>
      </div>
      <div className={'grid grid-cols-3 gap-x-[32px] gap-y-[16px] mt-[32px]'}>
        {termsList?.map((term: ITerm, index: number) => (
          <div key={index} className={'w-full'}>
            <BpTextArea
              label={`${term.country_name}, ${term.language_name}`}
              value={term.terms.join('\n')}
              placeholder={t('pages.project.placeholders.terms')}
              action={
                <button
                  className={'p-0 m-0 text-default text-[13px]'}
                  type={'button'}
                  onClick={() => {
                    dispatch(updateProjectTerm({ ...term, terms: [] }))
                  }}
                >
                  Clear list
                </button>
              }
              onChange={(e) => {
                dispatch(
                  updateProjectTerm({
                    ...term,
                    terms: e.target.value.replace(',', '\n').split('\n'),
                  }),
                )
              }}
              onBlur={(e) => {
                dispatch(
                  updateProjectTerm({
                    ...term,
                    terms: e.target.value
                      .replace(',', '\n')
                      .split('\n')
                      .filter((item) => item !== ''),
                  }),
                )
              }}
            />
          </div>
        ))}
      </div>
      {limitExceeded && (
        <div
          className={
            'bg-[#FF4980] rounded-[6px] p-[12px] mt-[24px] flex justify-between items-center'
          }
        >
          <p className={'text-white text-[15px]'}>
            You&apos;ve exceeded the limit of keywords for Standard. Please stay within the limit or
            update your plan.
          </p>
          <div className={'flex gap-[8px]'}>
            <BpButton
              theme={'light'}
              size={'sm'}
              type={'button'}
              onClick={() => dispatch(setModal({ children: <ChangePlan /> }))}
              contentClassName={'!text-[#00326D] !font-normal'}
            >
              Change Plan
            </BpButton>
            <CardDrop title={'Details'}>
              <div className={'min-w-[300px] p-[16px]'}>
                <div className={'flex items-center gap-[8px]'}>
                  <p className={'text-[15px]'}>
                    Used{' '}
                    <span className={'font-semibold'}>
                      {tariffDetails?.tariff?.used_keywords_count}/
                      {tariffDetails?.tariff?.max_keywords}
                    </span>{' '}
                    keywords
                  </p>
                </div>
                <ul className={'max-h-[110px] overflow-y-auto text-[15px] pl-[22px] list-disc'}>
                  {Object.entries(tariffDetails?.location_details).map(([key, value]) => (
                    <li key={value}>
                      {key}: {value}
                    </li>
                  ))}
                </ul>
              </div>
            </CardDrop>
          </div>
        </div>
      )}
      <div
        className={
          'flex gap-[12px] px-[16px] xl:px-[32px] -mx-[16px] xl:-mx-[32px] border-t-[3px] border-focus-600/[.03] pt-[24px] mt-[24px]'
        }
      >
        <BpButton disabled={!termsChanged} onClick={submit} className={'uppercase'}>
          {t('buttons.saveChanges')}
        </BpButton>
        <BpButton disabled={!termsChanged} onClick={cancel} theme='transparent'>
          {t('buttons.cancel')}
        </BpButton>
      </div>
    </div>
  )
}
