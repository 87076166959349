import { BpButton } from '../../buttons/bp-button/BpButton'
import { useTranslation } from 'react-i18next'

export interface IFormControlsParams {
  disabled?: boolean
  canceled?: boolean
  onCancel?: () => void
  onSubmit?: () => void
}

export const FormControls = ({
  disabled = false,
  canceled = true,
  onCancel,
  onSubmit,
}: IFormControlsParams) => {
  const { t } = useTranslation()

  return (
    <>
      <BpButton className={'uppercase'} type='submit' disabled={disabled} onClick={onSubmit}>
        {t('buttons.saveChanges')}
      </BpButton>
      <BpButton type='button' theme='transparent' disabled={!canceled} onClick={onCancel}>
        {t('buttons.cancel')}
      </BpButton>
    </>
  )
}
