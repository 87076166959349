import React, { ReactNode } from 'react'
import ContentLoader from 'react-content-loader'

export type TFieldTheme = 'default' | 'focus' | 'disabled' | 'success' | 'error' | 'text'
export type TFieldSize = 'lg' | 'md' | 'sm'

export interface IFieldProps {
  theme?: TFieldTheme
  fieldSize?: TFieldSize
  label?: string
  message?: string
  info?: string
  children?: ReactNode | undefined
  required?: boolean
  loading?: boolean
  action?: JSX.Element
}

export const Field = ({
  children,
  theme = 'default',
  fieldSize = 'md',
  label,
  message,
  info,
  required,
  loading,
  action,
}: IFieldProps) => {
  const MessageElement = () => {
    const classMessage: string = theme == 'success' ? 'text-success' : 'text-danger'
    return <div className={`text-[12px] leading-[18px] text-center ${classMessage}`}>{message}</div>
  }

  return (
    <div>
      <div className={'flex justify-between align-bottom'}>
        {label && (
          <div
            className={`text-[14px] font-medium mb-[2px] leading-[24px] ${
              theme == 'disabled' ? 'text-aqua-300' : 'text-focus-500'
            }`}
          >
            {label}
            {required && <span className={'text-default'}> *</span>}
          </div>
        )}
        {action}
      </div>
      <div
        className={`
          rounded-[8px]
          relative 
          ${loading && 'overflow-hidden'} 
          ${fieldSize === 'md' && 'p-[2px]'} 
          ${fieldSize === 'lg' && 'p-[4px]'}
          ${theme === 'default' && 'bg-grey'}
          ${theme === 'focus' && 'bg-grey-500'}
          ${theme === 'disabled' && 'bg-grey'}
          ${theme === 'error' && 'bg-danger-300'}
          ${theme === 'success' && 'bg-success-300'}
          ${theme === 'text' && 'bg-transparent'}
        `}
      >
        {loading && (
          <ContentLoader
            className='absolute bottom-0 left-0 top-0 w-full z-10'
            speed={2}
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='8' ry='8' width='100%' height='100%' />
          </ContentLoader>
        )}
        <div
          className={`
          relative
          rounded-[6px]
          shadow-outline
          ${theme === 'default' && 'bg-white shadow-focus/[0.65]'}
          ${theme === 'focus' && 'bg-white shadow-default/[0.65]'}
          ${theme === 'disabled' && 'bg-grey-600 shadow-focus-500/[0.65]'}
          ${theme === 'error' && 'bg-white shadow-danger/[0.65]'}
          ${theme === 'success' && 'bg-white shadow-success/[0.65]'}
          ${theme === 'text' && 'bg-transparent shadow-none'}
          `}
        >
          <div className={`${theme === 'disabled' && 'opacity-60'}`}>{children}</div>
        </div>
        {message && <MessageElement />}
      </div>
      <p className={'text-aqua-300 text-[13px] whitespace-pre-line'}>{info}</p>
    </div>
  )
}
