import { IWithSearchParamsProps, withSearchParams } from '../../../../shared/hoc/WithSearchParams'
import { IPpcComplianceFilter } from '../interfaces/PpcComplianceFilter.interface'
import {
  selectPpcComplianceCounter,
  selectPpcComplianceFilter,
  setPpcComplianceFilter,
  setPpcComplianceStatus,
} from '../store/PpcComplianceSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { Controller, useForm } from 'react-hook-form'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { IconFolder } from '../../../../shared/components/icons/IconFolder'
import { IconCountry } from '../../../../shared/components/icons/IconCountry'
import { BpSearchInput } from '../../../../shared/components/forms/search-input/BpSearchInput'
import { FilterDrop } from '../../../../shared/components/cards/filter-drop/FilterDrop'
import { BpDatePicker } from '../../../../shared/components/forms/datepicker/BpDatePicker'
import { setCanCloseDropdown } from '../../../../store/reducers/AppSlice'
import moment from 'moment'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_RANGE_FORMAT,
} from '../../../../shared/consts/DefaultRangeFormat.const'
import {
  useGetAffiliatesQuery,
  useGetAffiliateStatusQuery,
  useGetBrowsersQuery,
  useGetCitiesQuery,
  useGetCountryQuery,
  useGetDomainsQuery,
  useGetEnginesQuery,
  useGetProjectsQuery,
} from '../../../../api/filter/Filter.api'
import { IAffiliateParams } from '../../../../api/filter/interfaces/AffiliatesParams.interface'
import { IDomainParams } from '../../../../api/filter/interfaces/DomainParams.interface'
import { useEffect, useState } from 'react'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { PpcCompliancePanel } from './PpcCompliancePanel'
import { Switch } from 'antd'

const Filter = ({
  searchParams,
  setSearchParams,
}: IWithSearchParamsProps<IPpcComplianceFilter>) => {
  const {
    project,
    country,
    affiliate,
    network,
    dateRange,
    site,
    engine,
    browser,
    city,
    hidden,
    status,
  } = useAppSelector<IPpcComplianceFilter>(selectPpcComplianceFilter)
  const counter = useAppSelector(selectPpcComplianceCounter)
  const [siteSearch, setSiteSearch] = useState<string>('')
  const dispatch = useAppDispatch()

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { defaultValues },
  } = useForm<IPpcComplianceFilter>({
    mode: 'onChange',
    defaultValues: {
      project,
      country,
      affiliate,
      network,
      dateRange,
      site,
      engine,
      browser,
      city,
      hidden,
      ...searchParams,
    },
  })

  useEffect(() => {
    applyFilter()
  }, [])

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const {
    data: affiliates,
    isFetching: affiliatesFetching,
    isLoading: affiliatesLoading,
  } = useGetAffiliatesQuery(
    {
      date: dateRange,
      is_seo: false,
      project_id: +project,
    } as IAffiliateParams,
    { skip: !project },
  )

  const {
    data: domains,
    isFetching: domainsFetching,
    isLoading: domainsLoading,
  } = useGetDomainsQuery(
    {
      project_id: project ? +project : undefined,
      search: getValues('site'),
      date: dateRange,
      seo: false,
    } as IDomainParams,
    { skip: !project },
  )

  const {
    data: engines,
    isFetching: enginesFetching,
    isLoading: enginesLoading,
  } = useGetEnginesQuery({ project_id: project ? +project : undefined }, { skip: !project })

  const {
    data: browsers,
    isFetching: browsersFetching,
    isLoading: browsersLoading,
  } = useGetBrowsersQuery()

  const {
    data: cities,
    isFetching: citiesFetching,
    isLoading: citiesLoading,
  } = useGetCitiesQuery(
    {
      project_id: project ? +project : undefined,
      country_id: country ? +country : undefined,
    },
    { skip: !project },
  )

  const { data: statuses } = useGetAffiliateStatusQuery()

  const applyFilter = (): void => {
    const values = getValues()
    dispatch(setPpcComplianceFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(applyFilter)}
        className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'
      >
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.project
                ? defaultValues.project
                : project
                ? project
                : projects?.length && projects[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title='Project'
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    setValue('country', '')
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <BpSelect
                  {...fieldProps}
                  options={countries}
                  Icon={IconCountry}
                  fieldSize='lg'
                  title='Country'
                  loading={countriesLoading || countriesFetching}
                  disabled={countriesLoading || countriesFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='affiliate'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                options={affiliates}
                fieldSize='lg'
                placeholder='Affiliate ID'
                loading={affiliatesLoading || affiliatesFetching}
                disabled={affiliatesLoading || affiliatesFetching}
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              ></BpSearchInput>
            )}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='network'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                fieldSize='lg'
                placeholder='Network'
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              ></BpSearchInput>
            )}
          />
        </div>
        <div className='flex flex-col w-full lg:w-[calc(50%-4px)] xl:w-auto'>
          <FilterDrop
            onSubmit={applyFilter}
            onClear={() => {
              setValue('site', '')
              setValue('engine', '')
              setValue('browser', '')
              setValue('city', '')
            }}
            values={getValues(['dateRange', 'site', 'engine', 'browser', 'city', 'hidden'])}
          >
            <Controller
              name='dateRange'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <BpDatePicker
                    onOpenChange={(e) => dispatch(setCanCloseDropdown(!e))}
                    label='Date'
                    onChange={(e, t) => onChange(`${t[0]} - ${t[1]}`)}
                    defaultValue={[
                      moment(value, DEFAULT_DATE_FORMAT),
                      moment(value, DEFAULT_RANGE_FORMAT),
                    ]}
                  />
                )
              }}
            />
            <Controller
              name='site'
              control={control}
              render={({ field }) => (
                <BpSearchInput
                  {...field}
                  label='Advertiser'
                  placeholder='Select from list'
                  options={domains}
                  loading={domainsLoading || domainsFetching}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setSiteSearch(e.target.value)
                  }}
                ></BpSearchInput>
              )}
            />
            <Controller
              name='engine'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={engines}
                  label='Search Engine'
                  placeholder='Select from list'
                  loading={enginesLoading || enginesFetching}
                  disabled={enginesLoading || enginesFetching}
                />
              )}
            />
            <Controller
              name='browser'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={browsers}
                  label='Browser'
                  placeholder='Select from list'
                  defaultValue={field.value}
                  loading={browsersLoading || browsersFetching}
                  disabled={browsersLoading || browsersFetching}
                />
              )}
            />
            <Controller
              name='city'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={cities}
                  label='City'
                  placeholder='Select from list'
                  showSearch
                  loading={citiesLoading || citiesFetching}
                  disabled={citiesLoading || citiesFetching}
                />
              )}
            />
            <Controller
              name='hidden'
              control={control}
              render={({ field }) => (
                <div className='flex justify-between gap-[15px]'>
                  <span className='text-focus-500 text-[15px] leading-[24px]'>
                    Show hidden affiliates
                  </span>
                  <Switch {...field} defaultChecked={defaultValues?.hidden} />
                </div>
              )}
            />
          </FilterDrop>
        </div>
      </form>
      {statuses && (
        <BpTabs
          active={
            statuses.find((option) => {
              if (Number(option.value) === status) return option
            })?.text
          }
          className='mt-[19px] flex-wrap'
          onToggle={(e) =>
            dispatch(
              setPpcComplianceStatus(
                Number(
                  statuses.find((option) => {
                    if (option.text === e) return option
                  })?.value,
                ),
              ),
            )
          }
          selector
        >
          {statuses.map((status) => (
            <BpTab
              key={status.value}
              title={status.text}
              counter={
                status.text === 'Ignore' ? undefined : counter && counter[Number(status.value)]
              }
              panel={<PpcCompliancePanel />}
            />
          ))}
        </BpTabs>
      )}
    </>
  )
}

export const PpcComplianceFilters = withSearchParams<
  IWithSearchParamsProps<IPpcComplianceFilter>,
  IPpcComplianceFilter
>(Filter, selectPpcComplianceFilter)
