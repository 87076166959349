import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconCrown = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 14 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5037 3.92006L12.2737 9.35506C12.2493 9.46877 12.186 9.57043 12.0947 9.64252C12.0035 9.71462 11.8899 9.75264 11.7737 9.75006H2.19367C2.0774 9.75264 1.96386 9.71462 1.8726 9.64252C1.78134 9.57043 1.71806 9.46877 1.69367 9.35506L0.503669 3.92006C0.483642 3.82822 0.489906 3.7326 0.521744 3.64416C0.553581 3.55573 0.609702 3.47805 0.683669 3.42006C0.757839 3.36185 0.846954 3.3258 0.940732 3.31607C1.03451 3.30634 1.12913 3.32332 1.21367 3.36506L4.13367 4.78006L6.55367 0.500056C6.59728 0.423107 6.66052 0.359103 6.73694 0.314574C6.81336 0.270044 6.90022 0.246582 6.98867 0.246582C7.07712 0.246582 7.16398 0.270044 7.2404 0.314574C7.31682 0.359103 7.38006 0.423107 7.42367 0.500056L9.84367 4.78506L12.7837 3.36006C12.8688 3.31492 12.9652 3.29535 13.0612 3.30371C13.1572 3.31206 13.2487 3.34798 13.3248 3.40715C13.4009 3.46631 13.4582 3.5462 13.49 3.6372C13.5217 3.72819 13.5265 3.82642 13.5037 3.92006Z'
        fill={color}
      />
    </svg>
  )
}
