import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectProjectsFilters,
  selectProjectsPagination,
  setProjectsPagination,
} from '../store/ProjectsSlice'
import { IProjectFilters } from '../interfaces/ProjectFilters.interface'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../shared/hooks/UseSorter'
import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Space, Tooltip } from 'antd'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { setModal } from '../../../../store/reducers/AppSlice'
import { AddProject } from './AddProject'
import { useGetListQuery, useUpdateStatusMutation } from '../../../../api/project/Project.api'
import { cropRow } from '../../../../shared/helpers/crop-row'
import { DropOptions } from '../../../../shared/components/forms/drop-options/DropOptions'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { IconSettings } from '../../../../shared/components/icons/IconSettings'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconPlus } from '../../../../shared/components/icons/IconPlus'
import { ChangePlan } from './ChangePlan'
import { IconCrown } from '../../../../shared/components/icons/IconCrown'
import { ProjectModel } from '../../../../api/project/models/Project.model'
import { IGetProjectsList } from '../../../../api/project/interfaces/GetProjectsList.interface'
import { selectTariffDetails, setTariffUpdated } from '../../../../store/reducers/AuthSlice'
import { TariffButton } from '../../../../shared/components/buttons/tariff-button/TariffButton'

export const ProjectsTable = () => {
  const { t } = useTranslation()
  const tariffDetails = useAppSelector(selectTariffDetails)
  const navigate = useNavigate()
  const filters = useAppSelector<IProjectFilters>(selectProjectsFilters)
  const { project, country, status } = filters
  const { current, pageSize, total } = useAppSelector<IPagination>(selectProjectsPagination)
  const dispatch = useAppDispatch()

  const [queryParams, setQueryParams] = useState<IGetProjectsList>({
    page: current,
    'per-page': pageSize,
  })
  const { data, isLoading, isFetching } = useGetListQuery(queryParams)
  const [updateStatus] = useUpdateStatusMutation()

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      project_id: project ? project : undefined,
      country_id: country ? country : undefined,
      status: status ? status : undefined,
    })
  }, [filters])

  const columns: ColumnsType<ProjectModel> = [
    {
      title: t('table.columns.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name: string) => <div className={'truncate w-[185px]'}>{name}</div>,
    },
    {
      title: t('table.columns.countries'),
      key: 'countries',
      render: (_: string, { countries }) => {
        const { visible, hidden } = cropRow(countries, 25)

        return (
          <div className={'flex gap-[8px] items-center w-fit'}>
            {visible}{' '}
            {!!hidden.length && (
              <Tooltip title={hidden.join(', ')}>
                <span
                  className={'text-focus text-[13px] leading-[13px] ml-auto border-dotted border-b'}
                >
                  +{hidden.length}
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: t('table.columns.status'),
      key: 'status',
      render: (_: string, { id, status }) => {
        const options = [
          {
            text: t('pages.projects.activate'),
            value: 'active',
            hidden: ['Active', 'Archived'].includes(status),
          },
          {
            text: t('pages.projects.pause'),
            value: 'pause',
            hidden: ['On Pause', 'Archived'].includes(status),
          },
          {
            text: t('pages.projects.archive'),
            value: 'archive',
            hidden: status === 'Archived',
          },
          {
            text: t('pages.projects.restore'),
            value: 'active',
            hidden: status !== 'Archived',
          },
        ]
        return (
          <DropOptions
            options={options}
            onChange={(value) => {
              updateStatus({ id, status: value }).then(() => dispatch(setTariffUpdated()))
            }}
          >
            <span
              className={`text-[10px] font-semibold uppercase py-[2px] px-[8px] rounded-[6px] cursor-pointer
              ${
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {
                  Active: 'text-success-400 bg-success-300',
                  'On Pause': 'text-[#DA5533] bg-danger/[.07]',
                  Archived: 'text-aqua-300 bg-aqua-300/[.07]',
                }[status]
              }`}
            >
              {t(`pages.projects.statuses.${status}`)}
            </span>
          </DropOptions>
        )
      },
    },
    {
      title: t('table.columns.periodicity'),
      key: 'periodicity',
      render: (_: string, { periodicity }) => {
        return <div>{periodicity ? periodicity : '—'}</div>
      },
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.keywords')}
          <BpTooltip title={t('pages.projects.keywordsTooltip')} />
        </span>
      ),
      dataIndex: 'keywords_count',
      key: 'keywords_count',
    },
    {
      title: t('table.columns.created'),
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: t('table.columns.updated'),
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      key: 'actions',
      render: (_, { id }) => {
        return (
          <Space>
            <IconButton size='lg' onClick={() => navigate(`/projects/${id}`)}>
              <IconSettings color='#00326D' />
            </IconButton>
          </Space>
        )
      },
      width: 45,
    },
  ]

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<ProjectModel> | SorterResult<ProjectModel>[],
  ) => {
    dispatch(
      setProjectsPagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <section>
      <div className='flex items-center px-[16px] py-[21px] xl:px-[32px] mb-[21px] -mx-[16px] xl:-mx-[32px] border-b-[3px] border-focus-600/[.03]'>
        <BpButton
          contentClassName={'font-normal'}
          iconLeft={<IconPlus />}
          size={'sm'}
          disabled={
            !(
              tariffDetails.tariff &&
              tariffDetails.tariff.max_projects >= tariffDetails.tariff.used_projects_count
            )
          }
          onClick={() => dispatch(setModal({ children: <AddProject /> }))}
        >
          {t('pages.projects.addProject')}
        </BpButton>
        <TariffButton />
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<ProjectModel>}
        pagination={{
          current,
          pageSize,
          total,
        }}
      />
    </section>
  )
}
