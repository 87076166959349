import { useAppDispatch } from '../../../../store/hooks/Redux'
import React, { useState } from 'react'
import { setModal } from '../../../../store/reducers/AppSlice'
import { LinkSetup } from './LinkSetup'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconPlus } from '../../../../shared/components/icons/IconPlus'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import { ProjectModel } from '../../../../api/project/models/Project.model'
import { ColumnsType } from 'antd/lib/table'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../shared/hooks/UseSorter'

export const ProjectTracking = () => {
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    'per-page': 10,
  })
  const dispatch = useAppDispatch()

  const columns: ColumnsType<any> = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      sorter: true,
    },
    {
      title: 'Tracking params',
      dataIndex: 'tracking_params',
      key: 'tracking_params',
      sorter: true,
    },
    {
      title: 'Affiliate params',
      dataIndex: 'affiliate_params',
      key: 'affiliate_params',
      sorter: true,
    },
    {
      title: 'Network params',
      dataIndex: 'network_params',
      key: 'network_params',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
    },
  ]

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<any> | SorterResult<any>[],
  ) => {
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <div>
      <div
        className={
          'flex items-center gap-[16px] px-[16px] xl:px-[32px] -mx-[16px] xl:-mx-[32px] py-[24px] shadow-row-shadow'
        }
      >
        <p className={'text-focus-500 font-medium text-[15px]'}>Affiliate networks</p>
        <BpButton contentClassName={'font-normal uppercase'} size={'sm'} theme={'outline'}>
          Add
        </BpButton>
      </div>
      <div className={'py-[24px]'}>
        <BpButton
          onClick={() => dispatch(setModal({ children: <LinkSetup />, width: 640 }))}
          size={'sm'}
          iconLeft={<IconPlus />}
        >
          Add pattern
        </BpButton>
      </div>
      <div>
        <BpTable
          dataSource={[]}
          rowKey={(record) => record.id}
          columns={columns}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
          data={[] as TTableResponse<ProjectModel>}
          pagination={{
            current: 1,
            pageSize: 10,
            total: 100,
          }}
        />
      </div>
    </div>
  )
}
