import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IOption } from '../../shared/components/forms/search-input/BpSearchInput'

export const adminApi = createApi({
  reducerPath: 'admin/api',
  tagTypes: ['Admin'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/admin/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getVirtualUsers: build.query<IOption[], any>({
      query: () => ({ url: 'virtual-users' }),
      providesTags: ['Admin'],
      transformResponse: (response: any) => {
        if (response?.data) {
          return Object.keys(response.data.users).map((row) => {
            const user = response.data.users[row]

            return { text: user.company_name, value: String(user.user_id) }
          })
        }

        return []
      },
    }),
    getVirtualLogin: build.query<ICommonResponse<any>, any>({
      query: (params: any) => ({ url: 'virtual-login', params }),
      providesTags: ['Admin'],
    }),
  }),
})

export const { useGetVirtualUsersQuery, useLazyGetVirtualLoginQuery } = adminApi
