import React from 'react'
import { useTranslation } from 'react-i18next'

export const Tags = ({ value }: { value: string[] }) => {
  const { t } = useTranslation()

  return (
    <div className={'flex gap-[5px]'}>
      {value.map((value, index) => (
        <span
          key={index}
          className={
            'text-orange text-[9px] leading-[14px] px-[4px] bg-orange/[.07] rounded-[2px] border-[1px] border-orange uppercase'
          }
        >
          {t('components.tags.' + value)}
        </span>
      ))}
    </div>
  )
}
