import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import { useExportFileMutation } from '../../../../../api/export-reports/ExportReports.api'
import { useEffect, useState } from 'react'
import { IAllAdvertisersParams } from '../../../../../api/advertiser-report/interfaces/AllAdvertisersParams.interface'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { IPagination } from '../../../../../shared/interfaces/Pagination.interface'
import {
  selectAdvertiserReportFilters,
  selectAdvertiserReportPagination,
} from '../../store/AdvertiserReportSlice'
import { IAdvertiserReportFilter } from '../../interfaces/IAdvertiserReportFilter'
import { useTranslation } from 'react-i18next'
import { IconMinimize } from '../../../../../shared/components/icons/IconMinimize'
import { IconMaximize } from '../../../../../shared/components/icons/IconMaximize'
import { BpButton } from '../../../../../shared/components/buttons/bp-button/BpButton'

export const AdvertiserReportPanel = () => {
  const { t } = useTranslation()
  const advertiserFilter = useAppSelector<IAdvertiserReportFilter>(selectAdvertiserReportFilters)
  const {
    project,
    dateRange: date,
    country,
    policy,
    advertiser,
    status,
    keyword,
    affiliate,
    show_hidden,
    engine,
    browser,
    is_decloak,
  } = advertiserFilter
  const advertiserPagination = useAppSelector<IPagination>(selectAdvertiserReportPagination)
  const { current, pageSize, total } = advertiserPagination
  const [queryParams, setQueryParams] = useState<IAllAdvertisersParams>({
    page: current,
    'per-page': pageSize,
  })

  const [exportFile, { isLoading: exportFileLoading }] = useExportFileMutation()

  useEffect(() => {
    if (!project) return

    setQueryParams({
      ...queryParams,
      project_id: +project,
      country_id: country ? +country : undefined,
      engine_id: engine ? +engine : undefined,
      browser_id: browser ? +browser : undefined,
      policy,
      date,
      site: advertiser,
      status,
      keyword,
      affiliate,
      show_hidden,
      is_decloak,
    })
  }, [advertiserFilter, advertiserPagination])

  return (
    <span className='flex gap-[9px]'>
      <ExportDrop
        onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
        loading={exportFileLoading}
        columns={[
          {
            label: t('pages.paidResults.advertiserReport'),
            bold: true,
            child: [
              { keys: ['advertiser'], label: t('table.columns.advertiser') },
              { keys: ['count_total'], label: t('table.columns.adsAnalyzed') },
              { keys: ['keywords_count'], label: t('table.columns.keywords') },
              { keys: ['visibility'], label: t('table.columns.visibility') },
              { keys: ['affiliates_count'], label: t('table.columns.affiliates') },
              { keys: ['progress_status'], label: t('table.columns.progressStatus') },
              { keys: ['last_found'], label: t('table.columns.lastFound') },
            ],
          },
          {
            keys: ['latest_examples'],
            label: t('buttons.latestExamples'),
            tooltip: t('buttons.other.latestExamplesTooltip'),
            bold: true,
          },
        ]}
      />
    </span>
  )
}
