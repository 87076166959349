import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { ColumnsType } from 'antd/lib/table'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { IAdvertiserReportFilter } from '../../interfaces/IAdvertiserReportFilter'
import {
  selectAdvertiserReportFilters,
  selectAdvertiserReportPagination,
  selectAllowDecloak,
  setAdvertiserReportPagination,
  setAllowDecloak,
} from '../../store/AdvertiserReportSlice'
import {
  useEditDecloakSettingsMutation,
  useEditSiteSettingsMutation,
  useGetAllAdvertisersQuery,
} from '../../../../../api/advertiser-report/AdvertiserReport.api'
import { IAllAdvertisersParams } from '../../../../../api/advertiser-report/interfaces/AllAdvertisersParams.interface'
import { IAdvertiserModel } from '../../../../../api/advertiser-report/models/Advertiser.model'
import { ProgressBar } from '../../../../../shared/components/cards/progress-bar/ProgressBar'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { IEditSiteSettingsParams } from '../../../../../api/advertiser-report/interfaces/EditSiteSettingsParams.interface'
import { IconButton } from '../../../../../shared/components/buttons/icon-button/IconButton'
import { TriangleButton } from '../../../../../shared/components/buttons/triangle/TriangleButton'
import { IconEyeOff } from '../../../../../shared/components/icons/IconEyeOff'
import { IconEye } from '../../../../../shared/components/icons/IconEye'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { IconSpy } from '../../../../../shared/components/icons/IconSpy'
import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import { AdvertiserExpandRow } from './AdvertiserExpandRow'
import { EStatus } from '../../../../../api/advertiser-report/enums/Status.enum'
import { BpSelect } from '../../../../../shared/components/forms/select/BpSelect'
import { StatusOptions } from '../../const/StatusOptions.const'
import { IconMaximize } from '../../../../../shared/components/icons/IconMaximize'
import { IconMinimize } from '../../../../../shared/components/icons/IconMinimize'
import { IWithExpandedAllProps, withExpanded } from '../../../../../shared/hoc/WithExpanded'
import { BpButton } from '../../../../../shared/components/buttons/bp-button/BpButton'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import { IPagination } from '../../../../../shared/interfaces/Pagination.interface'
import { useExportFileMutation } from '../../../../../api/export-reports/ExportReports.api'
import { Space, Tooltip } from 'antd'
import { IconSpyOff } from '../../../../../shared/components/icons/IconSpyOff'
import { useTranslation } from 'react-i18next'

const Table = ({
  onToggleExpand,
  isExpandAll,
  expandedRowKeys,
  onToggleExpandAll,
}: IWithExpandedAllProps) => {
  const { t } = useTranslation()
  const [countRows, setCountRows] = useState<number>(0)
  const advertiserFilter = useAppSelector<IAdvertiserReportFilter>(selectAdvertiserReportFilters)
  const advertiserPagination = useAppSelector<IPagination>(selectAdvertiserReportPagination)
  const allowDecloak = useAppSelector<boolean>(selectAllowDecloak)
  const {
    project,
    dateRange: date,
    country,
    policy,
    advertiser,
    status,
    keyword,
    affiliate,
    show_hidden,
    engine,
    browser,
    is_decloak,
  } = advertiserFilter
  const { current, pageSize, total } = advertiserPagination

  const [queryParams, setQueryParams] = useState<IAllAdvertisersParams>({
    page: current,
    'per-page': pageSize,
  })
  const { data, isLoading, isFetching } = useGetAllAdvertisersQuery(
    queryParams as IAllAdvertisersParams,
    {
      skip: !queryParams.project_id,
    },
  )
  const [exportFile, { isLoading: exportFileLoading }] = useExportFileMutation()
  const [editSiteSetting, { isLoading: editSettingsLoading }] = useEditSiteSettingsMutation()
  const [editDecloakSetting] = useEditDecloakSettingsMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!project) return

    setQueryParams({
      ...queryParams,
      project_id: +project,
      country_id: country ? +country : undefined,
      engine_id: engine ? +engine : undefined,
      browser_id: browser ? +browser : undefined,
      policy,
      date,
      site: advertiser,
      status,
      keyword,
      affiliate,
      show_hidden,
      is_decloak,
    })
  }, [advertiserFilter])

  useEffect(() => {
    if (data?.data) {
      setCountRows(data.data.total)
    }

    if (data?.data?.allow_decloak !== undefined) {
      dispatch(setAllowDecloak(data.data.allow_decloak))
    }
  }, [data])

  const columns: ColumnsType<IAdvertiserModel> = [
    {
      title: t('table.columns.advertiser'),
      dataIndex: 'advertiser',
      key: 'advertiser',
      render: (_: string, { advertiser, is_decloak }: IAdvertiserModel) => {
        return (
          <div>
            <BpAnchor className='flex-1' target='_blank' href={`https://${advertiser}`}>
              {advertiser}
            </BpAnchor>
            {is_decloak && (
              <Tooltip
                arrowPointAtCenter
                placement='topLeft'
                title='Our system is currently revealing cloaked ads for this site.'
              >
                <div className='w-[22px] inline-block ml-[12px]'>
                  <IconSpy color='#00326D' />
                </div>
              </Tooltip>
            )}
          </div>
        )
      },
      width: '320px',
    },
    {
      title: t('table.columns.adsAnalyzed'),
      showSorterTooltip: false,
      dataIndex: 'count_total',
      key: 'count_total',
      sorter: true,
    },
    {
      title: t('table.columns.keywords'),
      dataIndex: 'keywords_count',
      key: 'keywords_count',
      sorter: true,
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.visibility')}{' '}
          <BpTooltip title={t('pages.paidResults.visibilityTooltip')} />
        </span>
      ),
      dataIndex: 'visibility',
      key: 'visibility',
      render: (_: number, record: IAdvertiserModel) => {
        return <ProgressBar className='w-[115px]' percent={record.visibility} />
      },
      sorter: true,
    },
    {
      title: t('table.columns.affiliates'),
      dataIndex: 'affiliates_count',
      key: 'affiliates_count',
      sorter: true,
    },
    {
      title: t('table.columns.progressStatus'),
      dataIndex: 'progress_status',
      key: 'progress_status',
      render: (
        _: EStatus,
        { advertiser: domain, is_hide, is_affiliate, progress_status }: IAdvertiserModel,
      ) => {
        return (
          <BpSelect
            hasTranslate
            fieldSize='sm'
            height={110}
            placeholder={t('fields.other.selectFromList')}
            options={StatusOptions}
            defaultValue={progress_status}
            disabled={editSettingsLoading}
            onChange={({ target: { value: status } }) => {
              if (!project || status == (progress_status === null ? '' : progress_status)) return
              editSiteSetting({
                domain,
                project_id: +project,
                status,
                is_hide: is_hide ? 1 : 0,
                is_affiliate: is_affiliate ? 1 : 0,
              } as IEditSiteSettingsParams)
            }}
          />
        )
      },
    },
    {
      title: t('table.columns.lastFound'),
      dataIndex: 'last_found',
      key: 'last_found',
      width: '150px',
      sorter: true,
    },
    {
      key: 'actions',
      render: (
        _: IAdvertiserModel,
        {
          is_hide,
          is_decloak,
          is_affiliate,
          advertiser: domain,
          progress_status: status,
        }: IAdvertiserModel,
      ) => {
        return (
          <Space>
            {allowDecloak && (
              <IconButton
                size='lg'
                onClick={() => {
                  if (!project) return
                  editDecloakSetting({
                    project_id: +project,
                    domain,
                    is_decloak: is_decloak ? 0 : 1,
                  })
                }}
              >
                {is_decloak ? <IconSpyOff /> : <IconSpy />}
              </IconButton>
            )}
            <IconButton
              size='lg'
              onClick={() => {
                if (!project) return
                editSiteSetting({
                  is_affiliate: is_affiliate ? 1 : 0,
                  domain,
                  status,
                  is_hide: !is_hide ? 1 : 0,
                  project_id: +project,
                } as IEditSiteSettingsParams)
              }}
            >
              {is_hide ? <IconEye color='#A059FF' /> : <IconEyeOff color='#A059FF' />}
            </IconButton>
          </Space>
        )
      },
      className: 'action',
    },
  ]

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<IAdvertiserModel> | SorterResult<IAdvertiserModel>[],
  ) => {
    dispatch(
      setAdvertiserReportPagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <section>
      <div className='mb-[16px] flex flex-col md:flex-row justify-between md:items-center gap-[16px]'>
        <h3 className='font-medium'>
          {t('pages.paidResults.allAdvertisers')}: {countRows}
        </h3>
        <span className='flex gap-[9px]'>
          <BpButton
            size='sm'
            theme='outline'
            type='button'
            onClick={() => {
              if (onToggleExpandAll) onToggleExpandAll(!isExpandAll)
            }}
            iconLeft={isExpandAll ? <IconMinimize /> : <IconMaximize />}
          ></BpButton>
          <ExportDrop
            onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
            loading={exportFileLoading}
            columns={[
              {
                label: t('pages.paidResults.advertiserReport'),
                bold: true,
                child: [
                  { keys: ['advertiser'], label: t('table.columns.advertiser') },
                  { keys: ['count_total'], label: t('table.columns.adsAnalyzed') },
                  { keys: ['keywords_count'], label: t('table.columns.keywords') },
                  { keys: ['visibility'], label: t('table.columns.visibility') },
                  { keys: ['affiliates_count'], label: t('table.columns.affiliates') },
                  { keys: ['progress_status'], label: t('table.columns.progressStatus') },
                  { keys: ['last_found'], label: t('table.columns.lastFound') },
                ],
              },
              {
                keys: ['latest_examples'],
                label: t('buttons.latestExamples'),
                tooltip: t('buttons.other.latestExamplesTooltip'),
                bold: true,
              },
            ]}
          />
        </span>
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => `${record.advertiser}`}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<IAdvertiserModel>}
        rowClassName={(e) => (e.is_hide ? 'disabled' : '')}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => (
            <IconButton onClick={(e) => onExpand(record, e)}>
              <TriangleButton color='#A059FF' position={expanded ? 'top' : 'down'} />
            </IconButton>
          ),
          expandedRowRender: (record: IAdvertiserModel) => <AdvertiserExpandRow {...record} />,
          onExpand: (expanded, { advertiser }) => {
            if (onToggleExpand) onToggleExpand(expanded, `${advertiser}`)
          },
          expandedRowKeys: isExpandAll
            ? data?.data?.items.map((item) => item.advertiser)
            : expandedRowKeys,
        }}
        pagination={{
          current,
          pageSize,
          total,
        }}
      />
    </section>
  )
}

export const AdvertiserReportTable = withExpanded(Table)
