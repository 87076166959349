import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IOption } from '../../shared/components/forms/search-input/BpSearchInput'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { ICountryParams } from './interfaces/CountryParams.interface'
import { ICommonFilterModel } from './models/CommonFilter.model'
import { IDomainParams } from './interfaces/DomainParams.interface'
import { IKeywordsParams } from './interfaces/KeywordsParams.interface'
import { IAffiliateParams } from './interfaces/AffiliatesParams.interface'
import { IAffiliateModel } from './models/Affiliate.model'
import { IDomainFilter } from './models/DomainFilterModel'
import { IAffiliatesParams } from '../ad-overview/interfaces/AffiliatesParams.interface'
import { IOwnDomainsParams } from './interfaces/OwnDomainsParams.interface'
import { IEngineParams } from './interfaces/EngineParams.interface'
import { ICityParams } from './interfaces/CityParams.interface'

const mapToOptions = (response: ICommonResponse<ICommonFilterModel[]>): IOption[] => {
  if (response?.data) {
    return response.data.map((o) => ({
      text: o.name,
      value: `${o.id}`,
    }))
  }

  return []
}

export const filterApi = createApi({
  reducerPath: 'filter/api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/filter/',
  }),
  endpoints: (build) => ({
    getProjects: build.query<IOption[], void>({
      query: () => ({ url: 'projects' }),
      transformResponse: mapToOptions,
    }),
    getCountry: build.query<IOption[], ICountryParams | void>({
      query: (params: ICountryParams) => ({ url: 'country', params }),
      transformResponse: mapToOptions,
    }),
    getDomains: build.query<IOption[], IDomainParams | void>({
      query: (params: IDomainParams) => ({ url: 'advertiser-domains', params }),
      transformResponse: (response: ICommonResponse<IDomainFilter[]>): IOption[] => {
        if (response?.data) return response.data.map(({ name }) => ({ text: name, value: name }))
        return []
      },
    }),
    getLandings: build.query<IOption[], IDomainParams | void>({
      query: (params: IDomainParams) => ({ url: 'advertiser-landing', params }),
      transformResponse: (response: ICommonResponse<IDomainFilter[]>): IOption[] => {
        if (response?.data) return response.data.map(({ name }) => ({ text: name, value: name }))
        return []
      },
    }),
    getOwnDomains: build.query<IOption[], IOwnDomainsParams | void>({
      query: (params: IOwnDomainsParams) => ({ url: 'own-domains', params }),
      transformResponse: (response: ICommonResponse<string[]>): IOption[] => {
        if (response?.data)
          return response.data.map((name: string) => ({ text: name, value: name }))
        return []
      },
    }),
    getEngines: build.query<IOption[], IEngineParams | void>({
      query: (params: IEngineParams) => ({ url: 'engine', params }),
      transformResponse: mapToOptions,
    }),
    getLiveSearchEngines: build.query<IOption[], IEngineParams | void>({
      query: (params: IEngineParams) => ({ url: 'engine-live-search', params }),
      transformResponse: mapToOptions,
    }),
    getCities: build.query<IOption[], ICityParams | void>({
      query: (params: ICityParams) => ({ url: 'city', params }),
      transformResponse: mapToOptions,
    }),
    getBrowsers: build.query<IOption[], void>({
      query: () => ({ url: 'browser' }),
      transformResponse: mapToOptions,
    }),
    getPolicy: build.query<IOption[], void>({
      query: () => ({ url: 'policy' }),
      transformResponse: mapToOptions,
    }),
    getKeywords: build.query<IOption[], IKeywordsParams | void>({
      query: (params: IKeywordsParams) => ({ url: 'keywords', params }),
      transformResponse: mapToOptions,
    }),
    getAffiliates: build.query<IOption[], IAffiliateParams | void>({
      query: (params: IAffiliatesParams) => ({ url: 'affiliates', params }),
      transformResponse: (response: ICommonResponse<IAffiliateModel[]>): IOption[] => {
        if (!response.data) return []
        return response.data.map((item) => ({
          text: item.name,
          value: `${item.id}`,
        }))
      },
    }),
    getDevices: build.query<IOption[], void>({
      query: () => ({ url: 'devices' }),
      transformResponse: mapToOptions,
    }),
    getDeviceTypes: build.query<IOption[], void>({
      query: () => ({ url: 'device-types' }),
      transformResponse: mapToOptions,
    }),
    getAffiliateStatus: build.query<IOption[], void>({
      query: () => ({ url: 'affiliate-status' }),
      transformResponse: (response: { data: { [key: number]: string } }): IOption[] => {
        if (!response?.data) {
          return []
        }
        const result = []

        for (const key in response.data) {
          result.push({ text: response.data[key], value: key })
        }

        return result
      },
    }),
    getProjectStatus: build.query<IOption[], void>({
      query: () => ({ url: 'project-status' }),
      transformResponse: (response: { data: { [key: number]: string } }): IOption[] => {
        if (!response?.data) {
          return []
        }
        const result = []

        for (const key in response.data) {
          result.push({ text: response.data[key], value: key })
        }

        return result
      },
    }),
    getBrowserLanguage: build.query<IOption[], void>({
      query: () => ({ url: 'browser-language' }),
      transformResponse: (response: { data: { [key: number]: string } }): IOption[] => {
        if (!response?.data) {
          return []
        }
        const result = []

        for (const key in response.data) {
          result.push({ text: response.data[key], value: key })
        }

        return result
      },
    }),
  }),
})

export const {
  useGetProjectsQuery,
  useGetCountryQuery,
  useGetDomainsQuery,
  useGetLandingsQuery,
  useGetOwnDomainsQuery,
  useGetEnginesQuery,
  useGetLiveSearchEnginesQuery,
  useGetCitiesQuery,
  useGetBrowsersQuery,
  useGetKeywordsQuery,
  useGetAffiliatesQuery,
  useGetDevicesQuery,
  useGetDeviceTypesQuery,
  useGetAffiliateStatusQuery,
  useGetProjectStatusQuery,
  useGetBrowserLanguageQuery,
} = filterApi
